import React from "react";
import { GlobalPromotionStrategyData } from "..";
import { Empty } from "../../../components/atoms";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { useSetAtom } from "jotai";
import { globalPromotionStrategyStateAtom } from "../../../store/businessPlanDataState.atom";

/**
 * @deprecated
 */
export const GlobalPromotionStrategyView = ({
  businessPlanSectionData,
  isFreeVersion = false,
  isGlobalMarket = false,
}: BusinessPlanSectionProps<GlobalPromotionStrategyData> & {
  /**
   * @description 목표 시장이 글로벌 시장인지 여부
   */
  isGlobalMarket?: boolean;
}) => {
  const setBusinessPlanSectionData = useSetAtom(globalPromotionStrategyStateAtom);

  return (
    <BusinessPlanSectionLayout
      title={isGlobalMarket ? "글로벌 시장 확장 전략" : "글로벌 진출 전략"}
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.GLOBAL_PROMOTION_STRATEGY}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H2>
        {isGlobalMarket ? "글로벌 시장 확장 전략" : "글로벌 진출 전략"}
      </BusinessPlanSectionLayout.H2>
      {!isGlobalMarket && (
        <BusinessPlanSectionLayout.Blockquote>
          TIP : 내수 시장 만을 노리겠다고 하는 사업은 지원사업 평가에 있어 점점 경쟁력이 떨어지고
          있습니다. 글로벌 진출 전략의 중요성은 커지고 있으며, 이는 글로벌 관련 지원사업의 정부 예산
          확대로도 느낄 수 있습니다.
        </BusinessPlanSectionLayout.Blockquote>
      )}
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData.globalMarketTarget}
      </BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.H3>
        1. {businessPlanSectionData.background.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.background.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        2. {businessPlanSectionData.solution.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.solution.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};
