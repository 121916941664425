import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

export type ControlledMuiProps = TextFieldProps & {
  useControllerProps: UseControllerProps;
  disableHelperText?: boolean;
};

/**
 * @description
 * 제어 컴포넌트를 위해 useController를 이용해 TextField 생성
 */
export const TextFieldControlled = React.forwardRef<HTMLDivElement, ControlledMuiProps>(
  ({ useControllerProps, disableHelperText = false, ...props }: ControlledMuiProps, ref) => {
    const {
      field,
      fieldState: { error },
    } = useController(useControllerProps);

    return (
      <TextField
        {...field}
        ref={ref}
        error={!!error}
        helperText={!disableHelperText && !!error && error.message}
        sx={{ "& .MuiOutlinedInput-input": { fontSize: 15 }, ...props.sx }}
        {...props}
      />
    );
  }
);
