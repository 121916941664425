import React from "react";
import { noSelectabilityStyle } from "../../styles/infra.style";
import { appColor } from "../../constants/appColor";

export const SvgLayout = React.forwardRef(
  (
    { children, id, width = "720px", height = "460px", ...props }: Props,
    ref: React.Ref<SVGSVGElement>
  ) => {
    return (
      <svg
        width={width}
        height={height}
        id={id}
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        style={{ backgroundColor: appColor.white, ...noSelectabilityStyle }}
        {...props}
      >
        <style>
          {`@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;}`}
          {`text {
          font-family: 'Pretendard-Regular';
        }`}
        </style>
        <defs />
        {children}
      </svg>
    );
  }
);

type Props = {
  children: React.ReactNode;
  id: string;
  width?: string;
  height?: string;
} & React.SVGProps<SVGSVGElement>;
