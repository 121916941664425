import React from "react";
import { SvgLayout } from "../../layouts/SvgLayout";
import { appColor } from "../../../constants/appColor";
import {
  FemaleBunHairEarringsSvg,
  FemaleLongHairGlassesSvg,
  FemaleMidLengthHairNecklaceSvg,
  FemalePonytailLovelySvg,
  FemaleStraightHairNormalSvg,
  MaleBaldBeardSvg,
  MaleBeanieNormalSvg,
  MaleCurlyHairGlassesSvg,
  MaleCurlyHairNormalSvg,
} from "../../svgs";
import { useAtomValue } from "jotai";
import {
  businessPlanPaletteAtom,
  isEnglishDocumentAtom,
} from "../../../store/businessPlanUiState.atom";
import { appSize } from "../../../constants/appSize";

export const ClientPersonaUi = ({ svgId, clientPersona }: Props) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  const personaSvgMap = {
    MaleBaldBeardSvg: <MaleBaldBeardSvg width={180} height={180} x={120} y={10} />,
    FemaleLongHairGlassesSvg: <FemaleLongHairGlassesSvg width={180} height={180} x={120} y={10} />,
    FemaleBunHairEarringsSvg: <FemaleBunHairEarringsSvg width={180} height={180} x={120} y={10} />,
    FemaleMidLengthHairNecklaceSvg: (
      <FemaleMidLengthHairNecklaceSvg width={180} height={180} x={120} y={10} />
    ),
    FemalePonytailLovelySvg: <FemalePonytailLovelySvg width={180} height={180} x={120} y={10} />,
    FemaleStraightHairNormalSvg: (
      <FemaleStraightHairNormalSvg width={180} height={180} x={120} y={10} />
    ),
    MaleBeanieNormalSvg: <MaleBeanieNormalSvg width={180} height={180} x={120} y={10} />,
    MaleCurlyHairGlassesSvg: <MaleCurlyHairGlassesSvg width={180} height={180} x={120} y={10} />,
    MaleCurlyHairNormalSvg: <MaleCurlyHairNormalSvg width={180} height={180} x={120} y={10} />,
  };

  // [Temp]
  const ENGLISH_VERSION_EXTRA_HEIGHT = 45;
  const addMargin = isEnglish ? ENGLISH_VERSION_EXTRA_HEIGHT : 0;

  return (
    <SvgLayout
      id={svgId}
      width={appSize.clientPersona.width}
      height={isEnglish ? "736px" : appSize.clientPersona.height}
    >
      <rect
        x={0}
        y={0}
        width={400}
        height={700 + addMargin}
        fill="none"
        stroke={appColor.border}
        rx={30}
      />
      {personaSvgMap[clientPersona.svg as keyof typeof personaSvgMap]}
      <foreignObject x={0} y={185} width="400" height="40" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: 30, fontWeight: "bold" }}>{clientPersona.name}</span>
        </div>
      </foreignObject>
      <foreignObject x={0} y={230} width="400" height="20" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: 14 }}>
            {isEnglish
              ? `${clientPersona.sex} ${clientPersona.age}, ${clientPersona.job}`
              : `${clientPersona.sex} ${clientPersona.age}세, ${clientPersona.job}`}
          </span>
        </div>
      </foreignObject>
      {/* interview */}
      <foreignObject x={50} y={255} width="300" height="100" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: 17 }}>{`"${clientPersona.interview}"`}</span>
        </div>
      </foreignObject>
      {/* [Temp] 영문 버전의 경우 간격을 더 줌 */}
      <line x1={30} y1={330 + addMargin} x2={370} y2={330 + addMargin} stroke={appColor.border} />
      {/* interest */}
      <foreignObject x={80} y={350 + addMargin} width="160" height="110">
        <div style={{ width: "100%", height: "100%" }}>
          {Object.entries(clientPersona.interest).map(([key]) => {
            return <div style={{ fontSize: 14 }}>{key}</div>;
          })}
        </div>
      </foreignObject>
      {Object.entries(clientPersona.interest).map(([_, value], index) => {
        return (
          <>
            <rect
              x={220}
              y={355 + index * 20.5 + addMargin}
              width={100}
              height={12}
              fill={appColor.disabled}
            />
            <rect
              x={220}
              y={355 + index * 20.5 + addMargin}
              width={(value / 10) * 100}
              height={12}
              fill={businessPlanPalette.primary}
            />
          </>
        );
      })}
      {/* 관심사 */}
      <foreignObject x={60} y={468 + addMargin} width="100" height="20" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          <span style={{ fontSize: 16 }}>{isEnglish ? "Interest" : "관심사"}</span>
        </div>
      </foreignObject>
      <foreignObject x={60} y={492 + addMargin} width="290" height="105" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          {clientPersona.description.map((description) => {
            return <div style={{ fontSize: 14 }}>- {description}</div>;
          })}
        </div>
      </foreignObject>
      {/* scenario */}
      <foreignObject x={60} y={595 + addMargin} width="100" height="20" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          <span style={{ fontSize: 16 }}>{isEnglish ? "Scenario" : "시나리오"}</span>
        </div>
      </foreignObject>
      <foreignObject x={60} y={620 + addMargin} width="290" height="800" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          <span style={{ fontSize: 14 }}>{clientPersona.scenario}</span>
        </div>
      </foreignObject>
    </SvgLayout>
  );
};

type Props = {
  svgId: string;
  clientPersona: ClientPersona;
};

export type ClientPersona = {
  name: string;
  age: number;
  sex: string;
  job: string;
  interest: {
    [key: string]: number;
  };
  description: string[];
  scenario: string;
  interview: string;
  svg: string;
};
