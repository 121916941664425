import React from "react";
import { useQuery } from "@tanstack/react-query";
import { PromptOutput, ResGetPromptOutputById, promptApi } from "../../apis/prompt.api";
import {
  ItemBackgroundAndNeedsData,
  MarketGrowthGraphWithTamSamSom,
} from "../../pages/1_1_창업아이템_배경_및_필요성";
import {
  BusinessConcretizationData,
  CompetitorAnalysisAndDifferentiationPlanData,
  PositioningMapData,
} from "../../pages/2_2_창업_아이템_실현_및_구체화_방안";
import { BusinessOverviewData, LeanCanvasData } from "../../pages/2_1_창업_아이템_현황";
import { ClientPersonaData } from "../../pages/1_2_창업아이템_목표_시장(고객)_현황_분석";
import {
  BusinessEntryStrategyData,
  BusinessModelData,
} from "../../pages/3_1_창업아이템_사업화_추진_전략";
import {
  GlobalPromotionStrategyAndBusinessEntryStrategyData,
  GlobalPromotionStrategyData,
  TargetSalesGraphData,
} from "../../pages/3_2_생존율_제고를_위한_노력";
import { BusinessRoadmapData } from "../../pages/3_3_사업추진_일정";
import { useAtom } from "jotai";
import {
  businessConcretizationStateAtom,
  itemBackgroundAndNeedsStateAtom,
  marketGrowthGraphWithTamSamSomStateAtom,
  positioningMapStateAtom,
  leanCanvasStateAtom,
  businessModelStateAtom,
  clientPersonaStateAtom,
  businessEntryStrategyStateAtom,
  globalPromotionStrategyStateAtom,
  globalPromotionStrategyAndBusinessEntryStrategyStateAtom,
  targetSalesGraphStateAtom,
  businessRoadmapStateAtom,
  competitorAnalysisAndDifferentiationPlanStateAtom,
  businessOverviewStateAtom,
} from "../../store/businessPlanDataState.atom";
import { getOutputByPromptTypeName } from "../../lib/utils/getOutputByPromptTypeName";
import { useNavigateByPromptCategory } from "./useNavigateByPromptCategory";
import { PROMPT_CATEGORY } from "../../constants/domain/promptCategory";

export const useGetBusinessPlanOutputData = ({ promptOutputId }: Props) => {
  const { navigateByPromptCategory } = useNavigateByPromptCategory();

  // [Todo] promptOutputData에 타이틀 추가할거임
  const { data: promptOutputData } = useQuery({
    queryKey: ["promptOutput", promptOutputId],
    queryFn: async () => {
      const res = await promptApi.getPromptOutputById(promptOutputId);
      const promptCategory = res?.data?.promptCategory?.name;
      if (
        promptCategory !== PROMPT_CATEGORY.예창 &&
        promptCategory !== PROMPT_CATEGORY.청창 &&
        promptCategory !== PROMPT_CATEGORY.예창_영어버전
      ) {
        navigateByPromptCategory({ promptCategory, id: promptOutputId });
        return null;
      }

      const outputs = res?.data?.outputs.sort((a: PromptOutput, b: PromptOutput) => b.id - a.id);

      const autoFillInformationId = outputs[0]?.autoFillInformation?.id;

      let resInputData;
      if (autoFillInformationId) {
        const { data } = await promptApi.getSurveyInputById(outputs[0]?.autoFillInformation?.id);
        resInputData = data;
        resInputData.sort((a: any, b: any) => a.id - b.id);
      }

      return {
        ...res?.data,
        outputs,
        resInputData: {
          title: resInputData?.[0]?.content || "",
          description: resInputData?.[1]?.content || "",
          target: resInputData?.[2]?.content || "",
          motivation: resInputData?.[3]?.content || "",
        },
      };
    },
  });

  const [marketGrowthGraphWithTamSamSomState, setMarketGrowthGraphWithTamSamSomState] = useAtom(
    marketGrowthGraphWithTamSamSomStateAtom
  );
  const [itemBackgroundAndNeedsState, setItemBackgroundAndNeedsState] = useAtom(
    itemBackgroundAndNeedsStateAtom
  );
  const [positioningMapState, setPositioningMapState] = useAtom(positioningMapStateAtom);
  const [businessConcretizationState, setBusinessConcretizationState] = useAtom(
    businessConcretizationStateAtom
  );
  const [
    competitorAnalysisAndDifferentiationPlanState,
    setCompetitorAnalysisAndDifferentiationPlanState,
  ] = useAtom(competitorAnalysisAndDifferentiationPlanStateAtom);
  const [leanCanvasState, setLeanCanvasState] = useAtom(leanCanvasStateAtom);
  const [businessOverviewState, setBusinessOverviewState] = useAtom(businessOverviewStateAtom);
  const [clientPersonaState, setClientPersonaState] = useAtom(clientPersonaStateAtom);
  const [businessModelState, setBusinessModelState] = useAtom(businessModelStateAtom);
  const [businessEntryStrategyState, setBusinessEntryStrategyState] = useAtom(
    businessEntryStrategyStateAtom
  );
  const [globalPromotionStrategyState, setGlobalPromotionStrategyState] = useAtom(
    globalPromotionStrategyStateAtom
  );
  const [
    globalPromotionStrategyAndBusinessEntryStrategyState,
    setGlobalPromotionStrategyAndBusinessEntryStrategyState,
  ] = useAtom(globalPromotionStrategyAndBusinessEntryStrategyStateAtom);
  const [targetSalesGraphState, setTargetSalesGraphState] = useAtom(targetSalesGraphStateAtom);
  const [businessRoadmapState, setBusinessRoadmapState] = useAtom(businessRoadmapStateAtom);

  React.useLayoutEffect(() => {
    setMarketGrowthGraphWithTamSamSomState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "시장 성장 그래프 + TAM SAM SOM",
      }) as MarketGrowthGraphWithTamSamSom
    );
    setItemBackgroundAndNeedsState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "외적동기, 문제점 해결 시급성, 해결방안",
      }) as ItemBackgroundAndNeedsData
    );
    setPositioningMapState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "포지셔닝 맵",
      }) as PositioningMapData
    );
    setBusinessConcretizationState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "사업 구체화 방안",
      }) as BusinessConcretizationData
    );
    setCompetitorAnalysisAndDifferentiationPlanState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "경쟁사 분석 및 차별화 방안",
      }) as CompetitorAnalysisAndDifferentiationPlanData
    );
    setLeanCanvasState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "린캔버스",
      }) as LeanCanvasData
    );
    setBusinessOverviewState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "사업 소개",
      }) as BusinessOverviewData
    );
    setClientPersonaState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "페르소나",
      }) as ClientPersonaData
    );
    setBusinessModelState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "비즈니스 모델과 수익 창출 과정",
      }) as BusinessModelData
    );
    setBusinessEntryStrategyState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "사업 추진 전략(마케팅 전략)",
      }) as BusinessEntryStrategyData
    );
    setGlobalPromotionStrategyState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "글로벌 진출 전략(legacy)",
      }) as GlobalPromotionStrategyData
    );
    setGlobalPromotionStrategyAndBusinessEntryStrategyState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "글로벌 시장 진입 및 마케팅 전략",
      }) as GlobalPromotionStrategyAndBusinessEntryStrategyData
    );
    setTargetSalesGraphState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "재무 및 목표 매출 그래프",
      }) as TargetSalesGraphData
    );
    setBusinessRoadmapState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "사업 추진 방안(로드맵)",
      }) as BusinessRoadmapData
    );
  }, [promptOutputData]);

  return {
    promptOutputData,
    marketGrowthGraphWithTamSamSomState,
    itemBackgroundAndNeedsState,
    positioningMapState,
    businessConcretizationState,
    competitorAnalysisAndDifferentiationPlanState,
    leanCanvasState,
    businessOverviewState,
    clientPersonaState,
    businessModelState,
    businessEntryStrategyState,
    globalPromotionStrategyState,
    globalPromotionStrategyAndBusinessEntryStrategyState,
    targetSalesGraphState,
    businessRoadmapState,
  };
};

type Props = {
  promptOutputId: number;
};
