import React from "react";
import { BusinessEntryStrategyData } from "..";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { Typography } from "@mui/material";
import { Empty } from "../../../components/atoms";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessRoadmapData } from "../../3_3_사업추진_일정";
import { businessEntryStrategyStateAtom } from "../../../store/businessPlanDataState.atom";
import { useAtomValue, useSetAtom } from "jotai";
import { BusinessEntryTableUi } from "../../../components/molecules/businessPlan/BusinessEntryTableUi";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";
import { appSize } from "../../../constants/appSize";

export const BusinessEntryStrategyView = ({
  businessPlanSectionData,
  isFreeVersion = false,
  businessRoadmapState,
}: BusinessPlanSectionProps<BusinessEntryStrategyData> & {
  businessRoadmapState?: BusinessRoadmapData;
}) => {
  const setBusinessPlanSectionData = useSetAtom(businessEntryStrategyStateAtom);

  // [Temp] 테이블 UI 컴포넌트로 빼지 않고 일단 대입
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  if (businessPlanSectionData.marketEntryStrategy)
    return (
      <BusinessPlanSectionLayout
        title="시장 진입 및 마케팅 전략"
        businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
        setBusinessPlanSectionDataState={setBusinessPlanSectionData}
        id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_ENTRY_STRATEGY}_BLURRED`}
      >
        <BusinessPlanSectionLayout.H2>시장 진입 및 마케팅 전략</BusinessPlanSectionLayout.H2>
        {businessRoadmapState && (
          <>
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              KPI : {businessRoadmapState.kpi.join(", ")}
            </BusinessPlanSectionLayout.P>
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              {isEnglish ? "Goal" : "목표"} : {businessRoadmapState.goal.join(", ")}
            </BusinessPlanSectionLayout.P>
          </>
        )}
        <BusinessPlanSectionLayout.H3
          style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
        >
          시장 초기 진입 전략 단계별 표
        </BusinessPlanSectionLayout.H3>
        <BusinessEntryTableUi marketEntryStrategy={businessPlanSectionData.marketEntryStrategy} />
        <Empty height="1rem" />
        <Typography variant="h4">마케팅 전략</Typography>
        <BusinessPlanSectionLayout.Toggle
          summary={<b style={{ color: "green" }}>마케팅 용어 설명</b>}
        >
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CAC (Customer Acquisition Cost)</b>: 고객 1명을 획득하는 데 드는 비용. 마케팅 및
            영업 비용을 신규 고객 수로 나누어 계산.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>LTV (Lifetime Value)</b>: 고객 생애 가치. 고객이 비즈니스에 기여하는 예상 전체
            수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.Toggle
            summary={<b style={{ color: "green" }}>CAC와 LTV의 관계</b>}
          >
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              이상적으로 LTV는 CAC보다 훨씬 높아야 합니다. 고객 한 명을 획득하는 데 드는 비용보다 그
              고객이 생성하는 수익이 커야하니까요. 일반적으로 LTV:CAC 비율이 3:1 이상이면 건강한
              비즈니스로 간주됩니다. 만약 CAC가 LTV에 비해 너무 높다면, 이는 기업이 고객을 획득하는
              데 너무 많은 비용을 지출하고 있음을 의미합니다. 반대로, LTV가 매우 높고 CAC가 매우
              낮다면, 회사는 마케팅 및 판매에 더 많은 투자를 할 수 있는 기회를 놓치고 있는 것일 수도
              있습니다.
            </BusinessPlanSectionLayout.P>
          </BusinessPlanSectionLayout.Toggle>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPC (Cost Per Click)</b>: 클릭당 비용. 온라인 광고 클릭 한 번당 광고주가 지불하는
            비용입니다.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>PPC (Pay Per Click)</b>: 클릭당 지불 광고 모델. 광고주가 자신의 광고가 클릭될
            때마다 특정 금액을 지불합니다. 주로 검색 엔진 광고와 소셜 미디어 광고에서 사용됩니다.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.Toggle
            summary={<b style={{ color: "green" }}>PPC와 CPC는 똑같은 거 아닌가요?</b>}
          >
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              정말 헷갈리지만 조금 다릅니다. PPC는 광고가 클릭될 때마다 비용이 발생하는 광고 결제
              모델을 나타내는 것이고, CPC는 그 모델 안에서 광고주가 클릭당 실제로 지불하는 금액을
              말합니다. PPC는 결제 구조의 한 형태이고, CPC는 그 구조 안에서 발생하는 구체적인 비용인
              것이죠.
            </BusinessPlanSectionLayout.P>
          </BusinessPlanSectionLayout.Toggle>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>DAU (Daily Active Users)</b>: 일일 활성 사용자 수.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ROAS (Return On Advertising Spend)</b>: 광고 지출 대비 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ROI (Return On Investment)</b>: 투자 수익률.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ARPU (Average Revenue Per User)</b>: 사용자당 평균 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ARPPU (Average Revenue Per Paying User)</b>: 지불 사용자당 평균 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ARPDAU (Average Revenue Per Daily Active User)</b>: 일일 활성 사용자당 평균 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPI (Cost Per Install)</b>: 앱 설치당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPA (Cost Per Action/Acquisition)</b>: 구매, 가입 등 특정 행동 또는 획득당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPM (Cost Per Mille)</b>: 천 회 노출당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPE (Cost Per Engagement)</b>: 사용자의 특정 참여당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPL (Cost Per Lead)</b>: 잠재 고객 한 명당 비용
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPT (Cost Per Transaction)</b>: 온라인 거래나 판매 등 거래당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CVR (Conversion Rate)</b>: 전환율. 특정 목표 달성 비율.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CTR (Click Through Rate)</b>: 광고 노출 횟수 대비 클릭률.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>NRU (New Registered Users)</b>: 신규 등록 사용자 수.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>MAU (Monthly Active Users)</b>: 월간 활성 사용자 수.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>PU (Paying Users)</b>: 지불 사용자 수.
          </BusinessPlanSectionLayout.P>
        </BusinessPlanSectionLayout.Toggle>
        <Empty height="1rem" />
        <TableLayout style={{ ...appSize.table }}>
          <TableLayout.THead>
            <TableLayout.Tr>
              <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
              <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
            </TableLayout.Tr>
          </TableLayout.THead>
          <TableLayout.TBody>
            {businessPlanSectionData.marketingStrategy.map((content) => (
              <TableLayout.Tr>
                <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
                <TableLayout.Td>{content.contents}</TableLayout.Td>
              </TableLayout.Tr>
            ))}
          </TableLayout.TBody>
        </TableLayout>
        <Empty height="1rem" />
        {businessPlanSectionData.marketingStrategyDetail.map((marketingStrategyDetail) => (
          <>
            <Typography
              variant="h4"
              style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
            >
              {marketingStrategyDetail.title}
            </Typography>
            <Empty height="1rem" />
            <TableLayout
              style={
                isFreeVersion
                  ? { ...appSize.table, ...freeVersionBusinessPlanStyle }
                  : { ...appSize.table }
              }
            >
              <TableLayout.THead>
                <TableLayout.Tr>
                  <TableLayout.Th textCenter>{isEnglish ? "Criteria" : "구분"}</TableLayout.Th>
                  <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
                </TableLayout.Tr>
              </TableLayout.THead>
              <TableLayout.TBody>
                {marketingStrategyDetail.rows.map((content) => (
                  <TableLayout.Tr>
                    <TableLayout.Td textCenter>{content.criteria}</TableLayout.Td>
                    <TableLayout.Td>{content.content}</TableLayout.Td>
                  </TableLayout.Tr>
                ))}
              </TableLayout.TBody>
            </TableLayout>
            <Empty height="1rem" />
          </>
        ))}
      </BusinessPlanSectionLayout>
    );
  /**
   * @deprecated 옛날 stratege 오타 수용
   */ else if (businessPlanSectionData.marketEntryStratege)
    return (
      <BusinessPlanSectionLayout
        title="시장 진입 및 마케팅 전략"
        businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
        setBusinessPlanSectionDataState={setBusinessPlanSectionData}
        id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_ENTRY_STRATEGY}_BLURRED`}
      >
        <BusinessPlanSectionLayout.H2>시장 진입 및 마케팅 전략</BusinessPlanSectionLayout.H2>
        {businessRoadmapState && (
          <>
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              KPI : {businessRoadmapState.kpi.join(", ")}
            </BusinessPlanSectionLayout.P>
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              {isEnglish ? "Goal" : "목표"} : {businessRoadmapState.goal.join(", ")}
            </BusinessPlanSectionLayout.P>
          </>
        )}
        <BusinessPlanSectionLayout.H3
          style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
        >
          시장 초기 진입 전략 단계별 표
        </BusinessPlanSectionLayout.H3>
        <TableLayout
          style={
            isFreeVersion
              ? { ...appSize.table, ...freeVersionBusinessPlanStyle }
              : { ...appSize.table }
          }
        >
          <TableLayout.THead>
            <TableLayout.Tr>
              <TableLayout.Th textCenter>{isEnglish ? "Step" : "단계"}</TableLayout.Th>
              <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
              <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
            </TableLayout.Tr>
          </TableLayout.THead>
          <TableLayout.TBody>
            {businessPlanSectionData.marketEntryStratege.map((content, index) => (
              <TableLayout.Tr>
                <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
                <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
                <TableLayout.Td>{content.contents}</TableLayout.Td>
              </TableLayout.Tr>
            ))}
          </TableLayout.TBody>
        </TableLayout>
        <Empty height="1rem" />
        <Typography variant="h4">마케팅 전략</Typography>
        <BusinessPlanSectionLayout.Toggle
          summary={<b style={{ color: "green" }}>마케팅 용어 설명</b>}
        >
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CAC (Customer Acquisition Cost)</b>: 고객 1명을 획득하는 데 드는 비용. 마케팅 및
            영업 비용을 신규 고객 수로 나누어 계산.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>LTV (Lifetime Value)</b>: 고객 생애 가치. 고객이 비즈니스에 기여하는 예상 전체
            수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.Toggle
            summary={<b style={{ color: "green" }}>CAC와 LTV의 관계</b>}
          >
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              이상적으로 LTV는 CAC보다 훨씬 높아야 합니다. 고객 한 명을 획득하는 데 드는 비용보다 그
              고객이 생성하는 수익이 커야하니까요. 일반적으로 LTV:CAC 비율이 3:1 이상이면 건강한
              비즈니스로 간주됩니다. 만약 CAC가 LTV에 비해 너무 높다면, 이는 기업이 고객을 획득하는
              데 너무 많은 비용을 지출하고 있음을 의미합니다. 반대로, LTV가 매우 높고 CAC가 매우
              낮다면, 회사는 마케팅 및 판매에 더 많은 투자를 할 수 있는 기회를 놓치고 있는 것일 수도
              있습니다.
            </BusinessPlanSectionLayout.P>
          </BusinessPlanSectionLayout.Toggle>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPC (Cost Per Click)</b>: 클릭당 비용. 온라인 광고 클릭 한 번당 광고주가 지불하는
            비용입니다.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>PPC (Pay Per Click)</b>: 클릭당 지불 광고 모델. 광고주가 자신의 광고가 클릭될
            때마다 특정 금액을 지불합니다. 주로 검색 엔진 광고와 소셜 미디어 광고에서 사용됩니다.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.Toggle
            summary={<b style={{ color: "green" }}>PPC와 CPC는 똑같은 거 아닌가요?</b>}
          >
            <BusinessPlanSectionLayout.P style={{ color: "green" }}>
              정말 헷갈리지만 조금 다릅니다. PPC는 광고가 클릭될 때마다 비용이 발생하는 광고 결제
              모델을 나타내는 것이고, CPC는 그 모델 안에서 광고주가 클릭당 실제로 지불하는 금액을
              말합니다. PPC는 결제 구조의 한 형태이고, CPC는 그 구조 안에서 발생하는 구체적인 비용인
              것이죠.
            </BusinessPlanSectionLayout.P>
          </BusinessPlanSectionLayout.Toggle>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>DAU (Daily Active Users)</b>: 일일 활성 사용자 수.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ROAS (Return On Advertising Spend)</b>: 광고 지출 대비 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ROI (Return On Investment)</b>: 투자 수익률.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ARPU (Average Revenue Per User)</b>: 사용자당 평균 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ARPPU (Average Revenue Per Paying User)</b>: 지불 사용자당 평균 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>ARPDAU (Average Revenue Per Daily Active User)</b>: 일일 활성 사용자당 평균 수익.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPI (Cost Per Install)</b>: 앱 설치당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPA (Cost Per Action/Acquisition)</b>: 구매, 가입 등 특정 행동 또는 획득당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPM (Cost Per Mille)</b>: 천 회 노출당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPE (Cost Per Engagement)</b>: 사용자의 특정 참여당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPL (Cost Per Lead)</b>: 잠재 고객 한 명당 비용
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CPT (Cost Per Transaction)</b>: 온라인 거래나 판매 등 거래당 비용.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CVR (Conversion Rate)</b>: 전환율. 특정 목표 달성 비율.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>CTR (Click Through Rate)</b>: 광고 노출 횟수 대비 클릭률.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>NRU (New Registered Users)</b>: 신규 등록 사용자 수.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>MAU (Monthly Active Users)</b>: 월간 활성 사용자 수.
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.P style={{ color: "green" }}>
            - <b>PU (Paying Users)</b>: 지불 사용자 수.
          </BusinessPlanSectionLayout.P>
        </BusinessPlanSectionLayout.Toggle>
        <Empty height="1rem" />
        <TableLayout style={{ ...appSize.table }}>
          <TableLayout.THead>
            <TableLayout.Tr>
              <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
              <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
            </TableLayout.Tr>
          </TableLayout.THead>
          <TableLayout.TBody>
            {businessPlanSectionData.marketingStratege.map((content) => (
              <TableLayout.Tr>
                <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
                <TableLayout.Td>{content.contents}</TableLayout.Td>
              </TableLayout.Tr>
            ))}
          </TableLayout.TBody>
        </TableLayout>
        <Empty height="1rem" />
        {businessPlanSectionData.marketingStrategeDetail.map((marketingStrategeDetail) => (
          <>
            <Typography
              variant="h4"
              style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
            >
              {marketingStrategeDetail.title}
            </Typography>
            <Empty height="1rem" />
            <TableLayout
              style={
                isFreeVersion
                  ? { ...appSize.table, ...freeVersionBusinessPlanStyle }
                  : { ...appSize.table }
              }
            >
              <TableLayout.THead>
                <TableLayout.Tr>
                  <TableLayout.Th textCenter>{isEnglish ? "Criteria" : "구분"}</TableLayout.Th>
                  <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
                </TableLayout.Tr>
              </TableLayout.THead>
              <TableLayout.TBody>
                {marketingStrategeDetail.rows.map((content) => (
                  <TableLayout.Tr>
                    <TableLayout.Td textCenter>{content.criteria}</TableLayout.Td>
                    <TableLayout.Td>{content.content}</TableLayout.Td>
                  </TableLayout.Tr>
                ))}
              </TableLayout.TBody>
            </TableLayout>
            <Empty height="1rem" />
          </>
        ))}
      </BusinessPlanSectionLayout>
    );
};
