import React from "react";
import { SvgLayout } from "../../layouts/SvgLayout";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { useAtomValue } from "jotai";
import { businessPlanPaletteAtom } from "../../../store/businessPlanUiState.atom";
import { TamSamSomData } from "../../../pages/1_2_창업아이템_목표_시장(고객)_현황_분석";
import { appSize } from "../../../constants/appSize";

export const TamSamSomUi = ({ tamSamSom }: Props) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);

  return (
    <SvgLayout
      width={appSize.tamSamSom.width}
      height={appSize.tamSamSom.height}
      id={BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}
    >
      <circle
        cx={170}
        cy={200}
        fill={businessPlanPalette.primaryLightest}
        fillOpacity={0.5}
        r={160}
      />
      <circle cx="170" cy="240" fill={businessPlanPalette.primary} fillOpacity="0.5" r="120" />
      <circle
        cx="170"
        cy="280"
        fill={businessPlanPalette.primaryDarkest}
        fillOpacity="0.5"
        r="80"
      />
      <foreignObject x={145} y={45} width="50" height="20" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "16px" }}>TAM</span>
        </div>
      </foreignObject>
      <foreignObject x={145} y={125} width="50" height="20" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "16px" }}>SAM</span>
        </div>
      </foreignObject>
      <foreignObject x={145} y={220} width="50" height="20" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>SOM</span>
        </div>
      </foreignObject>
      <foreignObject x={70} y={80} width="200" height="30" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "22px" }}>
            {tamSamSom.tam.marketSizeNum.toLocaleString("ko-KR") + tamSamSom.tam.marketSizeUnit}
          </span>
        </div>
      </foreignObject>
      <foreignObject x={70} y={160} width="200" height="30" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "22px" }}>
            {tamSamSom.sam.marketSizeNum.toLocaleString("ko-KR") + tamSamSom.sam.marketSizeUnit}
          </span>
        </div>
      </foreignObject>
      <foreignObject x={70} y={265} width="200" height="30" textAnchor="middle">
        <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <span style={{ fontSize: "22px", fontWeight: "bold" }}>
            {tamSamSom.som.marketSizeNum.toLocaleString("ko-KR") + tamSamSom.som.marketSizeUnit}
          </span>
        </div>
      </foreignObject>
      <line stroke="grey" strokeDasharray="5,5" strokeWidth="1" x1="200" x2="370" y1="57" y2="57" />
      <line
        stroke="grey"
        strokeDasharray="5,5"
        strokeWidth="1"
        x1="200"
        x2="370"
        y1="137"
        y2="137"
      />
      <line
        stroke="grey"
        strokeDasharray="5,5"
        strokeWidth="1"
        x1="200"
        x2="370"
        y1="232"
        y2="232"
      />
      <foreignObject x={380} y={45} width="360" height="100" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          <div style={{ fontSize: "16px" }}>{tamSamSom?.tam?.description}</div>
          <div style={{ fontSize: "16px" }}>{`(${tamSamSom?.tam?.marketSizeJustification})`}</div>
        </div>
      </foreignObject>
      <foreignObject x={380} y={125} width="360" height="100" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          <div style={{ fontSize: "16px" }}>{tamSamSom?.sam?.description}</div>
          <div style={{ fontSize: "16px" }}>{`(${tamSamSom?.sam?.marketSizeJustification})`}</div>
        </div>
      </foreignObject>
      <foreignObject x={380} y={220} width="360" height="100" textAnchor="middle">
        <div style={{ width: "100%", height: "100%" }}>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>{tamSamSom?.som?.description}</div>
          <div
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >{`(${tamSamSom?.som?.marketSizeJustification})`}</div>
        </div>
      </foreignObject>
    </SvgLayout>
  );
};

type Props = {
  tamSamSom: TamSamSomData;
};
