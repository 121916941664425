import React from "react";
import { TargetSalesGraphData } from "..";
import { Bar, ComposedChart, Legend, Line, XAxis, YAxis } from "recharts";
import { Empty } from "../../../components/atoms";
import { useAtomValue, useSetAtom } from "jotai";
import { businessPlanPaletteAtom } from "../../../store/businessPlanUiState.atom";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageListProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { Button } from "@mui/material";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { appColor } from "../../../constants/appColor";
import { targetSalesGraphStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { appZIndex } from "../../../constants/appZIndex";
import { TargetSalesGraphUi } from "../../../components/molecules/businessPlan/TargetSalesGraphUi";

export const TargetSalesGraphView = ({
  businessPlanSectionData,
  listOfCopyImage = [],
  listOfDownloadImage = [],
  isFreeVersion = false,
}: BusinessPlanSectionProps<TargetSalesGraphData, BusinessPlanSectionImageListProps>) => {
  const setBusinessPlanSectionData = useSetAtom(targetSalesGraphStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="재무 / 목표 매출 그래프"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H3>재무 / 목표 매출 그래프</BusinessPlanSectionLayout.H3>
      <ImageTooltip
        title={
          <div style={{ display: "flex" }}>
            <Button style={{ zIndex: appZIndex.MAIN_HEADER }} onClick={listOfCopyImage[0]}>
              Copy
            </Button>
            <Empty width="10px" />
            <Button style={{ zIndex: appZIndex.MAIN_HEADER }} onClick={listOfDownloadImage[0]}>
              Download
            </Button>
          </div>
        }
      >
        <TargetSalesGraphUi targetSalesGraphData={businessPlanSectionData} />
      </ImageTooltip>
      <Empty height="1rem" />
      {businessPlanSectionData.detail.map((detail) => (
        <BusinessPlanSectionLayout.P>- {detail}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};
