import React from "react";
import { TableLayout } from "../../layouts/TableLayout";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { LeanCanvasUIContent } from "../../../pages/2_1_창업_아이템_현황/components/LeanCanvasUiContent";
import { LeanCanvasUiDto } from "../../../pages/LeanCanvas/dtos/leanCanvasUi.dto";
import { appSize } from "../../../constants/appSize";
import { useAtomValue } from "jotai";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";

export const LeanCanvasUi = ({ leanCanvasData }: Props) => {
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  return (
    <TableLayout
      id={BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}
      style={{ width: appSize.leanCanvas.width, minHeight: appSize.leanCanvas.height }}
    >
      <TableLayout.TBody>
        <TableLayout.Tr>
          <TableLayout.Td rowSpan={2} colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[0]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
          <TableLayout.Td colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[3]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
          <TableLayout.Td rowSpan={2} colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[2]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
          <TableLayout.Td colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[8]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
          <TableLayout.Td rowSpan={2} colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[1]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
        </TableLayout.Tr>
        <TableLayout.Tr>
          <TableLayout.Td colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[7]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
          <TableLayout.Td colSpan={2}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[4]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
        </TableLayout.Tr>
        <TableLayout.Tr>
          <TableLayout.Td colSpan={5}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[6]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
          <TableLayout.Td colSpan={5}>
            <LeanCanvasUIContent
              leanCanvasUiDtoContent={leanCanvasData?.contents?.[5]}
              isEnglishContents={isEnglish}
            />
          </TableLayout.Td>
        </TableLayout.Tr>
      </TableLayout.TBody>
    </TableLayout>
  );
};

type Props = {
  leanCanvasData: LeanCanvasUiDto;
};
