import React from "react";
import { Button, Grid } from "@mui/material";
import { Empty } from "../../../components/atoms";
import { useSetAtom } from "jotai";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageListProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { clientPersonaStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { ClientPersonaUi } from "../../../components/molecules/businessPlan/ClientPersonaUi";
import { ClientPersonaData } from "..";

export const ClientPersonaView = ({
  businessPlanSectionData,
  listOfCopyImage = [],
  listOfDownloadImage = [],
  isFreeVersion,
  isEditMode = true
}: BusinessPlanSectionProps<ClientPersonaData, BusinessPlanSectionImageListProps>) => {
  const setBusinessPlanSectionData = useSetAtom(clientPersonaStateAtom);
  const freeVersionPersonaData = businessPlanSectionData.persona.slice(2) // NOTICE : Free 버전에서는 두개만 노출

  return (
    <BusinessPlanSectionLayout
      title="타겟 고객 페르소나"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}_BLURRED`}
      isEditMode={isEditMode}
    >
      <BusinessPlanSectionLayout.H3>{`타겟 고객 페르소나 : ${businessPlanSectionData.personaTitle}`}</BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.Blockquote>
        TIP : 고객 페르소나는 내 주요 고객을 대표하는 가상의 인물을 만들어 보여주는 것입니다. 우리의
        고객이 누구인지, 그들의 니즈와 동기가 무엇인지 쉽게 보여줄 수 있습니다. 그런데! 가상의
        페르소나도 좋지만 실제 고객을 보여줄 수 있다면 더 좋을 수 있습니다. 사업을 준비하면서 얻은
        고객 인터뷰, 설문조사, 피드백 혹은 구매 의향서 등과 함께 고객을 보여준다면 보다 설득력 있는
        고객 정의를 할 수 있습니다.
      </BusinessPlanSectionLayout.Blockquote>
      <Empty height="1rem" />
      <Grid container>
        {(isFreeVersion?freeVersionPersonaData:businessPlanSectionData.persona).map((persona, index) => {
          return (
            <Grid xs={6} sx={{ display: "flex", justifyContent: "center" }}>
              <ImageTooltip
                title={
                  <div style={{ display: "flex" }}>
                    <Button onClick={listOfCopyImage[index]}>Copy</Button>
                    <Empty width="10px" />
                    <Button onClick={listOfDownloadImage[index]}>Download</Button>
                  </div>
                }
              >
                <ClientPersonaUi
                  svgId={`${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${index + 1}`}
                  clientPersona={persona}
                />
              </ImageTooltip>
            </Grid>
          );
        })}
      </Grid>
    </BusinessPlanSectionLayout>
  );
};
