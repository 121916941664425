import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useAtomValue, useSetAtom } from "jotai";
import { technologyDevelopmentGoalsStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { appSize } from "../../../constants/appSize";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";

export const SpecificCommercializationGoalsView = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<SpecificCommercializationGoals>) => {
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  const setBusinessPlanSectionData = useSetAtom(technologyDevelopmentGoalsStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="구체적인 사업화 목표"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H2>1. 구체적인 사업화 목표</BusinessPlanSectionLayout.H2>
      {businessPlanSectionData.goals.map((goal, index) => (
        <div key={index}>
          <BusinessPlanSectionLayout.H3>{`${index + 1}. ${
            goal.title
          }`}</BusinessPlanSectionLayout.H3>
          {goal.contents.map((content, index) => (
            <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
          ))}
        </div>
      ))}
      <BusinessPlanSectionLayout.Span />
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.Tr>
          <TableLayout.Th>품목명</TableLayout.Th>
          <TableLayout.Th>판매처</TableLayout.Th>
          <TableLayout.Th>국가명</TableLayout.Th>
          <TableLayout.Th>가격</TableLayout.Th>
          <TableLayout.Th>예상 총 매출액</TableLayout.Th>
        </TableLayout.Tr>
        {businessPlanSectionData.expectedOutcomes.map((outcome, index) => (
          <TableLayout.Tr key={index}>
            <TableLayout.Td>{outcome.itemName}</TableLayout.Td>
            <TableLayout.Td>{outcome.salesOutlet}</TableLayout.Td>
            <TableLayout.Td>{outcome.countryName}</TableLayout.Td>
            <TableLayout.Td>{outcome.price}</TableLayout.Td>
            <TableLayout.Td>{outcome.expectedTotalRevenue}</TableLayout.Td>
          </TableLayout.Tr>
        ))}
      </TableLayout>
    </BusinessPlanSectionLayout>
  );
};

export type SpecificCommercializationGoals = {
  goals: { title: string; contents: string[] }[];
  expectedOutcomes: {
    itemName: string;
    salesOutlet: string;
    countryName: string;
    price: string;
    expectedTotalRevenue: string;
  }[];
} & { searchResult?: SearchResult[] };
