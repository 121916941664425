import React from "react";
import { TableLayout } from "../../layouts/TableLayout";
import { useAtomValue } from "jotai";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";

export const BusinessEntryTableUi = ({ marketEntryStrategy }: Props) => {
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  return (
    <TableLayout>
      <TableLayout.THead>
        <TableLayout.Tr>
          <TableLayout.Th textCenter>{isEnglish ? "Step" : "단계"}</TableLayout.Th>
          <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
          <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
        </TableLayout.Tr>
      </TableLayout.THead>
      <TableLayout.TBody>
        {marketEntryStrategy.map((content, index) => (
          <TableLayout.Tr>
            <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
            <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
            <TableLayout.Td>{content.contents}</TableLayout.Td>
          </TableLayout.Tr>
        ))}
      </TableLayout.TBody>
    </TableLayout>
  );
};

type Props = {
  marketEntryStrategy: {
    title: string;
    contents: string;
  }[];
};
