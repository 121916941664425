import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useAtomValue, useSetAtom } from "jotai";
import { technologyDevelopmentGoalsStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { appSize } from "../../../constants/appSize";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";
import { BusinessEntryTableUi } from "../../../components/molecules/businessPlan/BusinessEntryTableUi";

export const MarketEntryStrategyAndMarketingView = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<MarketEntryStrategyAndMarketing>) => {
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  const setBusinessPlanSectionData = useSetAtom(technologyDevelopmentGoalsStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="시장 진입 및 마케팅 전략"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H3>시장 초기 진입 전략</BusinessPlanSectionLayout.H3>
      <BusinessEntryTableUi marketEntryStrategy={businessPlanSectionData.marketEntryStrategy} />
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.H3>마케팅 전략</BusinessPlanSectionLayout.H3>
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.THead>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
          </TableLayout.Tr>
        </TableLayout.THead>
        <TableLayout.TBody>
          {businessPlanSectionData.marketingStrategy.map((content) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
              <TableLayout.Td>{content.contents}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
    </BusinessPlanSectionLayout>
  );
};

export type MarketEntryStrategyAndMarketing = {
  marketEntryStrategy: { title: string; contents: string }[];
  marketingStrategy: { title: string; contents: string }[];
} & { searchResult?: SearchResult[] };
