import { useEffect, useState } from "react";
import "../../../app.css";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/path";
import { ResGetOutputCollections, promptApi } from "../../../apis/prompt.api";

export const FormListPage = () => {
  const navigate = useNavigate();
  const [formListData, setFormListData] = useState<ResGetOutputCollections>([]);

  useEffect(() => {
    setFormListData([]);
    const timer1 = setInterval(() => {
      window.location.reload();
    }, 10000);

    promptApi.getOutputCollections().then(async (res) => {
      const formList = res.data;
      setFormListData(formList);
      // 전송 처리
      if (formList.length > 0) {
        navigate(PATH.P_S_S_T + "/" + formList[0].id, {
          state: "sendState",
        });
      }
    });

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  // [Todo] 버튼을 눌러서 보내기? -> 더 안전하며 검수 가능
  // [Todo] 자동화 보내기에 meta data 보내기
  return (
    <div className={"form_list_container"}>
      <h1>Form List Page</h1>
      <table className={"list_table_container"}>
        <thead className={"table_header"}>
          <tr>
            <td>ID</td>
            <td>NOTION</td>
            <td>Mail</td>
            <td>CreatedAt</td>
          </tr>
        </thead>
        <tbody>
          {formListData &&
            formListData.map((data: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{data.id}</td>
                  <td>
                    <a href={data.notionLink}>Link to {data.id}</a>
                  </td>
                  <td>{data.isTestMailSent ? "true" : "false"}</td>
                  <td>{data.createdAt}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
