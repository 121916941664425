import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { Stack } from "@mui/material";
import { ItemBackgroundAndNeedsView } from "./components/ItemBackgroundAndNeedsView";
import { Empty } from "../../components/atoms";
import { MarketGrowthGraphView } from "./components/MarketGrowthGraphView";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { isEmpty } from "../../lib/utils/isEmpty";
import { TamSamSomData } from "../1_2_창업아이템_목표_시장(고객)_현황_분석";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 1-1. 창업아이템 배경 및 필요성
 */
export const ItemBackgroundAndNeedsPage = () => {
  const { copyToClipboard, downloadPng } = useExportImageFromHtml();

  // form 일단 보류
  const businessPlanTitleList = ["시장 성장 그래프 + TAM SAM SOM", "배경 및 필요성"];
  const [marketGrowthGraphState, setMarketGrowthGraphState] =
    React.useState<MarketGrowthGraphWithTamSamSom>({} as MarketGrowthGraphWithTamSamSom);
  const [itemBackgroundAndNeedsState, setItemBackgroundAndNeedsState] =
    React.useState<ItemBackgroundAndNeedsData>({} as ItemBackgroundAndNeedsData);
  const setterList = [setMarketGrowthGraphState, setItemBackgroundAndNeedsState];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() => {
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])));
                }}
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(marketGrowthGraphState) && (
              <MarketGrowthGraphView
                businessPlanSectionData={marketGrowthGraphState}
                copyImage={() =>
                  copyToClipboard({
                    selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
                  })
                }
                downloadImage={() =>
                  downloadPng({
                    selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
                    name: `${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}.png`,
                  })
                }
              />
            )}
            <Empty height="2rem" />
            {!isEmpty(itemBackgroundAndNeedsState) && (
              <ItemBackgroundAndNeedsView businessPlanSectionData={itemBackgroundAndNeedsState} />
            )}
          </Stack>
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

// 시장 성장 그래프 + TAM SAM SOM
export type MarketGrowthGraphWithTamSamSom = {
  marketGrowthGraph: MarketGrowthGraphData;
  tamSamSom: TamSamSomData;
} & { searchResult?: SearchResult[] };

export type ItemBackgroundAndNeedsData = {
  backgrounds: {
    title: string;
    contents: string[];
  };
  problems: {
    title: string;
    contents: string[];
  };
  solutions: {
    title: string;
    contents: string[];
  };
  motivations: {
    title: string;
    contents: string[];
  };
} & { searchResult?: SearchResult[] };

export type MarketGrowthGraphData = {
  marketSizeUnit: string;
  target: string;
  contents: {
    year: number;
    value: number;
  }[];
};
