export enum BUSINESS_PLAN_SVG_ID {
  MARKET_GROWTH_GRAPH = "시장_성장_그래프",
  TAM_SAM_SOM = "TAM_SAM_SOM",
  COMPETITOR_ANALYSIS = "경쟁사_분석표",
  CLIENT_PERSONA = "고객_페르소나",
  LEAN_CANVAS = "린_캔버스",
  POSITIONING_MAP = "포지셔닝_맵",
  BUSINESS_MODEL = "비즈니스_모델",
  TARGET_SALES_GRAPH = "목표_매출_그래프",
  FINANCIAL_STATEMENT = "재무제표",
  ACTION_PLAN = "협약_기간_내_사업_추진_일정",
  BUSINESS_ROADMAP = "사업_로드맵",
  BUSINESS_OVERVIEW = "사업_개요",
  BUSINESS_CONCRETIZATION = "사업_구체화",
  BUSINESS_ENTRY_STRATEGY = "사업_진출_전략",
  GLOBAL_PROMOTION_STRATEGY = "글로벌_홍보_전략",
}
