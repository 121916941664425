export const enum LOCAL_STORAGE {
  ACCESS_TOKEN = "accessToken",
  USER_NAME = "userName",
  BUSINESS_PLAN_COLOR = "businessPlanColor",
}

export const enum LOCAL_STORAGE_STATE {
  DEFAULT = "",
  EMPTY = "empty",
}
