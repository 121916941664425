import React from "react";
import { SearchResult } from "../../types/searchResult.type";
import { BusinessPlanSectionLayout } from "../layouts/BusinessPlanSection.layout";
import { useAtomValue } from "jotai";
import { isEnglishDocumentAtom } from "../../store/businessPlanUiState.atom";
import { TableLayout } from "../layouts/TableLayout";
import { appSize } from "../../constants/appSize";

export const ReferenceLinkSection = ({ searchResultList }: Props) => {
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  return (
    <div>
      <BusinessPlanSectionLayout.H3>
        {"<AI 검색을 통한 근거 자료 목록>"}
      </BusinessPlanSectionLayout.H3>
      <TableLayout style={{ maxWidth: appSize.table.width, height: "fit-content" }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>{isEnglish ? "No." : "순번"}</TableLayout.Th>
            <TableLayout.Th textCenter>{"URL"}</TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Summary" : "요약"}</TableLayout.Th>
          </TableLayout.Tr>
          {searchResultList.map((searchResult, index) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
              <TableLayout.Td textCenter>
                <a href={searchResult.url} target="_blank" rel="noopener noreferrer">
                  {searchResult.title}
                </a>
              </TableLayout.Td>
              <TableLayout.Td textCenter>{searchResult?.summary || ""}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
    </div>
  );
};

type Props = {
  searchResultList: SearchResult[];
};
