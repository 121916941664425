import React from "react";
import { useQuery } from "@tanstack/react-query";
import { PromptOutput, ResGetPromptOutputById, promptApi } from "../../apis/prompt.api";
import { getOutputByPromptTypeName } from "../../lib/utils/getOutputByPromptTypeName";
import { useNavigateByPromptCategory } from "./useNavigateByPromptCategory";
import { PROMPT_CATEGORY } from "../../constants/domain/promptCategory";
import { useAtom } from "jotai";
import {
  backgroundAndMotivationStateAtom,
  marketEntryStrategyAndMarketingStateAtom,
  precedingRnDStateAtom,
  researchDevelopmentMethodStateAtom,
  rndPlanStateAtom,
  specificCommercializationGoalsStateAtom,
  technologyDevelopmentGoalsStateAtom,
} from "../../store/rndDataState";
import { TechnologyDevelopmentGoals } from "../../pages/RnD/components/TechnologyDevelopmentGoalsView";
import { ResearchDevelopmentMethod } from "../../pages/RnD/components/ResearchDevelopmentMethodView";
import { PrecedingRnD } from "../../pages/RnD/components/PrecedingRnDView";
import { RnDPlan } from "../../pages/RnD/components/RnDPlanView";
import { SpecificCommercializationGoals } from "../../pages/RnD/components/SpecificCommercializationGoalsView";
import { MarketEntryStrategyAndMarketing } from "../../pages/RnD/components/MarketEntryStrategyAndMarketingView";
import { BackgroundAndMotivation } from "../../pages/RnD/components/BackgroundAndMotivationView";
import { businessModelStateAtom } from "../../store/businessPlanDataState.atom";
import { BusinessModelData } from "../../pages/3_1_창업아이템_사업화_추진_전략";

export const useGetRnDOutputData = ({ rndOutputId }: Props) => {
  const { navigateByPromptCategory } = useNavigateByPromptCategory();

  const { data: promptOutputData } = useQuery({
    queryKey: ["promptOutput", rndOutputId],
    queryFn: async () => {
      const res = await promptApi.getPromptOutputById(rndOutputId);
      const promptCategory = res?.data?.promptCategory?.name;
      if (promptCategory !== PROMPT_CATEGORY.R_N_D) {
        navigateByPromptCategory({ promptCategory, id: rndOutputId });
        return null;
      }

      return {
        ...res?.data,
        outputs: res?.data?.outputs.sort((a: PromptOutput, b: PromptOutput) => b.id - a.id),
      };
    },
  });

  const [technologyDevelopmentGoalsState, setTechnologyDevelopmentGoalsState] = useAtom(
    technologyDevelopmentGoalsStateAtom
  );
  const [researchDevelopmentMethodState, setResearchDevelopmentMethodState] = useAtom(
    researchDevelopmentMethodStateAtom
  );
  const [precedingRnDState, setPrecedingRnDState] = useAtom(precedingRnDStateAtom);
  const [rnDPlanState, setRnDPlanState] = useAtom(rndPlanStateAtom);
  const [specificCommercializationGoalsState, setSpecificCommercializationGoalsState] = useAtom(
    specificCommercializationGoalsStateAtom
  );
  const [marketEntryStrategyAndMarketingState, setMarketEntryStrategyAndMarketingState] = useAtom(
    marketEntryStrategyAndMarketingStateAtom
  );
  const [backgroundAndMotivationState, setBackgroundAndMotivationState] = useAtom(
    backgroundAndMotivationStateAtom
  );
  const [businessModelState, setBusinessModelState] = useAtom(businessModelStateAtom);

  React.useLayoutEffect(() => {
    setTechnologyDevelopmentGoalsState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "1. 기술 개발 목표",
      }) as TechnologyDevelopmentGoals
    );
    setResearchDevelopmentMethodState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "2. 연구개발 방법",
      }) as ResearchDevelopmentMethod
    );
    setPrecedingRnDState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "3. 선행연구개발",
      }) as PrecedingRnD
    );
    setRnDPlanState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "5. 기술 개발 일정",
      }) as RnDPlan
    );
    setSpecificCommercializationGoalsState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "7. 구체적인 사업화 목표",
      }) as SpecificCommercializationGoals
    );
    setMarketEntryStrategyAndMarketingState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "7. 시장진입전략 및 마케팅",
      }) as MarketEntryStrategyAndMarketing
    );
    setBackgroundAndMotivationState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "7. 배경 및 동기",
      }) as BackgroundAndMotivation
    );
    setBusinessModelState(
      getOutputByPromptTypeName({
        promptOutputData: promptOutputData || ({} as ResGetPromptOutputById),
        promptTypeName: "7. 비즈니스 모델",
      }) as BusinessModelData
    );
  }, [promptOutputData]);

  return {
    promptOutputData,
    technologyDevelopmentGoalsState,
    researchDevelopmentMethodState,
    precedingRnDState,
    rnDPlanState,
    specificCommercializationGoalsState,
    marketEntryStrategyAndMarketingState,
    backgroundAndMotivationState,
    businessModelState,
  };
};

type Props = {
  rndOutputId: number;
};
