import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PATH, QUERY_STRING } from "../../constants/path";

/**
 * @description Navigate to a new page
 * path와 쿼리 스트링 타입을 지정하여 버그를 방지
 */
// [Todo] react-router에서는 redirect 사용을 권장하므로 이후에 수정
export const useNaviTo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pathNameStack, setPathNameStack] = React.useState<string[]>([]);

  const naviTo: NaviTo = (path, query, config) => {
    if (!query) navigate(path);
    else {
      const queryString = query.reduce((acc, [curQuery, curValue], index) => {
        if (index === 0) return `${acc}${curQuery}=${curValue}`;
        else return `${acc}&${curQuery}=${curValue}`;
      }, "?");
      navigate(`${path}${queryString}`, { ...config });
    }
  };

  const naviToPrevious = () => {
    navigate(-1);
  };

  /**
   * @param num 0: 현재 페이지, 1: 한 단계 상위 페이지, 2: 두 단계 상위 페이지 ...
   */
  const naviToUpper = (num: number) => {
    if (num === 0) return;
    const _pathNameStack = [...pathNameStack];
    while (num--) {
      _pathNameStack.pop();
    }
    navigate("/" + _pathNameStack.join("/"));
  };

  React.useLayoutEffect(() => {
    setPathNameStack(location.pathname.split("/").filter((pathName) => pathName !== ""));
  }, [location]);

  return { naviTo, naviToUpper, naviToPrevious };
};

export type NaviTo = (
  path: PATH,
  query?: [QUERY_STRING, string | number][],
  config?: { replace?: boolean }
) => void;
