import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNaviTo } from "../../../hooks/infra/useNaviTo";
import { PATH } from "../../../constants/path";

export const BusinessPlanMenu = () => {
  const { naviTo } = useNaviTo();
  return (
    <Stack
      style={{ padding: "1rem", alignItems: "center" }}
      spacing={2}
      width="fit-content"
      direction="row"
    >
      <Typography variant="h5">사업계획서 메뉴</Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => naviTo(PATH.ITEM_BACKGROUND_AND_NEEDS)}
      >
        1-1
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => naviTo(PATH.TARGET_MARKET_ANALYSIS)}
      >
        1-2
      </Button>
      <Button variant="outlined" color="primary" onClick={() => naviTo(PATH.BUSINESS_STATE)}>
        2-1
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => naviTo(PATH.BUSINESS_REALIZATION_AND_CONCRETIZATION_PLAN)}
      >
        2-2
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => naviTo(PATH.BUSINESS_COMMERCIALIZATION_STRATEGY)}
      >
        3-1
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => naviTo(PATH.EFFORTS_TO_IMPROVE_SURVIVAL_RATES)}
      >
        3-2
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => naviTo(PATH.BUSINESS_PROMOTION_SCHEDULE)}
      >
        3-3
      </Button>
    </Stack>
  );
};
