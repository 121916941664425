import * as HTMLtoDOCX from "html-docx-js-typescript";
/**
 * @description HTML을 DOCX 파일로 다운로드하는 훅 (임시)
 */
export const useDownloadHtmlToDocx = () => {
  // [Todo] 폰트 설정
  // 이미지 바꿔서 넣기
  //

  /**
   * @param outerHTML HTML 문자열
   * @param fileName DOCX 파일명 (.docx 확장자 포함 필요)
   */
  const downloadHtmlToDocx = async ({ outerHTML, fileName }: PropsDownloadHtmlToDocx) => {
    const docx = await HTMLtoDOCX.asBlob(outerHTML);
    const link = document.createElement("a");
    const url = URL.createObjectURL(docx as Blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { downloadHtmlToDocx };
};

type PropsDownloadHtmlToDocx = {
  outerHTML: string;
  fileName: string;
};
