import React from "react";
import { LeanCanvasData } from "..";
import { LeanCanvasUiDto } from "../../LeanCanvas/dtos/leanCanvasUi.dto";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { Button } from "@mui/material";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { useSetAtom } from "jotai";
import { leanCanvasStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { Empty } from "../../../components/atoms";
import { LeanCanvasUi } from "../../../components/molecules/businessPlan/LeanCanvasUi";

export const LeanCanvasUiView = ({
  businessPlanSectionData,
  copyImage,
  downloadImage,
  isFreeVersion = false,
  isEditMode
}: BusinessPlanSectionProps<LeanCanvasData, BusinessPlanSectionImageProps>) => {
  const leanCanvasUiDto = new LeanCanvasUiDto({ ...businessPlanSectionData, id: "" });

  const setBusinessPlanSectionData = useSetAtom(leanCanvasStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="린 캔버스"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
      isEditMode={isEditMode}
    >
      <BusinessPlanSectionLayout.Blockquote>
        TIP : 자유도가 가장 높은 항목입니다. 지금까지 진행한 기획, 개발 단계 혹은 성과들을 마음껏
        보여주시면 됩니다. MVP 테스트나 프로토타입이어도 좋고, 초기 기획 시 작성했던 낙서같은 노트도
        상관없습니다. 이 아이템과 사업계획서를 단지 지원사업용으로 급하게 만든 것이 아닌, 진심으로
        준비해왔음을 보여주세요!
      </BusinessPlanSectionLayout.Blockquote>
      <BusinessPlanSectionLayout.H3>Lean Canvas</BusinessPlanSectionLayout.H3>
      <ImageTooltip
        title={
          <div style={{ display: "flex" }}>
            <Button onClick={copyImage}>Copy</Button>
            <Empty width="10px" />
            <Button onClick={downloadImage}>Download</Button>
          </div>
        }
      >
        <LeanCanvasUi leanCanvasData={leanCanvasUiDto} />
      </ImageTooltip>
    </BusinessPlanSectionLayout>
  );
};
