// [Todo] 색상 정리 필요
const originColor = {
  green50: "#66dca6",
  green100: "#E9F8ED",
  green200: "#00C66B",
  green200Opacity: "rgba(0, 198, 107, 0.5)",
  green300: "#00AA5C",
  green400: "#007640",
  grey50: "#f9f9f9",
  grey100: "#eeeeee",
  grey200: "#dedede",
  grey300: "#bdbdbd",
  grey400: "#959492",
  grey500: "#717171",
  grey600: "#595959",
  grey700: "#313131",
  black: "#191919",
  white: "#FFFFFF",
  whiteOpacity: "rgba(255, 255, 255, 0.9)",
  red: "#DC2B2B",
};

export const appColor = {
  ...originColor,
  backGround: originColor.white,
  bgCard: originColor.grey50,
  primary: originColor.green200,
  primaryLight: originColor.green100,
  text: originColor.grey700,
  disabled: originColor.grey300,
  border: originColor.grey200,
  shadow: originColor.grey300,
};
