import Color from "color";

/**
 * Adjusts the brightness of a hex color
 * @param hexColor The hex color to adjust
 * @param magnitude The magnitude of the adjustment. Positive values will darken the color, negative values will lighten the color.
 * @returns The adjusted hex color string (e.g. #ffffff)
 */
export const adjustBrightness = (hexColor: string, magnitude: number) => {
  const color = Color(hexColor);

  if (magnitude > 0) return color.darken(magnitude).hex();
  else return color.lighten(-magnitude).hex();
};
