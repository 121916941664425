import { AppRoutes } from "./pages/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { JotaiProvider, MuiThemeProvider, TanstackReactQueryProvider } from "./config/providers";

function App() {
  return (
    <Router>
      <MuiThemeProvider>
        <JotaiProvider>
          <TanstackReactQueryProvider>
            <AppRoutes />
          </TanstackReactQueryProvider>
        </JotaiProvider>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
