import React from "react";
import { BusinessOverviewData } from "..";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { useSetAtom } from "jotai";
import { businessOverviewStateAtom } from "../../../store/businessPlanDataState.atom";

export const BusinessOverviewView = ({
  businessPlanSectionData,
  isFreeVersion = false,
}: BusinessPlanSectionProps<BusinessOverviewData>) => {
  const setBusinessPlanSectionData = useSetAtom(businessOverviewStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="사업 개요"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_OVERVIEW}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H3>{businessPlanSectionData.title}</BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.progress.map((progress) => {
        return <BusinessPlanSectionLayout.P>- {progress}</BusinessPlanSectionLayout.P>;
      })}
    </BusinessPlanSectionLayout>
  );
};
