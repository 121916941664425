import React from "react";
import { SvgLayout } from "../../layouts/SvgLayout";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { appColor } from "../../../constants/appColor";
import { useAtomValue } from "jotai";
import { businessPlanPaletteAtom } from "../../../store/businessPlanUiState.atom";
import { MarketGrowthGraphData } from "../../../pages/1_1_창업아이템_배경_및_필요성";
import { appSize } from "../../../constants/appSize";

export const MarketGrowthGraphUi = ({
  marketGrowthGraph,
}: {
  marketGrowthGraph: MarketGrowthGraphData;
}) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);

  const xAxisYposition = 350;

  const maxY = Math.max(...(marketGrowthGraph?.contents.map((content) => content.value) ?? []));

  return (
    <SvgLayout
      id={BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}
      width={appSize.marketGrowthGraph.width}
      height={appSize.marketGrowthGraph.height}
    >
      <line x1={40} x2={560} y1={350} y2={350} stroke={appColor.black} strokeWidth="1" />
      {/* dotdotdot */}
      <circle cx={441} cy={260} r={2} fill={appColor.black} />
      <circle cx={449} cy={260} r={2} fill={appColor.black} />
      <circle cx={456} cy={260} r={2} fill={appColor.black} />
      {/* year list */}
      {marketGrowthGraph.contents.map((content, index) => {
        const x =
          ((index + 1) * 600) / (marketGrowthGraph.contents.length + 2 - 1) +
          (index === 4 ? +15 : -15);
        return (
          <>
            <line
              x1={x}
              x2={x}
              y1={351}
              y2={356}
              stroke={appColor.black}
              strokeWidth="1"
              strokeLinecap="round"
            />
            <foreignObject x={x - 30} y={355} width="60" height="20" textAnchor="center">
              <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                <span>{content.year}</span>
              </div>
            </foreignObject>
          </>
        );
      })}
      {marketGrowthGraph?.contents.map((content, index) => {
        const x =
          ((index + 1) * 600) / (marketGrowthGraph?.contents?.length + 2 - 1) +
          (index === 4 ? +15 : -15);
        const yTopMargin = 80;
        const y = xAxisYposition - (xAxisYposition - yTopMargin) * (content.value / maxY);
        return (
          <>
            {/* 막대 그래프 */}
            <rect
              x={x - 30}
              y={y - 1}
              width={60}
              height={xAxisYposition - y + 1}
              fill={businessPlanPalette.primary}
            />
            {/* 선 그래프 */}
            {/* {index !== 0 && (
        <line
        x1={20 + ((index + 0) * 680) / (marketGrowthGraph?.contents.length + 2 - 1)}
        x2={20 + ((index + 1) * 680) / (marketGrowthGraph?.contents.length + 2 - 1)}
        y1={
          380 -
          ((marketGrowthGraph?.contents[index - 1].value - minY) * 250) /
          (maxY - minY) -
          50
        }
        y2={y}
        stroke={appColor.primary}
        strokeWidth="3"
        strokeLinecap="round"
        />
      )} */}
            <foreignObject x={x - 100} y={y - 25} width="200" height="20" textAnchor="center">
              <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight:
                      index === marketGrowthGraph?.contents.length - 1 ? "bold" : "normal",
                  }}
                >
                  {content.value.toLocaleString("ko-KR", { maximumFractionDigits: 2 })}
                </span>
              </div>
            </foreignObject>
          </>
        );
      })}
    </SvgLayout>
  );
};
