import { Typography } from "@mui/material";
import { Empty } from "../../../components/atoms";
import { appColor } from "../../../constants/appColor";
import { LeanCanvasUiDtoContent } from "../../LeanCanvas/dtos/leanCanvasUi.dto";

export const LeanCanvasUIContent = ({
  leanCanvasUiDtoContent,
  isEnglishContents = false,
}: Props) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: appColor.black }}>
          {isEnglishContents
            ? `${leanCanvasUiDtoContent.no}. ${leanCanvasUiDtoContent.engTitle}`
            : `${leanCanvasUiDtoContent.no}. ${leanCanvasUiDtoContent.title}`}
        </Typography>
      </div>
      {!isEnglishContents ? (
        <Typography variant="BodySmall" sx={{ color: appColor.black }}>
          {leanCanvasUiDtoContent.engTitle}
        </Typography>
      ) : (
        <Empty height="0.4rem" />
      )}
      <Empty height="0.4rem" />
      {leanCanvasUiDtoContent.content.split("\n").map((line, index) => (
        <Typography variant="body1" key={index}>{`${line}`}</Typography>
      ))}
    </>
  );
};

type Props = {
  leanCanvasUiDtoContent: LeanCanvasUiDtoContent;
  isEnglishContents?: boolean;
};
