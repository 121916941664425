import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useAtomValue, useSetAtom } from "jotai";
import { researchDevelopmentMethodStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { appSize } from "../../../constants/appSize";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";

export const ResearchDevelopmentMethodView = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<ResearchDevelopmentMethod>) => {
  const setBusinessPlanSectionData = useSetAtom(researchDevelopmentMethodStateAtom);

  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  return (
    <BusinessPlanSectionLayout
      title="연구 개발 방법"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      {businessPlanSectionData.developInfo.map((data, index) => (
        <>
          <BusinessPlanSectionLayout.H3>
            {index + 1}. {data.title}
          </BusinessPlanSectionLayout.H3>
          {data.contents.map((content, index) => (
            <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
          ))}
        </>
      ))}
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.H2>성과 지표</BusinessPlanSectionLayout.H2>
      <BusinessPlanSectionLayout.H3>결과물의 성능지표</BusinessPlanSectionLayout.H3>
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            {Object.keys(businessPlanSectionData.performanceMetric[0]).map((key) => (
              <TableLayout.Th key={key}>{key}</TableLayout.Th>
            ))}
          </TableLayout.Tr>
          {businessPlanSectionData.performanceMetric.map((data, index) => (
            <TableLayout.Tr key={index}>
              {Object.values(data).map((value, index) => (
                <TableLayout.Td key={index}>{value}</TableLayout.Td>
              ))}
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.H2>평가 방법 및 평가 환경</BusinessPlanSectionLayout.H2>
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th>{isEnglish ? "No." : "순번"}</TableLayout.Th>
            <TableLayout.Th>{isEnglish ? "Criteria" : "평가 기준"}</TableLayout.Th>
            <TableLayout.Th>{isEnglish ? "Method" : "평가 방법"}</TableLayout.Th>
            <TableLayout.Th>{isEnglish ? "Environment" : "평가 환경"}</TableLayout.Th>
          </TableLayout.Tr>
          {businessPlanSectionData.evaluationMethod.map((data, index) => (
            <TableLayout.Tr key={index}>
              <TableLayout.Td>{index + 1}</TableLayout.Td>
              <TableLayout.Td>{data.assessmentCriteria}</TableLayout.Td>
              <TableLayout.Td>{data.assessmentMethod}</TableLayout.Td>
              <TableLayout.Td>{data.assessmentEnvironment}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
    </BusinessPlanSectionLayout>
  );
};

export type ResearchDevelopmentMethod = {
  developInfo: { title: string; contents: string[] }[];
  performanceMetric: { [key: string]: string }[];
  evaluationMethod: {
    assessmentCriteria: string;
    assessmentMethod: string;
    assessmentEnvironment: string;
  }[];
} & { searchResult?: SearchResult[] };
