// [Todo] 사업계획서에 들어가는 각각의 이미지 사이즈를 정의
const businessPlanImageSize = {
  marketGrowthGraph: {
    width: "600px",
    height: "400px",
  },
  clientPersona: {
    width: "400px",
    height: "700px",
  },
  positioningMap: {
    width: "720px",
    height: "420px",
  },
  competitorAnalysis: {
    width: "1200px",
    height: "420px",
  },
  businessModel: {
    width: "720px",
    height: "420px",
  },
  tamSamSom: {
    width: "765px",
    height: "400px",
  },
  leanCanvas: {
    width: "880px",
    height: "580px",
  },
  targetSalesGraph: {
    width: "600px",
    height: "400px",
  },
  table: {
    width: "600px",
    height: "fit-content",
  },
};

/**
 * @description
 * Specify if component sizes are used in duplicate
 */
export const appSize = {
  mainSideNavMinWidth: "15rem",
  mainSideNavMaxWidth: "20rem",
  floatingBarWidth: "4rem",
  mainHeaderHeight: "4rem",
  businessPlanWidth: "900px",
  ...businessPlanImageSize,
};
