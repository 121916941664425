import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import PptxGenJS from "pptxgenjs";
import { Button, Typography } from "@mui/material";
import { useAlert } from "../../hooks/infra/useAlert";
import { useParams } from "react-router-dom";
import { BusinessPlanColorPicker } from "../../components/organisms/BusinessPlanColorPicker";
import { useAtomValue } from "jotai";
import { businessPlanPaletteAtom } from "../../store/businessPlanUiState.atom";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { IrPptxSlide } from "./IrPptSlide";
import { isEmpty } from "../../lib/utils/isEmpty";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { MarketGrowthGraphView } from "../1_1_창업아이템_배경_및_필요성/components/MarketGrowthGraphView";
import { useGetBusinessPlanOutputData } from "../../hooks/domain/useGetBusinessPlanOutputById";
import { mockResGetPptOutputById } from "../../mocks";
import { ClientPersonaView } from "../1_2_창업아이템_목표_시장(고객)_현황_분석/components/ClientPersonaView";
import { PositioningMapView } from "../2_2_창업_아이템_실현_및_구체화_방안/components/PositioningMapView";
import { CompetitorAnalysisAndDifferentiationPlanView } from "../2_2_창업_아이템_실현_및_구체화_방안/components/CompetitorAnalysisAndDifferentiationPlanView";
import { BusinessModelView } from "../3_1_창업아이템_사업화_추진_전략/components/businessModelView";
import { TamSamSomView } from "../1_2_창업아이템_목표_시장(고객)_현황_분석/components/TamSamSomView";
import { TargetSalesGraphView } from "../3_2_생존율_제고를_위한_노력/components/TargetSalesGraphView";
import { useGetIrOutputData } from "../../hooks/domain/useGetIrOutputData";

// [Todo] 추 후 여러 양식의 Ir이 존재하지 않을까 생각.
// [Todo] 사업계획서 페이지에서 Ir 타입이면 여기로 네비게이션 되도록 수정
export const IrPage = () => {
  const { Alert, openAlert } = useAlert();
  const { irOutputId } = useParams();
  const { getPreEncodedBase64Image } = useExportImageFromHtml();

  const {
    parents: {
      marketGrowthGraphWithTamSamSomState,
      clientPersonaState,
      positioningMapState,
      competitorAnalysisAndDifferentiationPlanState,
      businessModelState,
      targetSalesGraphState,
      businessEntryStrategyState,
      businessRoadmapState,
    },
    irDataState,
  } = useGetIrOutputData({ irOutputId: Number(irOutputId) });
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);

  const presentation = new PptxGenJS();

  const svgSelectorList = [
    `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
    `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}1`,
    `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}2`,
    `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
    `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}1`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}2`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}3`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}4`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}5`,
    `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
    `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
  ];

  const onClick = async () => {
    openAlert({ contents: "다운로드 중입니다...", severity: "info" });

    const htmlElementList = svgSelectorList.map((selector) => {
      return document.querySelector(selector);
    });

    const imageDataList = htmlElementList.map((element) => {
      if (!element) return { width: "0px", height: "0px" };
      const width = element.clientWidth + "px";
      const height = element.clientHeight + "px";
      return { width, height };
    });

    const base64ImageList = await Promise.all(
      svgSelectorList.map(async (selector) => {
        return getPreEncodedBase64Image({ selector });
      })
    );

    presentation.layout = "LAYOUT_16x9";
    irDataState?.pages?.forEach(async (irData, index) => {
      const irSlide = new IrPptxSlide({
        businessPlanPalette,
        pptxSlide: presentation.addSlide(),
        presentation,
      });
      switch (irData?.order) {
        case 1:
          irSlide.setIntro({
            irData: irData,
          });
          break;
        case 2:
          irSlide.addIndex("1. 문제인식 (Problem)").setTemplate1({
            irData: irData,
            rawImageData: {
              width: imageDataList[0].width,
              height: imageDataList[0].height,
              base64: base64ImageList[0] || "",
            },
          });
          break;
        case 3:
          irSlide.addIndex("1. 문제인식 (Problem)").setTemplate4({
            irData: irData,
            rawImageData1: {
              width: imageDataList[1].width,
              height: imageDataList[1].height,
              base64: base64ImageList[1] || "",
            },
            rawImageData2: {
              width: imageDataList[2].width,
              height: imageDataList[2].height,
              base64: base64ImageList[2] || "",
            },
          });
          break;
        case 4:
          irSlide.addIndex("2. 실현가능성 (Solution)").setTemplate3({ irData: irData });
          break;
        case 5:
          irSlide.addIndex("2. 실현가능성 (Solution)").setTemplate3({ irData: irData });
          break;
        case 6:
          irSlide.addIndex("2. 실현가능성 (Solution)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[3].width,
              height: imageDataList[3].height,
              base64: base64ImageList[3] || "",
            },
          });
          break;
        case 7:
          irSlide.addIndex("2. 실현가능성 (Solution)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[4].width,
              height: imageDataList[4].height,
              base64: base64ImageList[4] || "",
            },
          });
          break;
        case 8:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[5].width,
              height: imageDataList[5].height,
              base64: base64ImageList[5] || "",
            },
          });
          break;
        case 9:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[6].width,
              height: imageDataList[6].height,
              base64: base64ImageList[6] || "",
            },
          });
          break;
        case 10:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[7].width,
              height: imageDataList[7].height,
              base64: base64ImageList[7] || "",
            },
          });
          break;
        case 11:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[8].width,
              height: imageDataList[8].height,
              base64: base64ImageList[8] || "",
            },
          });
          break;
        case 12:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate5({
            irData: irData,
            rawImageData: {
              width: imageDataList[9].width,
              height: imageDataList[9].height,
              base64: base64ImageList[9] || "",
            },
          });
          break;
        case 13:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate1({
            irData: irData,
            rawImageData: {
              width: imageDataList[10].width,
              height: imageDataList[10].height,
              base64: base64ImageList[10] || "",
            },
          });
          break;
        case 14:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate6({
            irData: irData,
            tabledata: {
              tableRows: [
                [{ text: "단계" }, { text: "제목" }, { text: "내용" }],
                ...(businessEntryStrategyState?.marketEntryStrategy
                  ? businessEntryStrategyState?.marketEntryStrategy?.map((strategy, index) => [
                      { text: `${index + 1}` },
                      { text: strategy.title },
                      { text: strategy.contents },
                    ]) || []
                  : businessEntryStrategyState?.marketEntryStratege
                  ? businessEntryStrategyState?.marketEntryStratege?.map((strategy, index) => [
                      { text: `${index + 1}` },
                      { text: strategy.title },
                      { text: strategy.contents },
                    ]) || []
                  : []),
              ],
              options: { colW: [1, 3, 4] },
            },
          });
          break;
        case 15:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate6({
            irData: irData,
            tabledata: {
              tableRows: [
                [{ text: "제목" }, { text: "내용" }],
                ...(businessEntryStrategyState?.marketingStrategy
                  ? businessEntryStrategyState?.marketingStrategy?.map((strategy, index) => [
                      { text: strategy.title },
                      { text: strategy.contents },
                    ]) || []
                  : businessEntryStrategyState?.marketingStratege
                  ? businessEntryStrategyState?.marketingStratege?.map((strategy, index) => [
                      { text: strategy.title },
                      { text: strategy.contents },
                    ]) || []
                  : []),
              ],
              options: { colW: [4, 4] },
            },
          });
          break;
        case 16:
          // [Todo] 15에 표 넣기 + 세부 전략 프롬프팅 필요 추가
          break;
        case 17:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate1({
            irData: irData,
            rawImageData: {
              width: imageDataList[11].width,
              height: imageDataList[11].height,
              base64: base64ImageList[11] || "",
            },
          });
          break;
        case 18:
          irSlide.addIndex("3. 성장전략 (Scale-up)").setTemplate7({
            irData: irData,
            tabledata1: {
              tableRows: [
                [
                  { text: "순번" },
                  { text: "추진 내용" },
                  { text: "추진 기간" },
                  { text: "세부 내용" },
                ],
                ...(businessRoadmapState?.businessRoadMap?.map((strategy, index) => [
                  { text: `${index + 1}` },
                  { text: strategy.promotionTitle },
                  { text: strategy.promotionPeriod },
                  { text: strategy.promotionDetail },
                ]) || []),
              ],
              options: { colW: [0.5, 1.5, 1, 1.5] },
            },
            tabledata2: {
              tableRows: [
                [
                  { text: "순번" },
                  { text: "추진 내용" },
                  { text: "추진 기간" },
                  { text: "세부 내용" },
                ],
                ...(businessRoadmapState?.actionPlan?.map((strategy, index) => [
                  { text: `${index + 1}` },
                  { text: strategy.promotionTitle },
                  { text: strategy.promotionPeriod },
                  { text: strategy.promotionDetail },
                ]) || []),
              ],
              options: { colW: [0.5, 1.5, 1, 1.5] },
            },
          });
          break;

        default:
          break;
      }
    });

    await presentation.writeFile({ fileName: "IR.pptx" });
    openAlert({ contents: "다운로드 완료", severity: "success" });
  };

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Absolute>
        <Alert />
      </PageLayout.Absolute>
      <PageLayout.Main>
        <BusinessPlanColorPicker />
        <Typography variant="h1">IR 미리보기</Typography>
        <Typography>(주의) 색상 변경 등 작업이 보다 오래 소요됩니다!</Typography>
        <Button onClick={onClick}>PPT 다운로드</Button>
        <div>
          {!isEmpty(marketGrowthGraphWithTamSamSomState) && (
            <MarketGrowthGraphView
              businessPlanSectionData={marketGrowthGraphWithTamSamSomState}
              copyImage={() => {}}
              downloadImage={() => {}}
            />
          )}
          {!isEmpty(clientPersonaState) && (
            <ClientPersonaView
              businessPlanSectionData={clientPersonaState}
              listOfCopyImage={[]}
              listOfDownloadImage={[]}
            />
          )}
          {!isEmpty(positioningMapState) &&
            !isEmpty(competitorAnalysisAndDifferentiationPlanState) && (
              <div>
                <PositioningMapView
                  businessPlanSectionData={positioningMapState}
                  copyImage={() => {}}
                  downloadImage={() => {}}
                />
                <CompetitorAnalysisAndDifferentiationPlanView
                  businessPlanSectionData={competitorAnalysisAndDifferentiationPlanState}
                  copyImage={() => {}}
                  downloadImage={() => {}}
                />
              </div>
            )}
          {!isEmpty(businessModelState) && (
            <BusinessModelView
              businessPlanSectionData={businessModelState}
              listOfCopyImage={[]}
              listOfDownloadImage={[]}
            />
          )}
          {!isEmpty(marketGrowthGraphWithTamSamSomState) && (
            <TamSamSomView
              businessPlanSectionData={marketGrowthGraphWithTamSamSomState}
              copyImage={() => {}}
              downloadImage={() => {}}
            />
          )}
          {!isEmpty(targetSalesGraphState) && (
            <TargetSalesGraphView
              businessPlanSectionData={targetSalesGraphState}
              listOfCopyImage={[]}
              listOfDownloadImage={[]}
            />
          )}
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
};
