import React from "react";
import { Modal as MuiModal } from "@mui/material";
import { Box } from "@mui/system";
import { appColor } from "../../constants/appColor";

// [Todo] Modal 컴포넌트가 아닌 ref 방식은 어떨까
export const useModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Modal = ({ children, height = "50%", width = "50%", style }: Props) => {
    return (
      <MuiModal sx={{ height: "100%" }} open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width,
            height: height,
            backgroundColor: appColor.backGround,
            borderRadius: "0.5rem",
            boxShadow: 24,
            padding: 5,
            overflow: "scroll",
            "&::-webkit-scrollbar": { width: 0 },
            ...style,
          }}
        >
          {children}
        </Box>
      </MuiModal>
    );
  };

  return { handleOpen, handleClose, Modal };
};

type Props = {
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
};
