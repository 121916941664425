import React from "react";
import { appColor } from "../../../constants/appColor";
import { MermaidComponent } from "../../atoms";
import { useAtomValue } from "jotai";
import { businessPlanPaletteAtom } from "../../../store/businessPlanUiState.atom";
import { appSize } from "../../../constants/appSize";

export const BusinessModelUi = ({ businessModelData, svgId }: Props) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);

  // [Todo] 스타일 관리 필요
  const initTheme = `
  %%{
    init: {
      'theme': 'neutral',
      'themeVariables': {
        'noteBkgColor': '${businessPlanPalette.primaryLightest}',
        'primaryTextColor': '${appColor.black}',
        'primaryBorderColor': '${appColor.white}'
      }
    }
  }%%
  `;

  const chartStyle = `
  classDef emphasizedNode fill:${businessPlanPalette.primaryLightest},font-size:28px,font-weight:bold,color:${businessPlanPalette.primaryDarkest};

  class ITEM emphasizedNode;
  `;

  return (
    <div
      id={svgId}
      style={{
        width: appSize.businessModel.width,
        display: "flex",
        justifyContent: "center",
        backgroundColor: appColor.white,
      }}
    >
      <MermaidComponent
        mermaidKey={`${svgId}_mermaid`}
        code={businessModelData?.mermaid}
        style={chartStyle}
        initTheme={initTheme}
      />
    </div>
  );
};

type Props = {
  businessModelData: {
    title: string;
    format: string;
    contents: string[];
    mermaid: string;
  };
  svgId: string;
};
