import { Typography } from "@mui/material";

export const ErorrPage = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
      <Typography variant="h1">404</Typography>
    </div>
  );
};
