import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useSetAtom } from "jotai";
import { precedingRnDStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";

export const PrecedingRnDView = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<PrecedingRnD>) => {
  const setBusinessPlanSectionData = useSetAtom(precedingRnDStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="선행 연구 개발"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H2>1. 선행연구개발 이력</BusinessPlanSectionLayout.H2>
      {businessPlanSectionData.priorResearchHistory.map((data, index) => (
        <>
          <BusinessPlanSectionLayout.H3>
            {index + 1}. {data.title}
          </BusinessPlanSectionLayout.H3>
          {data.contents.map((content, index) => (
            <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
          ))}
        </>
      ))}
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.H2>
        2. 본 과제 수행 시 선행연구개발 결과 활용계획
      </BusinessPlanSectionLayout.H2>
      {businessPlanSectionData.planForUtilizingPriorResearch.map((data, index) => (
        <>
          <BusinessPlanSectionLayout.H3>
            {index + 1}. {data.title}
          </BusinessPlanSectionLayout.H3>
          {data.contents.map((content, index) => (
            <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
          ))}
        </>
      ))}
    </BusinessPlanSectionLayout>
  );
};

export type PrecedingRnD = {
  priorResearchHistory: { title: string; contents: string[] }[];
  planForUtilizingPriorResearch: { title: string; contents: string[] }[];
} & { searchResult?: SearchResult[] };
