import React from "react";
import { Button } from "@mui/material";

export const ImageTooltip = ({ children, title }: Props) => {
  const [isHover, setIsHover] = React.useState<boolean>(false);

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#f5f5f5",
        width: "fit-content",
        height: "fit-content",
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        style={{
          position: "absolute",
          right: "0.4rem",
          top: "0.4rem",
          display: isHover ? "block" : "none",
        }}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

type Props = {
  children: React.ReactNode;
  title: React.ReactNode;
};
