import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { TargetSalesGraphView } from "./components/TargetSalesGraphView";
import { Empty } from "../../components/atoms";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { Stack } from "@mui/material";
import { isEmpty } from "../../lib/utils/isEmpty";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { GlobalPromotionStrategyAndBusinessEntryStrategyView } from "./components/GlobalPromotionStrategyAndBusinessEntryStrategyView";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 3-2. 생존율 제고를 위한 노력
 */
export const EffortsToImproveSurvivalRatesPage = () => {
  const { copyToClipboard, downloadPng } = useExportImageFromHtml();

  // form 일단 보류
  const businessPlanTitleList = ["목표 매출 그래프", "글로벌 시장 진입 및 마케팅 전략"];
  const [targetSalesGraphState, setTargetSalesGraphState] = React.useState<TargetSalesGraphData>(
    {} as TargetSalesGraphData
  );
  const [
    globalPromotionStrategyAndBusinessEntryStrategyState,
    setGlobalPromotionStrategyAndMarketEntryStrategyState,
  ] = React.useState<GlobalPromotionStrategyAndBusinessEntryStrategyData>(
    {} as GlobalPromotionStrategyAndBusinessEntryStrategyData
  );
  const setterList = [
    setTargetSalesGraphState,
    setGlobalPromotionStrategyAndMarketEntryStrategyState,
  ];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() => {
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])));
                }}
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(targetSalesGraphState) && (
              <TargetSalesGraphView
                businessPlanSectionData={targetSalesGraphState}
                listOfCopyImage={[
                  () =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
                    }),
                  () =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
                    }),
                ]}
                listOfDownloadImage={[
                  () =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
                      name: `${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}.png`,
                    }),
                  () =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
                      name: `${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}.png`,
                    }),
                ]}
              />
            )}
            <Empty height="2rem" />
            {!isEmpty(globalPromotionStrategyAndBusinessEntryStrategyState) && (
              <GlobalPromotionStrategyAndBusinessEntryStrategyView
                businessPlanSectionData={globalPromotionStrategyAndBusinessEntryStrategyState}
              />
            )}
          </Stack>
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

export type TargetSalesGraphData = {
  marketSizeUnit: string;
  contents: {
    year: number;
    totalRevenue: number;
    cost: { [criteria: string]: number };
  }[];
  detail: string[];
} & { searchResult?: SearchResult[] };

export type GlobalPromotionStrategyData = {
  globalMarketTarget: string;
  background: {
    title: string;
    contents: string[];
  };
  solution: {
    title: string;
    contents: string[];
  };
} & { searchResult?: SearchResult[] };

export type GlobalPromotionStrategyAndBusinessEntryStrategyData = {
  globalMarketTarget: string;
  background: { title: string; contents: string[] };
  solution: { title: string; contents: string[] };
  marketEntryStrategy: { title: string; contents: string }[];
  marketingStrategy: { title: string; contents: string }[];
  marketingStrategyDetail: { title: string; rows: { criteria: string; content: string }[] }[];
} & { searchResult?: SearchResult[] };
