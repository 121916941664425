import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { Stack } from "@mui/material";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { TamSamSomView } from "./components/TamSamSomView";
import { Empty } from "../../components/atoms";
import { ClientPersonaView } from "./components/ClientPersonaView";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { isEmpty } from "../../lib/utils/isEmpty";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { MarketGrowthGraphWithTamSamSom } from "../1_1_창업아이템_배경_및_필요성";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { ClientPersona } from "../../components/molecules/businessPlan/ClientPersonaUi";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 1-2. 창업아이템 목표 시장(고객) 현황 분석
 */
export const TargetMarketAnalysisPage = () => {
  const { copyToClipboard, downloadPng } = useExportImageFromHtml();

  // form 일단 보류
  const businessPlanTitleList = ["시장 성장 그래프 + TAM SAM SOM", "고객 페르소나"];
  const [tamSamSomState, setTamSamSomState] = React.useState<MarketGrowthGraphWithTamSamSom>(
    {} as MarketGrowthGraphWithTamSamSom
  );
  const [clientPersonaState, setClientPersonaState] = React.useState<ClientPersonaData>(
    {} as ClientPersonaData
  );
  const setterList = [setTamSamSomState, setClientPersonaState];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() => {
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])));
                }}
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(tamSamSomState) && (
              <TamSamSomView
                businessPlanSectionData={tamSamSomState}
                copyImage={() =>
                  copyToClipboard({
                    selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
                  })
                }
                downloadImage={() =>
                  downloadPng({
                    selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
                    name: `${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}.png`,
                  })
                }
              />
            )}
            <Empty height="1rem" />
            {!isEmpty(clientPersonaState) && (
              <ClientPersonaView
                businessPlanSectionData={clientPersonaState}
                listOfCopyImage={[1, 2, 3, 4].map(
                  (num) => () =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                    })
                )}
                listOfDownloadImage={[1, 2, 3, 4].map(
                  (num) => () =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                      name: `${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}.png`,
                    })
                )}
              />
            )}
          </Stack>
          <Empty height="1rem" />
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

export type TamSamSomData = {
  title: string;
  tam: Omit<MarketStateType, "color">;
  sam: Omit<MarketStateType, "color">;
  som: Omit<MarketStateType, "color">;
  somDetail: string[];
};

export type MarketStateType = {
  color: string;
  marketSizeNum: number;
  marketSizeUnit: string;
  marketSizeJustification: string;
  description: string;
};

export type ClientPersonaData = {
  persona: ClientPersona[];
  personaTitle: string;
} & { searchResult?: SearchResult[] };
