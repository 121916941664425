import React from "react";
import { useAlert } from "../../hooks/infra/useAlert";
import { useDownloadBusinessPlanImage } from "../사업계획서/hooks/useDownloadBusinessPlanImage";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../components/layouts/Page.layout";
import { BusinessPlanHeader } from "../../components/organisms/BusinessPlanHeader";
import { BusinessPlanCautionSection } from "../../components/molecules/businessPlan/BusinessPlanCautionSection";
import { appSize } from "../../constants/appSize";
import { BusinessPlanBlurArea } from "../../components/molecules/BusinessPlanBlurArea";
import { BusinessPlanSectionLayout } from "../../components/layouts/BusinessPlanSection.layout";
import { ErrorBoundary } from "react-error-boundary";
import { isEmpty } from "../../lib/utils/isEmpty";
import { TechnologyDevelopmentGoalsView } from "./components/TechnologyDevelopmentGoalsView";
import { ReferenceLinkSection } from "../../components/molecules/ReferenceLinkSection";
import { useGetRnDOutputData } from "../../hooks/domain/useGetRnDOutputById";
import { ResearchDevelopmentMethodView } from "./components/ResearchDevelopmentMethodView";
import { PrecedingRnDView } from "./components/PrecedingRnDView";
import { RnDPlanView } from "./components/RnDPlanView";
import { SpecificCommercializationGoalsView } from "./components/SpecificCommercializationGoalsView";
import { MarketEntryStrategyAndMarketingView } from "./components/MarketEntryStrategyAndMarketingView";
import { BackgroundAndMotivationView } from "./components/BackgroundAndMotivationView";
import { BusinessModelView } from "../3_1_창업아이템_사업화_추진_전략/components/businessModelView";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";

const RnD_ID = "RnD_id";
const RnD_FREE_VERSION_ID = "RnD_FREE_VERSION_ID";

/**
 * @description 사업계획서 전체 페이지
 */
export const RnDPage = () => {
  const { Alert, openAlert } = useAlert();

  const { copyToClipboard, downloadPng } = useDownloadBusinessPlanImage({ openAlert });

  const { rndOutputId } = useParams();

  const {
    technologyDevelopmentGoalsState,
    researchDevelopmentMethodState,
    precedingRnDState,
    rnDPlanState,
    specificCommercializationGoalsState,
    marketEntryStrategyAndMarketingState,
    backgroundAndMotivationState,
    businessModelState,
  } = useGetRnDOutputData({
    rndOutputId: Number(rndOutputId),
  });

  const searchResultList = [
    ...(technologyDevelopmentGoalsState?.searchResult || []),
    ...(researchDevelopmentMethodState?.searchResult || []),
    ...(precedingRnDState?.searchResult || []),
    ...(rnDPlanState?.searchResult || []),
    ...(specificCommercializationGoalsState?.searchResult || []),
    ...(marketEntryStrategyAndMarketingState?.searchResult || []),
  ]
    .filter((searchResult) => searchResult.url)
    .filter((searchResult, index, self) => {
      return index === self.findIndex((t) => t.url === searchResult.url);
    });

  const [isFreeVersionBusinessPlan, setIsFreeVersionBusinessPlan] = React.useState(true);
  const onCheckSwitch = () => setIsFreeVersionBusinessPlan((prev) => !prev);

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Absolute>
        <Alert />
      </PageLayout.Absolute>
      <PageLayout.Main>
        <BusinessPlanHeader
          isFreeVersionBusinessPlan={isFreeVersionBusinessPlan}
          onCheckSwitch={onCheckSwitch}
          businessPlanId={RnD_ID}
        />

        <div id={RnD_ID} style={{ width: appSize.businessPlanWidth }}>
          <BusinessPlanCautionSection />

          <BusinessPlanBlurArea isBlur={isFreeVersionBusinessPlan} id={RnD_FREE_VERSION_ID}>
            <BusinessPlanSectionLayout.H1>1. 기술개발 목표</BusinessPlanSectionLayout.H1>
            <ErrorBoundary
              fallbackRender={() => <div>TechnologyDevelopmentGoalsView 에러 발생</div>}
            >
              {!isEmpty(technologyDevelopmentGoalsState) && (
                <TechnologyDevelopmentGoalsView
                  businessPlanSectionData={technologyDevelopmentGoalsState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>2. 연구개발 방법</BusinessPlanSectionLayout.H1>
            <ErrorBoundary
              fallbackRender={() => <div>ResearchDevelopmentMethodView 에러 발생</div>}
            >
              {!isEmpty(researchDevelopmentMethodState) && (
                <ResearchDevelopmentMethodView
                  businessPlanSectionData={researchDevelopmentMethodState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>3. 선행연구개발</BusinessPlanSectionLayout.H1>
            <ErrorBoundary fallbackRender={() => <div>PrecedingRnDView 에러 발생</div>}>
              {!isEmpty(precedingRnDState) && (
                <PrecedingRnDView businessPlanSectionData={precedingRnDState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>4. 연구개발역량</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.P>
              - 이 항목은 사업자의 연구개발 역량을 확인하는 항목으로 직접 작성하셔야 합니다.
            </BusinessPlanSectionLayout.P>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>5. 기술개발일정</BusinessPlanSectionLayout.H1>
            <ErrorBoundary fallbackRender={() => <div>RnDPlanView 에러 발생</div>}>
              {!isEmpty(rnDPlanState) && <RnDPlanView businessPlanSectionData={rnDPlanState} />}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>6. 연구비 사용계획</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.P>
              - 이 항목은 사업자의 연구비 사용계획을 확인하는 항목으로 직접 작성하셔야 합니다.
            </BusinessPlanSectionLayout.P>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>
              7. 연구개발성과의 활용방안 및 기대효과 (사업화 목표와 전략 등)
            </BusinessPlanSectionLayout.H1>
            <ErrorBoundary
              fallbackRender={() => <div>SpecificCommercializationGoalsView 에러 발생</div>}
            >
              {!isEmpty(specificCommercializationGoalsState) && (
                <SpecificCommercializationGoalsView
                  businessPlanSectionData={specificCommercializationGoalsState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>2. 사업화 전략</BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BackgroundAndMotivationView 에러 발생</div>}>
              {!isEmpty(backgroundAndMotivationState) && (
                <BackgroundAndMotivationView
                  businessPlanSectionData={backgroundAndMotivationState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>비즈니스 모델</BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessModelView 에러 발생</div>}>
              {!isEmpty(businessModelState) && (
                <BusinessModelView
                  businessPlanSectionData={businessModelState}
                  listOfCopyImage={[1, 2, 3, 4, 5].map(
                    (num) => () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                      })
                  )}
                  listOfDownloadImage={[1, 2, 3, 4, 5].map(
                    (num) => () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                        name: `${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}.png`,
                      })
                  )}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary
              fallbackRender={() => <div>MarketEntryStrategyAndMarketingView 에러 발생</div>}
            >
              {!isEmpty(marketEntryStrategyAndMarketingState) && (
                <MarketEntryStrategyAndMarketingView
                  businessPlanSectionData={marketEntryStrategyAndMarketingState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <ReferenceLinkSection searchResultList={searchResultList} />
          </BusinessPlanBlurArea>
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
};
