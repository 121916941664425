import { atom } from "jotai";
import { TechnologyDevelopmentGoals } from "../pages/RnD/components/TechnologyDevelopmentGoalsView";
import { ResearchDevelopmentMethod } from "../pages/RnD/components/ResearchDevelopmentMethodView";
import { PrecedingRnD } from "../pages/RnD/components/PrecedingRnDView";
import { RnDPlan } from "../pages/RnD/components/RnDPlanView";
import { SpecificCommercializationGoals } from "../pages/RnD/components/SpecificCommercializationGoalsView";
import { MarketEntryStrategyAndMarketing } from "../pages/RnD/components/MarketEntryStrategyAndMarketingView";
import { BackgroundAndMotivation } from "../pages/RnD/components/BackgroundAndMotivationView";

/**
 * @description technologyDevelopmentGoals State Atom
 * @state {TechnologyDevelopmentGoals}
 */
export const technologyDevelopmentGoalsStateAtom = atom<TechnologyDevelopmentGoals>(
  {} as TechnologyDevelopmentGoals
);

/**
 * @description ResearchDevelopmentMethod State Atom
 * @state {ResearchDevelopmentMethod}
 */
export const researchDevelopmentMethodStateAtom = atom<ResearchDevelopmentMethod>(
  {} as ResearchDevelopmentMethod
);

/**
 * @description PrecedingRnD State Atom
 * @state {PrecedingRnD}
 */
export const precedingRnDStateAtom = atom<PrecedingRnD>({} as PrecedingRnD);

/**
 * @description RnDPlan State Atom
 * @state {RnDPlan}
 */
export const rndPlanStateAtom = atom<RnDPlan>({} as RnDPlan);

/**
 * @description Specific Commercialization Goals State Atom
 * @state {SpecificCommercializationGoals}
 */
export const specificCommercializationGoalsStateAtom = atom<SpecificCommercializationGoals>(
  {} as SpecificCommercializationGoals
);

/**
 * @description BackgroundAndMotivation State Atom
 * @state {BackgroundAndMotivation}
 */
export const backgroundAndMotivationStateAtom = atom<BackgroundAndMotivation>(
  {} as BackgroundAndMotivation
);

/**
 * @description MarketEntryStrategyAndMarketing State Atom
 * @state {MarketEntryStrategyAndMarketing}
 */
export const marketEntryStrategyAndMarketingStateAtom = atom<MarketEntryStrategyAndMarketing>(
  {} as MarketEntryStrategyAndMarketing
);
