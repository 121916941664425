import React from "react";
import styled from "@emotion/styled";
import { getChildrenByName } from "../../lib/utils/getChildrenByName";
import { MainHeader } from "../organisms/MainHeader";
import { Empty } from "../atoms";
import { GuideSection } from "../molecules/GuideSection";
import { Box } from "@mui/material";
import { breakPoints } from "../../constants/breakPoints";

const BodyLayout = styled("div")({
  height: "100%",
  display: "flex",
});

const MainLayout = styled("main")({
  height: "100%",
  minWidth: breakPoints.md,
  width: "100%",
  padding: "2rem 5rem",
});

/**
 * @description Header, Main, Footer를 관리하는 Layout
 */
const Body = ({
  children,
  isMainFunctionPage = false,
  createDocs = () => "",
  noHeader = false,
}: PropsBody) => {
  const MainComponentList = getChildrenByName(children, "Main");
  const AbsoluteComponentList = getChildrenByName(children, "Absolute");

  return (
    <BodyLayout>
      {AbsoluteComponentList}
      <Box sx={{ width: { xs: "100%", md: "100%" }, height: "100%" }}>
        {!noHeader && (
          <MainHeader isMainFunctionPage={isMainFunctionPage} createDocs={createDocs} />
        )}
        {MainComponentList}
      </Box>
    </BodyLayout>
  );
};

/**
 * @description absolute component 관리
 */
const Absolute = ({ children }: { children: React.ReactNode }) => {
  return <div>{children}</div>;
};

const Main = ({ children, guideText, ...props }: MainProps) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.toString()]);
  return (
    <MainLayout {...props}>
      {!!guideText && (
        <>
          <GuideSection>{guideText}</GuideSection>
          <Empty height="2rem" />
        </>
      )}
      {children}
    </MainLayout>
  );
};

export const PageLayout = Object.assign(Body, { Absolute, Main });

type PropsBody = {
  children: React.ReactNode;
  isMainFunctionPage?: boolean;
  createDocs?: () => void;
  noHeader?: boolean;
};

interface MainProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  guideText?: React.ReactNode;
}
