import { Route, Routes as ReactRoutes, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { PATH } from "../constants/path";

// Pages
import { ErorrPage } from "./Error";
import { TargetMarketAnalysisPage } from "./1_2_창업아이템_목표_시장(고객)_현황_분석";
import { BusinessCommercializationStrategyPage } from "./3_1_창업아이템_사업화_추진_전략";
import { EffortsToImproveSurvivalRatesPage } from "./3_2_생존율_제고를_위한_노력";
import { ItemBackgroundAndNeedsPage } from "./1_1_창업아이템_배경_및_필요성";
import { BusinessStatePage } from "./2_1_창업_아이템_현황";
import { BusinessRealizationAndConcretizationPlanPage } from "./2_2_창업_아이템_실현_및_구체화_방안";
import { BusinessPromotionSchedulePage } from "./3_3_사업추진_일정";
import { 창중대_예비창업자Page } from "./사업계획서/창중대_예비창업자";
import { 생애_최초_패키지Page } from "./사업계획서/생애_최초_패키지";
import { 창중대_초기창업자Page } from "./사업계획서/창중대_초기창업자";
import { 청년_창업_사관학교Page } from "./사업계획서/청년_창업_사관학교";
import { 예비_창업_패키지Page } from "./사업계획서/예비_창업_패키지";
import { 초기_창업_패키지Page } from "./사업계획서/초기_창업_패키지";
import { 신사업_창업_사관학교Page } from "./사업계획서/신사업_창업_사관학교";
import { IrPage } from "./Ir";
import { RnDPage } from "./RnD";
import { P_S_S_TPage } from "./사업계획서/PSST";
import { FormListPage } from "./사업계획서/formList";

const NaviTo = ({ pathname }: { pathname: string }) => {
  const { promptOutputId } = useParams();
  return <Navigate to={`${pathname}/${promptOutputId}`} replace />;
};

export const AppRoutes = () => {
  return (
    <ReactRoutes>
      <Route path={PATH.TARGET_MARKET_ANALYSIS} element={<TargetMarketAnalysisPage />} />
      <Route
        path={PATH.BUSINESS_COMMERCIALIZATION_STRATEGY}
        element={<BusinessCommercializationStrategyPage />}
      />
      <Route path={PATH.ITEM_BACKGROUND_AND_NEEDS} element={<ItemBackgroundAndNeedsPage />} />
      <Route
        path={PATH.EFFORTS_TO_IMPROVE_SURVIVAL_RATES}
        element={<EffortsToImproveSurvivalRatesPage />}
      />
      <Route path={PATH.BUSINESS_STATE} element={<BusinessStatePage />} />
      <Route
        path={PATH.BUSINESS_REALIZATION_AND_CONCRETIZATION_PLAN}
        element={<BusinessRealizationAndConcretizationPlanPage />}
      />
      <Route path={PATH.BUSINESS_PROMOTION_SCHEDULE} element={<BusinessPromotionSchedulePage />} />

      {/* [Temp] 회원가입을 막기 위해 임시 처리 */}
      {/* <Route path={PATH.SIGN_UP} element={<SignUpPage />} /> */}
      <Route path={PATH.ERROR} element={<ErorrPage />} />
      {/* 기존 combined pathname 리다이렉트 */}
      <Route
        path={PATH.COMBINED + "/:promptOutputId"}
        element={<NaviTo pathname={PATH.P_S_S_T} />}
      />
      <Route
        path={PATH.창중대_예비창업자 + "/:promptOutputId"}
        element={<창중대_예비창업자Page />}
      />
      <Route
        path={PATH.창중대_초기창업자 + "/:promptOutputId"}
        element={<창중대_초기창업자Page />}
      />
      <Route path={PATH.생애최초패키지 + "/:promptOutputId"} element={<생애_최초_패키지Page />} />
      <Route
        path={PATH.청년창업사관학교 + "/:promptOutputId"}
        element={<청년_창업_사관학교Page />}
      />
      <Route path={PATH.예비창업패키지 + "/:promptOutputId"} element={<예비_창업_패키지Page />} />
      <Route path={PATH.초기창업패키지 + "/:promptOutputId"} element={<초기_창업_패키지Page />} />
      <Route
        path={PATH.신사업창업사관학교 + "/:promptOutputId"}
        element={<신사업_창업_사관학교Page />}
      />
      <Route path={PATH.P_S_S_T} element={<FormListPage />} />
      <Route path={PATH.P_S_S_T + "/:promptOutputId"} element={<P_S_S_TPage />} />
      <Route path={PATH.BUSINESS_PROMOTION_SCHEDULE} element={<BusinessPromotionSchedulePage />} />
      <Route path={PATH.IR + "/:irOutputId"} element={<IrPage />} />
      <Route path={PATH.R_N_D + "/:rndOutputId"} element={<RnDPage />} />
      <Route path="*" element={<ErorrPage />} />
    </ReactRoutes>
  );
};
