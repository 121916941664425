import React from "react";
import { useExportImageFromHtml } from "../../../hooks/infra/useExportImageFromHtml";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { useAlert } from "../../../hooks/infra/useAlert";

export const useDownloadBusinessPlanImage = ({ openAlert }: Props) => {
  const { downloadPng, copyToClipboard } = useExportImageFromHtml();

  /**
   * @deprecated
   */
  const downloadAllImage = async () => {
    openAlert({
      severity: "info",
      contents: "이미지 다운로드가 시작되었습니다. 1분 정도 더 걸릴 수 있습니다.",
    });
    [1, 2, 3, 4, 5].forEach(async (num) => {
      await downloadPng({
        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
        name: `${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}.png`,
      });
    });
    [1, 2, 3, 4].forEach(async (num) => {
      await downloadPng({
        selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
        name: `${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}.png`,
      });
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
      name: `${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}.png`,
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
      name: `${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}.png`,
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
      name: `${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}.png`,
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
      name: `${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}.png`,
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
      name: `${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}.png`,
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
      name: `${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}.png`,
    });
    await downloadPng({
      selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
      name: `${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}.png`,
    });
    openAlert({ severity: "success", contents: "이미지 다운로드가 완료되었습니다." });
  };

  /**
   * @deprecated
   */
  const downloadAllImageWhenFreeVersion = async (businessPlanId: string) => {
    openAlert({
      severity: "info",
      contents: "이미지 다운로드가 시작되었습니다. 1분 정도 더 걸릴 수 있습니다.",
    });
    await downloadPng({
      selector: `#${businessPlanId}`,
      name: `사업계획서(Free).png`,
    });
    openAlert({ severity: "success", contents: "이미지 다운로드가 완료되었습니다." });
  };

  return {
    downloadAllImage,
    downloadAllImageWhenFreeVersion,
    copyToClipboard: async ({ selector }: { selector: string }) => {
      await copyToClipboard({ selector });
      openAlert({ severity: "success", contents: "클립보드에 복사되었습니다." });
    },
    downloadPng,
  };
};

type Props = {
  openAlert: ReturnType<typeof useAlert>["openAlert"];
};
