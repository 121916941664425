import React from "react";
import { BusinessRoadmapData } from "..";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useAtomValue, useSetAtom } from "jotai";
import {
  businessPlanPaletteAtom,
  isEnglishDocumentAtom,
} from "../../../store/businessPlanUiState.atom";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { businessRoadmapStateAtom } from "../../../store/businessPlanDataState.atom";
import { appSize } from "../../../constants/appSize";

export const BusinessRoadmapView = ({
  businessPlanSectionData,
  isFreeVersion = false,
  사업전체일정Title = "",
  협약기간사업일정Title = "",
}: BusinessPlanSectionProps<BusinessRoadmapData> & {
  사업전체일정Title?: string;
  협약기간사업일정Title?: string;
}) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);
  const setBusinessPlanSectionData = useSetAtom(businessRoadmapStateAtom);
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  // const initTheme = `
  // %%{
  //   init: {
  //     'theme': 'neutral',
  //     'themeVariables': {
  //       'noteBkgColor': '${businessPlanPalette.primaryLightest}',
  //       'primaryTextColor': '${appColor.black}',
  //       'primaryBorderColor': '${appColor.white}'
  //     }
  //   }
  // }%%
  // `;

  // const ganttStyle = ``;

  return (
    <BusinessPlanSectionLayout
      title="사업 추진 일정"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_ROADMAP}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.P>
        KPI : {businessPlanSectionData.kpi.join(", ")}
      </BusinessPlanSectionLayout.P>
      <BusinessPlanSectionLayout.P>
        {isEnglish ? "Goal" : "목표"} : {businessPlanSectionData.goal.join(", ")}
      </BusinessPlanSectionLayout.P>
      {사업전체일정Title && (
        <BusinessPlanSectionLayout.H2>{사업전체일정Title}</BusinessPlanSectionLayout.H2>
      )}
      <BusinessPlanSectionLayout.Blockquote>
        TIP : 현실적으로 달성 가능한 일정으로 설정하시는 것을 추천드립니다. 지원사업 성공 평가 시,
        제출했던 사업계획서 내용과 발표를 바탕으로 성공 여부를 판단할 수 있습니다. 추진 내용에
        과도한 목표들을 설정한 후, 달성하지 못하실 경우 최종 발표에서 불이익을 받을 수 있습니다.
      </BusinessPlanSectionLayout.Blockquote>
      <BusinessPlanSectionLayout.H3>{"사업 추진 일정(전체 사업단계)"}</BusinessPlanSectionLayout.H3>
      {/* <Tooltip
        title={<MainCtaBtn onClick={listOfDownloadImage[1]}>이미지 다운로드</MainCtaBtn>}
        arrow
        placement="top-end"
      >
        <div
          id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_ROADMAP}`}
          style={{ width: "1440px", display: "flex", justifyContent: "center" }}
        >
          <MermaidComponent
            mermaidKey={`${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}_mermaid`}
            code={businessPlanSectionData.businessRoadMapMermaid}
            initTheme={initTheme}
            style={ganttStyle}
          />
        </div>
      </Tooltip> */}
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>{isEnglish ? "No." : "순번"}</TableLayout.Th>
            <TableLayout.Th textCenter>
              {isEnglish ? "Promotion Contents" : "추진 내용"}
            </TableLayout.Th>
            <TableLayout.Th textCenter>
              {isEnglish ? "Promotion Period" : "추진 기간"}
            </TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Details" : "세부 내용"}</TableLayout.Th>
          </TableLayout.Tr>
          {businessPlanSectionData.businessRoadMap.map((plan, index) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionTitle}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionPeriod}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionDetail}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
      <BusinessPlanSectionLayout.Span />
      {협약기간사업일정Title && (
        <BusinessPlanSectionLayout.H2>{협약기간사업일정Title}</BusinessPlanSectionLayout.H2>
      )}
      <BusinessPlanSectionLayout.H3>{"사업 추진 일정(협약기간 내)"}</BusinessPlanSectionLayout.H3>
      {/* <Tooltip
        title={<MainCtaBtn onClick={listOfDownloadImage[0]}>이미지 다운로드</MainCtaBtn>}
        arrow
        placement="top-end"
      >
        <div
          id={`${BUSINESS_PLAN_SVG_ID.ACTION_PLAN}`}
          style={{ width: "1440px", display: "flex", justifyContent: "center" }}
        >
          <MermaidComponent
            mermaidKey={`${BUSINESS_PLAN_SVG_ID.ACTION_PLAN}_mermaid`}
            code={businessPlanSectionData.actionPlanMermaid}
            initTheme={initTheme}
            style={ganttStyle}
          />
        </div>
      </Tooltip> */}
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>{isEnglish ? "No." : "순번"}</TableLayout.Th>
            <TableLayout.Th textCenter>
              {isEnglish ? "Promotion Contents" : "추진 내용"}
            </TableLayout.Th>
            <TableLayout.Th textCenter>
              {isEnglish ? "Promotion Period" : "추진 기간"}
            </TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Details" : "세부 내용"}</TableLayout.Th>
          </TableLayout.Tr>
          {businessPlanSectionData.actionPlan.map((plan, index) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionTitle}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionPeriod}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionDetail}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
    </BusinessPlanSectionLayout>
  );
};
