import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/**
 * @caution 옛날 브라우저에는 호환이 안될 수 있어 polyfill을 설치해야 함
 */
export const TanstackReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = new QueryClient({});
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
