import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { BusinessRoadmapView } from "./components/BusinessRoadmapView";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { Empty } from "../../components/atoms";
import { Stack } from "@mui/material";
import { isEmpty } from "../../lib/utils/isEmpty";
import { useSign } from "../../hooks/infra/useSign";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 3-3. 사업추진 일정
 */
export const BusinessPromotionSchedulePage = () => {
  const businessPlanTitleList = ["사업 로드맵"];
  const [businessRoadmapState, setBusinessRoadmapState] = React.useState<BusinessRoadmapData>(
    {} as BusinessRoadmapData
  );

  const setterList = [setBusinessRoadmapState];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() => {
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])));
                }}
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(businessRoadmapState) && (
              <BusinessRoadmapView businessPlanSectionData={businessRoadmapState} />
            )}
          </Stack>
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

export type BusinessRoadmapData = {
  kpi: string[];
  goal: string[];
  businessRoadMap: {
    promotionTitle: string;
    promotionPeriod: string;
    promotionDetail: string;
  }[];
  businessRoadMapMermaid: string;
  actionPlan: {
    promotionTitle: string;
    promotionPeriod: string;
    promotionDetail: string;
  }[];
  actionPlanMermaid: string;
} & { searchResult?: SearchResult[] };
