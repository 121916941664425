import { atom } from "jotai";
import {
  ItemBackgroundAndNeedsData,
  MarketGrowthGraphWithTamSamSom,
} from "../pages/1_1_창업아이템_배경_및_필요성";
import {
  BusinessConcretizationData,
  CompetitorAnalysisAndDifferentiationPlanData,
  PositioningMapData,
} from "../pages/2_2_창업_아이템_실현_및_구체화_방안";
import { BusinessOverviewData, LeanCanvasData } from "../pages/2_1_창업_아이템_현황";
import { ClientPersonaData } from "../pages/1_2_창업아이템_목표_시장(고객)_현황_분석";
import {
  BusinessEntryStrategyData,
  BusinessModelData,
} from "../pages/3_1_창업아이템_사업화_추진_전략";
import {
  GlobalPromotionStrategyAndBusinessEntryStrategyData,
  GlobalPromotionStrategyData,
  TargetSalesGraphData,
} from "../pages/3_2_생존율_제고를_위한_노력";
import { BusinessRoadmapData } from "../pages/3_3_사업추진_일정";
import { UserInput } from "../pages/Input/types";

/**
 * @description marketGrowthGraphWithTamSamSomState atom
 * @state {MarketGrowthGraphWithTamSamSom}
 */
export const marketGrowthGraphWithTamSamSomStateAtom = atom<MarketGrowthGraphWithTamSamSom>(
  {} as MarketGrowthGraphWithTamSamSom
);

/**
 * @description itemBackgroundAndNeedsState atom
 * @state {ItemBackgroundAndNeedsData}
 */
export const itemBackgroundAndNeedsStateAtom = atom<ItemBackgroundAndNeedsData>(
  {} as ItemBackgroundAndNeedsData
);

/**
 * @description positioningMapState atom
 * @state {PositioningMapData}
 */
export const positioningMapStateAtom = atom<PositioningMapData>({} as PositioningMapData);

/**
 * @description businessConcretizationState atom
 * @state {BusinessConcretizationData}
 */
export const businessConcretizationStateAtom = atom<BusinessConcretizationData>(
  {} as BusinessConcretizationData
);

/**
 * @description competitorAnalysisAndDifferentiationPlanState atom
 * @state {stCompetitorAnalysisAndDifferentiationPlanDataring}
 */
export const competitorAnalysisAndDifferentiationPlanStateAtom =
  atom<CompetitorAnalysisAndDifferentiationPlanData>(
    {} as CompetitorAnalysisAndDifferentiationPlanData
  );

/**
 * @description leanCanvasState atom
 * @state {LeanCanvasData}
 */
export const leanCanvasStateAtom = atom<LeanCanvasData>({} as LeanCanvasData);

/**
 * @description businessOverviewState atom
 * @state {BusinessOverviewData}
 */
export const businessOverviewStateAtom = atom<BusinessOverviewData>({} as BusinessOverviewData);

/**
 * @description clientPersonaState atom
 * @state {ClientPersonaData}
 */
export const clientPersonaStateAtom = atom<ClientPersonaData>({} as ClientPersonaData);

/**
 * @description businessModelState atom
 * @state {BusinessModelData}
 */
export const businessModelStateAtom = atom<BusinessModelData>({} as BusinessModelData);

/**
 * @description businessEntryStrategyState atom
 * @state {BusinessEntryStrategyData}
 */
export const businessEntryStrategyStateAtom = atom<BusinessEntryStrategyData>(
  {} as BusinessEntryStrategyData
);

/**
 * @description globalPromotionStrategyState atom
 * @state {GlobalPromotionStrategyData}
 */
export const globalPromotionStrategyStateAtom = atom<GlobalPromotionStrategyData>(
  {} as GlobalPromotionStrategyData
);

/**
 * @description globalPromotionStrategyAndBusinessEntryStrategyState atom
 * @state {GlobalPromotionStrategyAndBusinessEntryStrategyData}
 */
export const globalPromotionStrategyAndBusinessEntryStrategyStateAtom =
  atom<GlobalPromotionStrategyAndBusinessEntryStrategyData>(
    {} as GlobalPromotionStrategyAndBusinessEntryStrategyData
  );

/**
 * @description targetSalesGraphState atom
 * @state {TargetSalesGraphData}
 */
export const targetSalesGraphStateAtom = atom<TargetSalesGraphData>({} as TargetSalesGraphData);

/**
 * @description businessRoadmapState atom
 * @state {BusinessRoadmapData}
 */
export const businessRoadmapStateAtom = atom<BusinessRoadmapData>({} as BusinessRoadmapData);
