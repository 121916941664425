import React from "react";
import { BusinessConcretizationData } from "..";
import { Empty } from "../../../components/atoms";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { businessConcretizationStateAtom } from "../../../store/businessPlanDataState.atom";
import { useSetAtom } from "jotai";

export const BusinessConcretizationView = ({
  businessPlanSectionData,
  isFreeVersion = false,
}: BusinessPlanSectionProps<BusinessConcretizationData>) => {
  const setBusinessPlanSectionData = useSetAtom(businessConcretizationStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="사업 구체화 방안"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_CONCRETIZATION}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      {businessPlanSectionData.businessConcretization.map((businessConcretization) => (
        <>
          <BusinessPlanSectionLayout.H3>
            {businessConcretization.title}
          </BusinessPlanSectionLayout.H3>
          {businessConcretization.contents.map((content) => (
            <BusinessPlanSectionLayout.P>- {content}</BusinessPlanSectionLayout.P>
          ))}
          <Empty height="1rem" />
        </>
      ))}
    </BusinessPlanSectionLayout>
  );
};
