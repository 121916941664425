import React from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constants/path";
import { PROMPT_CATEGORY } from "../../constants/domain/promptCategory";

export const useNavigateByPromptCategory = () => {
  const navigate = useNavigate();

  const navigateByPromptCategory = ({ promptCategory, id }: Props) => {
    switch (promptCategory) {
      case PROMPT_CATEGORY.PPT:
        navigate(`${PATH.IR}/${id}`);
        break;
      case PROMPT_CATEGORY.R_N_D:
        navigate(`${PATH.R_N_D}/${id}`);
        break;
      case PROMPT_CATEGORY.예창:
      case PROMPT_CATEGORY.청창:
      case PROMPT_CATEGORY.예창_영어버전:
      default:
        navigate(`${PATH.P_S_S_T}/${id}`);
        break;
    }
  };

  return { navigateByPromptCategory };
};

type Props = {
  promptCategory: string;
  id: number;
};
