import React from "react";
import mermaid from "mermaid";

/**
 * @description Mermaid 라이브러리를 이용하여 Mermaid Flow Chart를 렌더링하는 컴포넌트
 * @param mermaidKey Mermaid Flow Chart를 구분하기 위한 key
 * @param code Mermaid Flow Chart를 구성하는 코드
 * @param initTheme Mermaid Flow Chart의 테마
 * @param style Mermaid Flow Chart의 스타일
 */
export const MermaidComponent = ({
  mermaidKey,
  code,
  initTheme = "",
  style = "",
}: MermaidComponentProps) => {
  const ref = React.useRef<HTMLPreElement>(null);

  // [Todo] 테마 등 스타일 관리가 여기서 이루어짐 -> 컴포넌트 설계를 어떻게 해야할까
  React.useEffect(() => {
    mermaid.initialize({ startOnLoad: false });
    mermaid.render(mermaidKey, initTheme + "\n" + code + "\n" + style).then(
      ({ svg }) => {
        if (ref.current) ref.current.innerHTML = svg;
      },
      (error) => console.error(error)
    );
  }, [code, initTheme, style]);

  return (
    <pre
      key={mermaidKey}
      ref={ref}
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    />
  );
};

export type MermaidComponentProps = {
  mermaidKey: string;
  code: string;
  width?: string;
  initTheme?: string;
  style?: string;
};
