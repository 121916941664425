import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useSetAtom } from "jotai";
import { backgroundAndMotivationStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";

export const BackgroundAndMotivationView = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<BackgroundAndMotivation>) => {
  const setBusinessPlanSectionData = useSetAtom(backgroundAndMotivationStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="배경 및 동기"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData.backgrounds.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.backgrounds.contents.map((content) => (
        <BusinessPlanSectionLayout.P>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData.problems.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.problems.contents.map((content) => (
        <BusinessPlanSectionLayout.P>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData.solutions.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.solutions.contents.map((content) => (
        <BusinessPlanSectionLayout.P>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData.motivations.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.motivations.contents.map((content) => (
        <BusinessPlanSectionLayout.P>- {content}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};

export type BackgroundAndMotivation = {
  backgrounds: { title: string; contents: string[] };
  problems: { title: string; contents: string[] };
  solutions: { title: string; contents: string[] };
  motivations: { title: string; contents: string[] };
} & { searchResult?: SearchResult[] };
