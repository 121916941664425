import React from "react";
import { BusinessPlanSectionLayout } from "../../layouts/BusinessPlanSection.layout";

export const BusinessPlanCautionSection = () => {
  return (
    <>
      {new Array(5).fill(0).map((x) => (
          <BusinessPlanSectionLayout.Span />
      ))}
      {`⚠ 안내사항 :
- 독스헌트 AI는 고객님의 아이디어를 바탕으로 사업계획서의 초안 가이드를 생성해드리는 서비스입니다. 사업계획서를 완성하기 위해서 고객님의 심도있는 고민을 통한 수정과 보완이 필요합니다.
- 독스헌트 AI를 이용한 결과물의 권리와 책임은 모두 고객님에게 있습니다.
- 독스헌트 AI의 결과물 속 각종 수치 데이터는 신뢰도를 보장할 수 없습니다. 근거 제공 PRO버전의 경우 검색 증강 생성(RAG) 기술을 통한 데이터 보충을 제공하고 있습니다. 독스헌트는 보다 신뢰할 수 있는 데이터 제공을 위한 기술 개발에 힘쓰고 있습니다.
- 오류 발생으로 인해 일부 항목이 나타나지 않을 수도 있습니다. 이 경우 [다시 만들기]를 통해 재생성을 부탁드립니다.`
        .split("\n")
          .map((text) => {
            if (text.includes("[다시 만들기]")) {
              const parts = text.split("[다시 만들기]");
              return (
                  <>
                    <BusinessPlanSectionLayout.P style={{color: "gray"}}>
                      {parts[0]}<a href="https://forms.docshunt.co.kr/free" style={{color: "black"}}>다시 만들기</a>{parts[1]}
                    </BusinessPlanSectionLayout.P>
                  </>
              );
            } else {
              return <BusinessPlanSectionLayout.P style={{ color: "gray" }}>{text}</BusinessPlanSectionLayout.P>;
            }
          })}
      {new Array(5).fill(0).map((x) => (
        <BusinessPlanSectionLayout.Span />
      ))}
    </>
  );
};
