import React from "react";
import { PositioningMapData } from "..";
import { Button } from "@mui/material";
import { useSetAtom } from "jotai";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { positioningMapStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { Empty } from "../../../components/atoms";
import { PositioningMapUi } from "../../../components/molecules/businessPlan/PositioningMapUi";

export const PositioningMapView = ({
  businessPlanSectionData,
  copyImage,
  downloadImage,
  isFreeVersion = false,
}: BusinessPlanSectionProps<PositioningMapData, BusinessPlanSectionImageProps>) => {
  const setBusinessPlanSectionData = useSetAtom(positioningMapStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="포지셔닝 맵"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H3>
        {`${businessPlanSectionData.metaData.title}`}
      </BusinessPlanSectionLayout.H3>
      <ImageTooltip
        title={
          <div style={{ display: "flex" }}>
            <Button onClick={copyImage}>Copy</Button>
            <Empty width="10px" />
            <Button onClick={downloadImage}>Download</Button>
          </div>
        }
      >
        <PositioningMapUi positioningMapData={businessPlanSectionData} />
      </ImageTooltip>
    </BusinessPlanSectionLayout>
  );
};
