import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useSetAtom } from "jotai";
import { technologyDevelopmentGoalsStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";

export const TechnologyDevelopmentGoalsView = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<TechnologyDevelopmentGoals>) => {
  const setBusinessPlanSectionData = useSetAtom(technologyDevelopmentGoalsStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="기술 개발 목표"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H2>최종 개발 목표</BusinessPlanSectionLayout.H2>
      {businessPlanSectionData.output.finalGoals.map((goal, index) => (
        <BusinessPlanSectionLayout.P key={index}>{goal}</BusinessPlanSectionLayout.P>
      ))}
      {businessPlanSectionData.output.midTermGoals.map((goal, index) => (
        <div key={index}>
          <BusinessPlanSectionLayout.H3>
            {index + 1}. {goal.subject}
          </BusinessPlanSectionLayout.H3>
          {goal.contents.map((content, index) => (
            <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
          ))}
        </div>
      ))}
    </BusinessPlanSectionLayout>
  );
};

export type TechnologyDevelopmentGoals = {
  output: {
    finalGoals: string[];
    midTermGoals: {
      subject: string;
      contents: string[];
    }[];
  };
} & { searchResult?: SearchResult[] };
