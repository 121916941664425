import { BreakpointsOptions, Components, PaletteOptions } from "@mui/material";
import { breakPoints } from "../constants/breakPoints";
import { appColor } from "../constants/appColor";
import {
  FontStyleOptions,
  TypographyStyleOptions,
  Variant,
} from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  //  "bodyBold" | "BodySmall" | "BodyExtraSmall"
  interface TypographyVariants {
    bodyBold: React.CSSProperties;
    BodySmall: React.CSSProperties;
    BodyExtraSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyBold?: React.CSSProperties;
    BodySmall?: React.CSSProperties;
    BodyExtraSmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    BodySmall: true;
    BodyExtraSmall: true;
  }
}

export const configMuiBreakpoints: BreakpointsOptions = {
  values: { ...breakPoints },
};

export const configMuiComponents: Components = {
  // default css setting
  MuiCssBaseline: {
    styleOverrides: {
      // html, body default style
      "html, body": { height: "100%", width: "100%", backgroundColor: appColor.backGround },
      // ul, li default style
      "ul, li": { listStyle: "none", paddingLeft: 0, margin: 0 },
    },
  },
};

export const configMuiPalette: PaletteOptions = {
  primary: { main: appColor.primary },
  text: { primary: appColor.text },
};

export const configMuiTypography: TypographyOptions = {
  fontFamily: ["Pretendard", "sans-serif"].join(","),
  h1: { fontSize: 43, fontWeight: "bold", letterSpacing: "1px" },
  h2: { fontSize: 34, fontWeight: "bold", letterSpacing: "1px" },
  h3: { fontSize: 22, fontWeight: "bold", letterSpacing: "1px" },
  h4: { fontSize: 20, fontWeight: "bold", letterSpacing: "1px" },
  h5: { fontSize: 15, fontWeight: "bold", letterSpacing: "1px" },
  /**
   * Body Default
   */
  body1: { fontSize: 14 },
  bodyBold: { fontSize: 14, fontWeight: "bold" },
  BodySmall: { fontSize: 12, fontWeight: "lighter" },
  BodyExtraSmall: { fontSize: 10 },
};

type AppVariant = Variant | "bodyBold" | "BodySmall" | "BodyExtraSmall";

interface TypographyOptions
  extends Partial<Record<AppVariant, TypographyStyleOptions> & FontStyleOptions> {}
