import React, { HtmlHTMLAttributes } from "react";
import { Button, Typography } from "@mui/material";
import { hoverTransitionStyle } from "../../styles/infra.style";
import { useModal } from "../../hooks/infra/useModal";
import { BusinessPlanUiEditorModal } from "../organisms/BusinessPlanUiEditorModal";

/**
 * @description 사업계획서 내부 섹션 레이아웃
 */
const Main = ({
  children,
  title,
  businessPlanSectionDataState,
  setBusinessPlanSectionDataState,
  isEditMode = true,
  ...props
}: MainProps) => {
  const { style, ...rest } = props;
  const [hoverStyle, setHoverStyle] = React.useState<React.CSSProperties>({});
  const [isHover, setIsHover] = React.useState<boolean>(false);

  const { Modal, handleOpen, handleClose } = useModal();

  if (!isEditMode) return <>{children}</>;
  return (
    <>
      <BusinessPlanUiEditorModal
        Modal={Modal}
        title={title}
        businessPlanSectionDataState={businessPlanSectionDataState}
        setBusinessPlanSectionDataState={setBusinessPlanSectionDataState}
        handleClose={handleClose}
      />
      <div
        // position: relative를 사용하면, 자식 요소에 position: absolute를 사용하여 부모 요소를 기준으로 위치를 잡을 수 있음
        style={{ position: "relative", ...style, ...hoverTransitionStyle, ...hoverStyle }}
        onMouseEnter={() => {
          setHoverStyle({ backgroundColor: "#f5f5f5", borderRadius: "0.5rem" });
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setHoverStyle({});
          setIsHover(false);
        }}
        {...rest}
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{
            position: "absolute",
            right: "-10rem",
            display: isHover ? "block" : "none",
            width: "10rem",
            height: "100%",
          }}
          onClick={handleOpen}
        >
          UI 수정
        </Button>
        {children}
      </div>
    </>
  );
};

// [Error] ref 속성 처리 필요 -> 타입 문제
const H1 = ({ children, ...props }: TypographyProps) => {
  const { sx, ref, ...rest } = props;
  return (
    <Typography
      component="h1"
      sx={{ fontWeight: 600, fontSize: "2rem", lineHeight: "5rem", ...props.sx }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const H2 = ({ children, ...props }: TypographyProps) => {
  const { sx, ref, ...rest } = props;
  return (
    <Typography
      component="h2"
      sx={{ fontWeight: 600, fontSize: "1.6rem", lineHeight: "4rem", ...props.sx }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const H3 = ({ children, ...props }: TypographyProps) => {
  const { sx, ref, ...rest } = props;
  return (
    <Typography
      component="h3"
      sx={{ fontWeight: 600, fontSize: "1.2rem", lineHeight: "3rem", ...props.sx }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const P = ({ children, ...props }: TypographyProps) => {
  const { sx, ref, ...rest } = props;
  return (
    <Typography component="p" sx={{ fontSize: "1rem", lineHeight: "2rem", ...props.sx }} {...rest}>
      {children}
    </Typography>
  );
};

const Span = () => {
  return <p>&nbsp;</p>;
};

const Line = () => {
  return <hr />;
};

const Code = ({ children, ...props }: HtmlHTMLAttributes<HTMLPreElement>) => {
  return (
    <pre
      style={{
        backgroundColor: "#f5f5f5",
        padding: "1rem",
        borderRadius: "0.5rem",
        fontSize: "1rem",
        lineHeight: "2rem",
        ...props.style,
      }}
    >
      {children}
    </pre>
  );
};

const Blockquote = ({ children, ...props }: TypographyProps) => {
  const { sx, ref, ...rest } = props;
  return (
    <P style={{ color: "green" }} {...rest}>
      {children}
    </P>
  );
};

const Toggle = ({
  summary,
  children,
  ...props
}: HtmlHTMLAttributes<HTMLDetailsElement> & { summary: React.ReactNode }) => {
  return (
    <details
      style={{
        paddingLeft: "1rem",
        ...props.style,
      }}
    >
      <summary style={{ color: "green", fontSize: "1rem", lineHeight: "2rem" }}>{summary}</summary>
      {children}
    </details>
  );
};

export const BusinessPlanSectionLayout = Object.assign(Main, {
  H1,
  H2,
  H3,
  P,
  Span,
  Line,
  Code,
  Blockquote,
  Toggle,
});

type MainProps = {
  children: React.ReactNode;
  title: string;
  businessPlanSectionDataState: string;
  setBusinessPlanSectionDataState: any;
  isEditMode?: boolean;
} & HtmlHTMLAttributes<HTMLDivElement>;

type TypographyProps = {
  children: React.ReactNode;
} & React.ComponentProps<typeof Typography>;
