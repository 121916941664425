import React from "react";
import { BusinessModelData } from "..";
import { Empty } from "../../../components/atoms";
import { useSetAtom } from "jotai";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageListProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { Button } from "@mui/material";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { businessModelStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { BusinessModelUi } from "../../../components/molecules/businessPlan/BusinessModelUi";

export const BusinessModelView = ({
  businessPlanSectionData,
  listOfCopyImage = [],
  listOfDownloadImage = [],
  isFreeVersion = false,
}: BusinessPlanSectionProps<BusinessModelData, BusinessPlanSectionImageListProps>) => {
  const setBusinessPlanSectionData = useSetAtom(businessModelStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="비즈니스 모델"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}_BLURRED`}
    >
      {businessPlanSectionData.businessModel.map((content, index) => (
        <div style={index !== 0 && isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}>
          <BusinessPlanSectionLayout.H3>
            {`${index + 1}. ${content.title} (${content.format})`}
          </BusinessPlanSectionLayout.H3>
          <Empty height="1rem" />
          <ImageTooltip
            title={
              <div style={{ display: "flex" }}>
                <Button onClick={listOfCopyImage[index]}>Copy</Button>
                <Empty width="10px" />
                <Button onClick={listOfDownloadImage[index]}>Download</Button>
              </div>
            }
          >
            <BusinessModelUi
              businessModelData={content}
              svgId={`${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${index + 1}`}
            />
          </ImageTooltip>
          <Empty height="1rem" />
          {content.contents.map((string) => (
            <BusinessPlanSectionLayout.P>- {string}</BusinessPlanSectionLayout.P>
          ))}
        </div>
      ))}
    </BusinessPlanSectionLayout>
  );
};
