/**
 * @description LeanCanvas server data -> LeanCanvas UI
 */
export class LeanCanvasUiDto {
  public readonly id: string;
  public readonly title: string;
  public readonly createdAt: string | undefined;
  public readonly contents: LeanCanvasUiDtoContent[] = [];

  constructor(leanCanvas: LeanCanvasDetailsDto) {
    this.id = leanCanvas.id;
    this.title = leanCanvas.title;
    this.createdAt = leanCanvas.createdAt;

    this.contents.push({
      no: 1,
      serverId: "problem",
      title: "문제",
      engTitle: "Problem",
      description: "고객이 직면한 문제를 기술합니다.",
      content: leanCanvas.problem,
    });
    this.contents.push({
      no: 2,
      serverId: "customerSegment",
      title: "고객 세그먼트",
      engTitle: "Customer Segment",
      description: "고객 세그먼트를 기술합니다.",
      content: leanCanvas.customerSegment,
    });
    this.contents.push({
      no: 3,
      serverId: "uniqueValueProposition",
      title: "고유 가치 제안",
      engTitle: "Unique Value Proposition",
      description: "고유 가치 제안을 기술합니다.",
      content: leanCanvas.uniqueValueProposition,
    });
    this.contents.push({
      no: 4,
      serverId: "solution",
      title: "솔루션",
      engTitle: "Solution",
      description: "고객의 문제를 해결하기 위한 해결책을 기술합니다.",
      content: leanCanvas.solution,
    });
    this.contents.push({
      no: 5,
      serverId: "channel",
      title: "채널",
      engTitle: "Channel",
      description: "고객에게 제품을 전달하는 채널을 기술합니다.",
      content: leanCanvas.channel,
    });
    this.contents.push({
      no: 6,
      serverId: "revenueStream",
      title: "수익원",
      engTitle: "Revenue Stream",
      description: "수익원을 기술합니다.",
      content: leanCanvas.revenueStream,
    });
    this.contents.push({
      no: 7,
      serverId: "costStructure",
      title: "비용 구조",
      engTitle: "Cost Structure",
      description: "비용 구조를 기술합니다.",
      content: leanCanvas.costStructure,
    });
    this.contents.push({
      no: 8,
      serverId: "keyMetrics",
      title: "핵심 지표",
      engTitle: "Key Metrics",
      description: "핵심 지표를 기술합니다.",
      content: leanCanvas.keyMetrics,
    });
    this.contents.push({
      no: 9,
      serverId: "unfairAdvantage",
      title: "경쟁 우위",
      engTitle: "Unfair Advantage",
      description: "경쟁 우위를 기술합니다.",
      content: leanCanvas.unfairAdvantage,
    });
  }
}

export interface LeanCanvasUiDtoContent {
  no: number;
  serverId: string;
  title: string;
  engTitle: string;
  description: string;
  content: string;
}

export interface LeanCanvasBaseDto {
  id: string;
  title: string;
  /**
   * @format date-time
   */
  createdAt?: string;
}
export interface LeanCanvasDetailsDto extends LeanCanvasBaseDto {
  problem: string;
  customerSegment: string;
  uniqueValueProposition: string;
  solution: string;
  channel: string;
  revenueStream: string;
  costStructure: string;
  keyMetrics: string;
  unfairAdvantage: string;
}
