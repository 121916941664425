/**
 * @description Blur effect
 */
export const blurStyle: React.CSSProperties = {
  filter: "blur(5px)", // 다른 브라우저 호환
  WebkitFilter: "blur(5px)", // 사파리 브라우저 호환
};

/**
 * @description Prevents the user from selecting text
 */
export const noSelectabilityStyle: React.CSSProperties = {
  WebkitTouchCallout: "none" /* iOS Safari */,
  WebkitUserSelect: "none" /* Safari */,
  KhtmlUserSelect: "none" /* Konqueror HTML */,
  MozUserSelect: "none" /* Firefox */,
  userSelect: "none" /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */,
};

/**
 * @description Transitions for the hover effect
 */
export const hoverTransitionStyle: React.CSSProperties = {
  transition: "all 0.4s ease", // 대부분 브라우저 호환
  WebkitTransition: "all 0.4s ease", // 사파리 브라우저 호환
  MozTransition: "all 0.4s ease", // 파이어폭스 호환
  OTransition: "all 0.4s ease", // 오페라 브라우저 호환
};
