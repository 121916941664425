import React from "react";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { useAtomValue, useSetAtom } from "jotai";
import { rndPlanStateAtom } from "../../../store/rndDataState";
import { SearchResult } from "../../../types/searchResult.type";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";
import { appSize } from "../../../constants/appSize";

export const RnDPlanView = ({ businessPlanSectionData }: BusinessPlanSectionProps<RnDPlan>) => {
  const isEnglish = useAtomValue(isEnglishDocumentAtom);
  const setBusinessPlanSectionData = useSetAtom(rndPlanStateAtom);
  const numOfYears =
    businessPlanSectionData.contractualPeriod.end.year -
    businessPlanSectionData.contractualPeriod.start.year +
    1;
  const numOfMonthEachYearList = Array(numOfYears)
    .fill(0)
    .map((_, index) => {
      if (index === 0) {
        return 12 - businessPlanSectionData.contractualPeriod.start.month + 1;
      } else if (index === numOfYears - 1) {
        return businessPlanSectionData.contractualPeriod.end.month;
      } else {
        return 12;
      }
    });
  const dateToIndexMap: { [key: string]: number } = {};
  let dateIndex = 0;
  for (
    let year = businessPlanSectionData.contractualPeriod.start.year;
    year <= businessPlanSectionData.contractualPeriod.end.year;
    year++
  ) {
    for (let month = 1; month <= 12; month++) {
      if (
        year === businessPlanSectionData.contractualPeriod.start.year &&
        month < businessPlanSectionData.contractualPeriod.start.month
      ) {
        continue;
      }
      if (
        year === businessPlanSectionData.contractualPeriod.end.year &&
        month > businessPlanSectionData.contractualPeriod.end.month
      ) {
        continue;
      }
      dateToIndexMap[`${year}-${month}`] = dateIndex;
      dateIndex++;
    }
  }

  return (
    <BusinessPlanSectionLayout
      title="기술 개발 일정"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th rowSpan={2} textCenter>
              {isEnglish ? "No." : "순번"}
            </TableLayout.Th>
            <TableLayout.Th rowSpan={2} textCenter>
              {isEnglish ? "Executing Agency" : "수행기관"}
            </TableLayout.Th>
            <TableLayout.Th rowSpan={2} textCenter>
              {isEnglish ? "Develop Info" : "개발 내용"}
            </TableLayout.Th>
            {Array(businessPlanSectionData.contractualPeriod.end.year)
              .fill(0)
              .map((_, index) => {
                return (
                  <TableLayout.Th textCenter key={index} colSpan={numOfMonthEachYearList[index]}>
                    {isEnglish
                      ? `Year ${businessPlanSectionData.contractualPeriod.start.year + index}`
                      : `${businessPlanSectionData.contractualPeriod.start.year + index}차년도`}
                  </TableLayout.Th>
                );
              })}
          </TableLayout.Tr>
          <TableLayout.Tr>
            {Array(businessPlanSectionData.contractualPeriod.end.year)
              .fill(0)
              .map((_, index) => {
                return Array(numOfMonthEachYearList[index])
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <TableLayout.Th key={index} textCenter>
                        {isEnglish ? `${index + 1}M` : `${index + 1}월`}
                      </TableLayout.Th>
                    );
                  });
              })}
          </TableLayout.Tr>
          {businessPlanSectionData.developPlan.map((item, index) => {
            return (
              <TableLayout.Tr key={index}>
                <TableLayout.Td>{index + 1}</TableLayout.Td>
                <TableLayout.Td>{item.executingAgency}</TableLayout.Td>
                <TableLayout.Td>{item.developInfo}</TableLayout.Td>
                {Array(businessPlanSectionData.contractualPeriod.end.year)
                  .fill(0)
                  .map((_, yearIndex) => {
                    return Array(numOfMonthEachYearList[yearIndex])
                      .fill(0)
                      .map((_, monthIndex) => {
                        const date = `${
                          businessPlanSectionData.contractualPeriod.start.year + yearIndex
                        }-${businessPlanSectionData.contractualPeriod.start.month + monthIndex}`;
                        const dateIndex = dateToIndexMap[date];
                        if (
                          dateIndex <
                            dateToIndexMap[
                              `${item.executingDate.start.year}-${item.executingDate.start.month}`
                            ] ||
                          dateIndex >
                            dateToIndexMap[
                              `${item.executingDate.end.year}-${item.executingDate.end.month}`
                            ]
                        ) {
                          return <TableLayout.Td key={monthIndex}> </TableLayout.Td>;
                        }
                        return (
                          <TableLayout.Td key={monthIndex} textCenter>
                            {isEnglish ? "O" : "○"}
                          </TableLayout.Td>
                        );
                      });
                  })}
              </TableLayout.Tr>
            );
          })}
        </TableLayout.TBody>
      </TableLayout>
      <BusinessPlanSectionLayout.P>
        {isEnglish
          ? "- The development schedule above may be changed through mutual agreement with the entrusted research institution"
          : "- 상기 개발 일정은 위탁연구기관과의 상호 협의하에 변경될 수 있음"}
      </BusinessPlanSectionLayout.P>
    </BusinessPlanSectionLayout>
  );
};

export type RnDPlan = {
  contractualPeriod: {
    start: { year: number; month: number };
    end: { year: number; month: number };
  };
  developPlan: {
    executingAgency: string;
    developInfo: string;
    executingDate: { start: { year: number; month: number }; end: { year: number; month: number } };
  }[];
} & { searchResult?: SearchResult[] };
