import React from "react";
import { CompetitorAnalysisAndDifferentiationPlanData } from "..";
import { Button } from "@mui/material";
import { useSetAtom } from "jotai";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import {
  BusinessPlanSectionImageProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { competitorAnalysisAndDifferentiationPlanStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { Empty } from "../../../components/atoms";
import { CompetitorAnalysisUi } from "../../../components/molecules/businessPlan/CompetitorAnalysisUi";

export const CompetitorAnalysisAndDifferentiationPlanView = ({
  businessPlanSectionData,
  copyImage,
  downloadImage,
  isFreeVersion = false,
}: BusinessPlanSectionProps<
  CompetitorAnalysisAndDifferentiationPlanData,
  BusinessPlanSectionImageProps
>) => {
  const setBusinessPlanSectionData = useSetAtom(competitorAnalysisAndDifferentiationPlanStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="경쟁사 분석 및 차별화 전략"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H3>경쟁사 분석</BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.Blockquote>
        TIP : 본래 경쟁사 분석과 포지셔닝 맵은 시장 생태계에서 우리와 경쟁사 각각의 제품/서비스
        위치를 파악하고, 경쟁 우위와 차별화 요소를 얻기 위해 만듭니다. 하지만..! 지원사업에서 경쟁사
        분석의 목표 중 하나는 우리 제품/서비스의 어필입니다.
      </BusinessPlanSectionLayout.Blockquote>
      <ImageTooltip
        title={
          <div style={{ display: "flex" }}>
            <Button onClick={copyImage}>Copy</Button>
            <Empty width="10px" />
            <Button onClick={downloadImage}>Download</Button>
          </div>
        }
      >
        <CompetitorAnalysisUi competitorAnalysisData={businessPlanSectionData} />
      </ImageTooltip>
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData?.differences?.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData?.differences?.contents?.map((difference) => (
        <BusinessPlanSectionLayout.P>- {difference}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};
