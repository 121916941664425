import React from "react";
import { toSvg, toBlob } from "html-to-image";

/**
 * @description html코드에서 svg, png를 다운로드하거나 복사하는 hook
 * @example
 * const { downloadSvg } = useDownloadSvg();
 * downloadSvg({ selector: ".svg", name: "export.svg" }); -> .svg확장자까지 적어줘야 함
 */
export const useExportImageFromHtml = () => {
  const downloadSvg = React.useCallback(async ({ selector, name }: PropsDownloadSvg) => {
    const $html = document.querySelector(selector);
    if (!$html) return;
    await toSvg($html as HTMLElement).then((dataUrl) => {
      const $link = document.createElement("a");
      $link.download = name;
      $link.href = dataUrl;
      $link.click();
    });
  }, []);

  const downloadPng = React.useCallback(async ({ selector, name }: PropsDownloadSvg) => {
    const $html = document.querySelector(selector);
    if (!$html) return;

    // width 750px 짜리를 1500px로 만들어서 더 선명하게 만들어줌
    await toBlob($html as HTMLElement, { pixelRatio: 2 }).then((blob) => {
      const $link = document.createElement("a");
      $link.download = name;
      $link.href = URL.createObjectURL(blob as Blob);
      $link.click();
    });
  }, []);

  const copyToClipboard = React.useCallback(async ({ selector }: { selector: string }) => {
    const $html = document.querySelector(selector);
    if (!$html) return;

    await toBlob($html as HTMLElement, { pixelRatio: 2 }).then((blob) => {
      if (blob) navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
    });
  }, []);

  const getPreEncodedBase64Image = React.useCallback(async ({ selector }: { selector: string }) => {
    const $html = document.querySelector(selector);
    if (!$html) return;

    return await toBlob($html as HTMLElement, { pixelRatio: 2, type: "image/png" }).then((blob) => {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob as Blob);
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
      });
    });
  }, []);

  return { downloadSvg, downloadPng, copyToClipboard, getPreEncodedBase64Image };
};

type PropsDownloadSvg = {
  selector: string;
  name: string;
};
