import React from "react";
import { freeVersionBusinessPlanStyle } from "../../styles/domain.style";

export const BusinessPlanBlurArea = ({
  children,
  isBlur,
  id,
}: {
  children: React.ReactNode;
  isBlur?: boolean;
  id?: string;
}) => {
  return (
    <div
      id={id}
      style={isBlur ? { padding: "1rem", ...freeVersionBusinessPlanStyle } : { padding: "1rem" }}
    >
      {children}
    </div>
  );
};
