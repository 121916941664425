import React from "react";
import { useSetAtom } from "jotai";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { Button } from "@mui/material";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { marketGrowthGraphWithTamSamSomStateAtom } from "../../../store/businessPlanDataState.atom";
import { MarketGrowthGraphWithTamSamSom } from "../../1_1_창업아이템_배경_및_필요성";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { Empty } from "../../../components/atoms";
import { TamSamSomUi } from "../../../components/molecules/businessPlan/TamSamSomUi";

export const TamSamSomView = ({
  businessPlanSectionData,
  copyImage,
  downloadImage,
  isFreeVersion = false,
}: BusinessPlanSectionProps<MarketGrowthGraphWithTamSamSom, BusinessPlanSectionImageProps>) => {
  const setBusinessPlanSectionData = useSetAtom(marketGrowthGraphWithTamSamSomStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="TAM SAM SOM"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H3>{`TAM, SAM, SOM : ${businessPlanSectionData?.tamSamSom.title}`}</BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.Toggle
        summary={<b style={{ color: "green" }}>TAM SAM SOM이란?</b>}
      >
        <BusinessPlanSectionLayout.P style={{ color: "green" }}>
          TAM, SAM, SOM이 무엇인가요? TAM, SAM, SOM은 사업계획서에서 시장을 분석할 때 매우 많이
          쓰이는 방법입니다.
        </BusinessPlanSectionLayout.P>
        <BusinessPlanSectionLayout.P style={{ color: "green" }}>
          1. <b>TAM (Total Addressable Market)</b>: 전체시장, TAM은 우리 제품 혹은 서비스가 바라볼
          수 있는 전체 시장의 크기를 나타냅니다. 이상적인 비전을 보여줍니다.
        </BusinessPlanSectionLayout.P>
        <BusinessPlanSectionLayout.P style={{ color: "green" }}>
          2. <b>SAM (Serviceable Available Market)</b>: 유효시장, TAM 내에서 내 비즈니스 모델과 각종
          여건/제한을 고려해, 실제로 서비스 제공과 제품 판매를 할 수 있는 시장의 크기를 말합니다.
        </BusinessPlanSectionLayout.P>
        <BusinessPlanSectionLayout.P style={{ color: "green" }}>
          3. <b>SOM (Serviceable Obtainable Market)</b>: 수익시장, SAM 내에서 내 자원과 상황 등을
          고려해, 단기간에 실제로 점유할 수 있는 시장 규모입니다.
        </BusinessPlanSectionLayout.P>
      </BusinessPlanSectionLayout.Toggle>
      <BusinessPlanSectionLayout.Blockquote style={{ color: "green" }}>
        TIP : TAM SAM SOM 중에서 가장 중요한 부분은 어디일까요? 단계에 따라 다르겠지만, 초기
        창업가에겐 단연 수익시장인 SOM이 가장 중요하게 작성해야하는 부분입니다. 이 사업이 현실적으로
        달성가능한 사업이라는 것을 보여주세요. TAM과 SAM은 대략적으로 나타내어도 괜찮습니다. 하지만
        SOM은 구체적으로 명확하고 집중되게 제시해주세요. 특정 지역, 인구수, 고객 세그먼트 등을
        근거로 산출해주세요. TAM SAM SOM은 꼭 금액이 아니더라도 고객 수, 판매량, 점유율 등으로
        나타낼 수 있습니다. 하지만 섞지 마세요. 한 곳에 금액으로 적었으면 나머지도 금액으로
        통일시켜주세요.
      </BusinessPlanSectionLayout.Blockquote>
      <ImageTooltip
        title={
          <div style={{ display: "flex" }}>
            <Button onClick={copyImage}>Copy</Button>
            <Empty width="10px" />
            <Button onClick={downloadImage}>Download</Button>
          </div>
        }
      >
        <TamSamSomUi tamSamSom={businessPlanSectionData?.tamSamSom} />
      </ImageTooltip>
      <div>
        {businessPlanSectionData?.tamSamSom?.somDetail?.length > 0 &&
          businessPlanSectionData?.tamSamSom?.somDetail?.map((text, index) => {
            return (
              <BusinessPlanSectionLayout.P key={index} variant="body1" sx={{ lineHeight: "2rem" }}>
                - {text}
              </BusinessPlanSectionLayout.P>
            );
          })}
      </div>
    </BusinessPlanSectionLayout>
  );
};
