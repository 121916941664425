import { BusinessPlanPalette } from "../../store/businessPlanUiState.atom";
import PptxGenJS from "pptxgenjs";
import { appColor } from "../../constants/appColor";
import { IrData } from "../../hooks/domain/useGetIrOutputData";
import { TableCellProps } from "@mui/material";

// [Todo] 첫 번째 슬라이드 안들어오고 있음
/**
 * @description PptxGenJS.Slide를 생성하고, 각 항목마다의 UI 구성을 담당
 */
export class IrPptxSlide {
  private readonly businessPlanPalette: BusinessPlanPalette;
  private readonly pptxSlide: PptxGenJS.Slide;
  private readonly presentation: PptxGenJS;

  constructor({
    businessPlanPalette,
    pptxSlide,
    presentation,
  }: {
    businessPlanPalette: BusinessPlanPalette;
    pptxSlide: PptxGenJS.Slide;
    presentation: PptxGenJS;
  }) {
    this.businessPlanPalette = businessPlanPalette;
    this.pptxSlide = pptxSlide;
    this.presentation = presentation;
  }

  addIndex(indexText: string) {
    this.pptxSlide
      .addShape(this.presentation.ShapeType.rect, {
        x: 0.0,
        y: 0.25,
        w: 2.5,
        h: 0.5,
        fill: { color: this.businessPlanPalette.primaryDarker },
      })
      .addShape(this.presentation.ShapeType.ellipse, {
        x: 2.25,
        y: 0.25,
        w: 0.5,
        h: 0.5,
        fill: { color: this.businessPlanPalette.primaryDarker },
      })
      .addText(indexText, {
        x: 0,
        y: 0.25,
        w: 3,
        h: 0.5,
        fontSize: 16,
        bold: true,
        color: appColor.white,
      });
    return this;
  }

  /**
   * 표지 슬라이드
   */
  setIntro({ irData }: { irData: IrData }) {
    this.addNotes(irData?.script || "")
      .andThisSlide()
      .addText(irData?.contents?.[0] || "", {
        x: 0.5,
        y: 1,
        w: 9,
        h: 2,
        fontSize: 16,
        color: appColor.black,
        fit: "shrink",
      })
      .addText(irData?.title || "", {
        x: 0.5,
        y: 2,
        w: 9,
        h: 1,
        fontSize: 32,
        bold: true,
        color: appColor.black,
        fit: "shrink",
      })
      .addText("[이름]", {
        x: 6,
        y: 4.5,
        w: 3,
        h: 0.5,
        fontSize: 14,
        color: appColor.black,
        align: "right",
        fit: "shrink",
      })
      .addText("[날짜]", {
        x: 6,
        y: 5,
        w: 3,
        h: 0.5,
        fontSize: 14,
        color: appColor.black,
        align: "right",
        fit: "shrink",
      });

    return this;
  }

  /**
   * 템플릿 1
   *  ----------------
   * |     Title      |
   * |  (50)   (50)   |
   * | Asset contents |
   *  ----------------
   */
  setTemplate1({ irData, rawImageData }: PropsSlideTemplate) {
    this.setContentsRight(irData?.contents || [])
      .setTitle(irData?.title || "")
      .addNotes(irData?.script || "")
      .andThisSlide()
      .addImage({
        data: rawImageData.base64,
        x: 0.5,
        y: 2,
        ...this.setImageSize({ rawImageData, width: 4 }),
      });

    return this;
  }

  /**
   * 템플릿 3
   * 서비스 이미지 부분
   *  ----------------
   * |     Title      |
   * |  (50)    (50)  |
   * | Shape contents |
   *  ----------------
   */
  setTemplate3({ irData }: Omit<PropsSlideTemplate, "rawImageData">) {
    this.setContentsRight(irData?.contents || [])
      .setTitle(irData?.title || "")
      .addNotes(irData?.script || "")
      .andThisSlide()
      .addShape(this.presentation.ShapeType.rect, {
        x: 0.5,
        y: 2,
        w: 4,
        h: 3,
        fill: { type: "solid", color: this.businessPlanPalette.primaryDarker },
      })
      .addText("서비스 이미지", {
        x: 0.5,
        y: 2,
        w: 4,
        h: 3,
        fontSize: 14,
        color: appColor.white,
        align: "center",
        valign: "middle",
      });

    return this;
  }

  /**
   * 템플릿 4
   *  ----------------
   * |     Title      |
   * | (25, 25) (50)  |
   * | A , A contents |
   *  ----------------
   */
  setTemplate4({ rawImageData1, rawImageData2, irData }: PropsSlideTemplateWithTwoImages) {
    this.setContentsRight(irData?.contents || [])
      .setTitle(irData?.title || "")
      .addNotes(irData?.script || "")
      .andThisSlide()
      .addImage({
        data: rawImageData1.base64,
        x: 0.5,
        y: 1.5,
        ...this.setImageSize({ rawImageData: rawImageData1, width: 2 }),
      })
      .addImage({
        data: rawImageData2.base64,
        x: 2.5,
        y: 1.5,
        ...this.setImageSize({ rawImageData: rawImageData2, width: 2 }),
      });
    return this;
  }

  /**
   * 템플릿 5
   *  ----------------
   * |     Title      |
   * |     Image      |
   * |    contents    |
   *  ----------------
   */
  setTemplate5({ rawImageData, irData }: PropsSlideTemplate) {
    this.setContentsBottom(irData?.contents || [])
      .setTitle(irData?.title || "")
      .addNotes(irData?.script || "")
      .andThisSlide()
      .addImage({
        data: rawImageData.base64,
        x: 1.5,
        y: 1.5,
        ...this.setImageSize({ rawImageData: rawImageData, width: 7 }),
      });

    return this;
  }

  /**
   * 템플릿 6. 표 1개
   *  ----------------
   * |     Title      |
   * |     Table      |
   * |    contents    |
   *  ----------------
   */
  setTemplate6({ tabledata, irData }: PropsSlideTemplateWithTable) {
    this.setTitle(irData?.title || "")
      .addNotes(irData?.script || "")
      .setTable(tabledata?.tableRows, {
        x: 1,
        y: 1.5,
        w: 9,
        h: 3,
        autoPage: false,
        align: "center",
        border: { pt: 0.5, color: appColor.border },
        ...tabledata?.options,
      })
      .andThisSlide();

    return this;
  }

  /**
   * 템플릿 7. 표 2개
   *  ----------------
   * |     Title      |
   * |  Table  Table  |
   * |    contents    |
   *  ----------------
   */
  setTemplate7({ tabledata1, tabledata2, irData }: PropsSlideTemplateWithTwoTable) {
    this.setTitle(irData?.title || "")
      .addNotes(irData?.script || "")
      .setTable(tabledata1?.tableRows, {
        x: 0.4,
        y: 1.5,
        w: 3,
        h: 2,
        fontSize: 11,
        autoPage: false,
        align: "center",
        border: { pt: 0.5, color: appColor.border },
        ...tabledata1?.options,
      })
      .setTable(tabledata2?.tableRows, {
        x: 5.1,
        y: 1.5,
        w: 3,
        h: 2,
        fontSize: 11,
        autoPage: false,
        align: "center",
        border: { pt: 0.5, color: appColor.border },
        ...tabledata2?.options,
      })
      .andThisSlide();

    return this;
  }

  // Private Area -----------------------------------------------------------------------------------------------------------

  private setTitle(title: string) {
    this.pptxSlide.addText(title, {
      x: 0,
      y: 0.8,
      w: "100%",
      h: 0.5,
      fontSize: 20,
      bold: true,
      color: appColor.black,
      align: "center",
      fit: "shrink",
    });

    return this;
  }

  private setImageSize({ rawImageData, width }: PropsSetImageSize) {
    const numImageWidth = Number(rawImageData.width.replace("px", ""));
    const numImageHeight = Number(rawImageData.height.replace("px", ""));

    return {
      w: width,
      h: (width * numImageHeight) / numImageWidth,
    };
  }

  /**
   * @description 슬라이드에 내용을 오른쪽에 추가
   */
  private setContentsRight(contents: string[]) {
    this.pptxSlide.addText(contents.map((content) => "- " + content).join("\n"), {
      x: "50%",
      y: 2,
      w: "50%",
      h: "40%",
      fontSize: 14,
      color: appColor.black,
      lineSpacingMultiple: 1.5,
      fit: "shrink",
    });

    return this;
  }

  /**
   * @description 슬라이드에 내용을 아래에 추가
   */
  private setContentsBottom(contents: string[]) {
    this.pptxSlide.addText(contents.map((content) => "- " + content).join("\n"), {
      x: 0,
      y: 3,
      w: "100%",
      h: "30%",
      fontSize: 14,
      color: appColor.black,
      lineSpacingMultiple: 1.5,
      fit: "shrink",
      align: "center",
    });

    return this;
  }

  private addNotes(notesText: string) {
    this.pptxSlide.addNotes(notesText);
    return this;
  }

  // [Todo] 표 기능 그냥 정보 넣는 걸로 해야할 듯
  private setTable(tableRow: PptxGenJS.TableRow[], props?: PptxGenJS.TableToSlidesProps) {
    this.pptxSlide.addTable(tableRow, props);
    return this;
  }

  private andThisSlide() {
    return this.pptxSlide;
  }
}

type PropsSlideTemplate = {
  rawImageData: RawImageData;
  irData: IrData;
};

type PropsSlideTemplateWithTwoImages = {
  rawImageData1: RawImageData;
  rawImageData2: RawImageData;
  irData: IrData;
};

type PropsSlideTemplateWithTable = {
  tabledata: Tabledata;
  irData: IrData;
};

type PropsSlideTemplateWithTwoTable = {
  tabledata1: Tabledata;
  tabledata2: Tabledata;
  irData: IrData;
};

type Tabledata = {
  tableRows: PptxGenJS.TableRow[];
  options?: PptxGenJS.TableToSlidesProps;
};

type RawImageData = {
  base64: string;
  /**
   * @unit px
   */
  width: string;
  /**
   * @unit px
   */
  height: string;
};

type PropsSetImageSize = {
  rawImageData: RawImageData;
  width: number;
};
