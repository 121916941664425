import React from "react";
import { BusinessPlanSectionLayout } from "../../../../components/layouts/BusinessPlanSection.layout";
import { TableLayout } from "../../../../components/layouts/TableLayout";
import { BusinessPlanSectionProps } from "../../../../types/businessPlan.type";
import { BusinessRoadmapData } from "../../../3_3_사업추진_일정";
import { useSetAtom } from "jotai";
import { businessRoadmapStateAtom } from "../../../../store/businessPlanDataState.atom";

export const 협약_기간_사업_추진_일정View = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<BusinessRoadmapData>) => {
  const setBusinessPlanSectionData = useSetAtom(businessRoadmapStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="협약 기간 사업 추진 일정"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H3>{"사업 추진 일정(협약기간 내)"}</BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.Blockquote>
        TIP : 현실적으로 달성 가능한 일정으로 설정하시는 것을 추천드립니다. 지원사업 성공 평가 시,
        제출했던 사업계획서 내용과 발표를 바탕으로 성공 여부를 판단할 수 있습니다. 추진 내용에
        과도한 목표들을 설정했다가 달성하지 못하실 경우 최종 발표에서 불이익을 받을 수 있습니다.
      </BusinessPlanSectionLayout.Blockquote>
      <TableLayout style={{ maxWidth: "1280px", height: "fit-content" }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>순번</TableLayout.Th>
            <TableLayout.Th textCenter>추진 내용</TableLayout.Th>
            <TableLayout.Th textCenter>추진 기간</TableLayout.Th>
            <TableLayout.Th textCenter>세부 내용</TableLayout.Th>
          </TableLayout.Tr>
          {businessPlanSectionData.actionPlan.map((plan, index) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionTitle}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionPeriod}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionDetail}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
    </BusinessPlanSectionLayout>
  );
};
