import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { BusinessOverviewView } from "./components/BusinessOverviewView";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { Empty } from "../../components/atoms";
import { Stack } from "@mui/material";
import { isEmpty } from "../../lib/utils/isEmpty";
import { LeanCanvasUiView } from "./components/LeanCanvasUiView";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 2-1. 창업 아이템 현황
 */
export const BusinessStatePage = () => {
  const { copyToClipboard, downloadPng } = useExportImageFromHtml();

  // form 일단 보류
  const businessPlanTitleList = ["린 캔버스", "사업 개요"];
  const [leanCanvasState, setLeanCanvasState] = React.useState<LeanCanvasData>(
    {} as LeanCanvasData
  );
  const [businessOverviewState, setBusinessOverviewState] = React.useState<BusinessOverviewData>(
    {} as BusinessOverviewData
  );
  const setterList = [setLeanCanvasState, setBusinessOverviewState];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() => {
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])));
                }}
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(leanCanvasState) && (
              <LeanCanvasUiView
                businessPlanSectionData={leanCanvasState}
                copyImage={() =>
                  copyToClipboard({
                    selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
                  })
                }
                downloadImage={() =>
                  downloadPng({
                    selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
                    name: `${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}.png`,
                  })
                }
              />
            )}
            <Empty height="1rem" />
            {!isEmpty(businessOverviewState) && (
              <BusinessOverviewView businessPlanSectionData={businessOverviewState} />
            )}
          </Stack>
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

export type LeanCanvasData = {
  title: string;
  problem: string;
  customerSegment: string;
  uniqueValueProposition: string;
  solution: string;
  channel: string;
  revenueStream: string;
  costStructure: string;
  keyMetrics: string;
  unfairAdvantage: string;
} & { searchResult?: SearchResult[] };

export type BusinessOverviewData = {
  title: string;
  progress: string[];
} & { searchResult?: SearchResult[] };
