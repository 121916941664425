export const enum PATH {
  ROOT = "/",
  GET_STARTED = "/get-started",
  HOME = "/home",
  QnA = "/qna",
  LEAN_CANVAS = "/lean-canvas",
  BUSINESS_PLAN = "/business-plan",
  IR_DECK = "/ir-deck",
  TARGET_MARKET_ANALYSIS = "/target-market-analysis",
  BUSINESS_COMMERCIALIZATION_STRATEGY = "/business-commercialization-strategy",
  ITEM_BACKGROUND_AND_NEEDS = "/item-background-and-needs",
  BUSINESS_STATE = "/business-state",
  BUSINESS_REALIZATION_AND_CONCRETIZATION_PLAN = "/business-realization-and-concretization-plan",
  EFFORTS_TO_IMPROVE_SURVIVAL_RATES = "/efforts-to-improve-survival-rates",
  BUSINESS_PROMOTION_SCHEDULE = "/business-promotion-schedule",
  COMBINED = "/combined",
  창중대_예비창업자 = "/docs/창중대-예비창업자",
  생애최초패키지 = "/docs/생애최초패키지",
  창중대_초기창업자 = "/docs/창중대_초기창업자",
  청년창업사관학교 = "/docs/청년창업사관학교",
  예비창업패키지 = "/docs/예비창업패키지",
  초기창업패키지 = "/docs/초기창업패키지",
  신사업창업사관학교 = "/docs/신사업창업사관학교",
  IR = "/docs/ir",
  R_N_D = "/docs/rnd",
  P_S_S_T = "/docs/p_s_s_t",

  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  ERROR = "/error",
}

export const enum QUERY_STRING {
  QNA_ID = "qna_id",
  LEAN_CANVAS_ID = "lc_id",
  BUSINESS_PLAN_ID = "bp_id",
}

export const pathNameMap: PathNameMap = {
  [PATH.ROOT]: "시작하기",
  [PATH.GET_STARTED]: "시작하기",
  [PATH.HOME]: "홈",
  [PATH.QnA]: "질의응답",
  [PATH.LEAN_CANVAS]: "린 캔버스",
  [PATH.BUSINESS_PLAN]: "사업계획서",
  [PATH.IR_DECK]: "IR Deck",
  [PATH.ITEM_BACKGROUND_AND_NEEDS]: "1-1. 창업아이템 배경 및 필요성",
  [PATH.TARGET_MARKET_ANALYSIS]: "1-2. 창업아이템 목표 시장(고객) 현황 분석",
  [PATH.BUSINESS_STATE]: "2-1. 창업 아이템 현황",
  [PATH.BUSINESS_REALIZATION_AND_CONCRETIZATION_PLAN]: "2-2. 창업 아이템 실현 및 구체화 방안",
  [PATH.BUSINESS_COMMERCIALIZATION_STRATEGY]: "3-1. 창업아이템 사업화 추진 전략",
  [PATH.EFFORTS_TO_IMPROVE_SURVIVAL_RATES]: "3-2. 생존율 제고를 위한 노력",
  [PATH.BUSINESS_PROMOTION_SCHEDULE]: "3-3. 사업 추진 일정",
  [PATH.창중대_예비창업자]: "창중대 예비창업자",
  [PATH.생애최초패키지]: "생애최초패키지",
  [PATH.창중대_초기창업자]: "창중대 초기창업자",
  [PATH.청년창업사관학교]: "청년창업사관학교",
  [PATH.예비창업패키지]: "예비창업패키지",
  [PATH.초기창업패키지]: "초기창업패키지",
  [PATH.신사업창업사관학교]: "신사업창업사관학교",
  [PATH.IR]: "IR",
  [PATH.R_N_D]: "R&D",
  [PATH.P_S_S_T]: "PSST 통합 사업계획서",

  [PATH.SIGN_IN]: "Sign In",
  [PATH.SIGN_UP]: "Sign Up",
  [PATH.COMBINED]: "Combined",
};

type PathNameMap = Omit<{ [key in PATH]: string }, PATH.ERROR>;
