import { Typography, styled } from "@mui/material";
import { HelpSvg } from "../svgs";
import { Empty } from "../atoms";

const Layout = styled("section")({
  padding: 10,
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
});

export const GuideSection = ({ children }: Props) => {
  return (
    <Layout>
      <HelpSvg />
      <Empty width="10px" />
      <Typography variant="body1">{children}</Typography>
    </Layout>
  );
};

type Props = {
  children?: React.ReactNode;
};
