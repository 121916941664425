import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./static/fonts/pretendard.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  // [Todo] 얘 때문에 2번 실행됨. 그렇다면 얘를 이렇게 지워서 사용하는게 맞는걸까?
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
