import React from "react";
import { ListItemButton, ListItemText, SvgIconTypeMap, Typography } from "@mui/material";
import { appColor } from "../../../constants/appColor";
import { Empty } from "../../atoms";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const MainSideNavListButton = ({
  children,
  StartIcon,
  disabled = false,
  selected = false,
  onClick,
}: Props) => {
  const [textColor, setTextColor] = React.useState(appColor.text);
  React.useLayoutEffect(() => {
    if (disabled) setTextColor(appColor.disabled);
    else if (selected) setTextColor(appColor.primary);
    else setTextColor(appColor.text);
  }, [disabled, selected]);
  return (
    <li onClick={onClick}>
      <ListItemButton
        disableRipple
        sx={{
          width: "100%",
          borderRadius: "8px",
          padding: "6px",
          margin: "6px 0px",
          backgroundColor: selected ? appColor.primaryLight : "transparent",
          "&:hover, :focus": {
            backgroundColor: selected ? appColor.primaryLight : "",
          },
        }}
      >
        {!!StartIcon && (
          <>
            {/* [Error] 피그마 아이콘 색 조정하기 어려워 mui icon으로 대체 */}
            <StartIcon width={20} height={20} sx={{ color: textColor }} />
            <Empty width="10px" />
          </>
        )}
        <ListItemText
          primary={
            <Typography variant="h5" sx={{ color: textColor, display: "flex" }}>
              {children}
            </Typography>
          }
        />
      </ListItemButton>
    </li>
  );
};

type Props = {
  children?: React.ReactNode;
  StartIcon?: OverridableComponent<SvgIconTypeMap<object, "svg">> & {
    muiName: string;
  };
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
};
