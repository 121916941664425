import axios from "axios";
import { ENV } from "../constants/env";

const postMail = async (formData: FormData) => {
  return await axios.post(`${ENV.BASE_URL}/api/v1/mails/send`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const mailApi = { postMail };
