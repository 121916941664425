import React from "react";
import { Button, Typography } from "@mui/material";
import { appColor } from "../../constants/appColor";
import { LoadingAnimationSvg } from "../svgs";

export const MainCtaBtn = React.forwardRef(
  (
    { children, disabled = false, isLoading = false, ...props }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { sx, ...rest } = props;
    return (
      <Button
        // variant="contained"
        disabled={disabled || isLoading}
        sx={{
          width: "6rem",
          height: "2.5rem",
          backgroundColor: disabled && !isLoading ? "transparent" : appColor.primary,
          border: disabled && !isLoading ? `1px solid ${appColor.border}` : "",
          color: appColor.white,
          "&:hover": { backgroundColor: appColor.primary },
          "& .Mui-disabled": { backgroundColor: appColor.primary },
          padding: "0.5rem 1rem",
          ...sx,
        }}
        {...rest}
        ref={ref}
      >
        <Typography variant="h5" sx={{ color: appColor.white }}>
          {isLoading ? <LoadingAnimationSvg /> : <Typography variant="h5">{children}</Typography>}
        </Typography>
      </Button>
    );
  }
);

type Props = {
  children: string;
  isLoading?: boolean;
} & React.ComponentProps<typeof Button>;
