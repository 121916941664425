import React from "react";
import { MarketGrowthGraphWithTamSamSom } from "..";
import { useSetAtom } from "jotai";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import {
  BusinessPlanSectionImageProps,
  BusinessPlanSectionProps,
} from "../../../types/businessPlan.type";
import { Button } from "@mui/material";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { marketGrowthGraphWithTamSamSomStateAtom } from "../../../store/businessPlanDataState.atom";
import { ImageTooltip } from "../../../components/molecules/ImageTooltip";
import { Empty } from "../../../components/atoms";
import { MarketGrowthGraphUi } from "../../../components/molecules/businessPlan/MarketGrowthGraphUi";

export const MarketGrowthGraphView = ({
  businessPlanSectionData,
  copyImage,
  downloadImage,
  isFreeVersion = false,
}: BusinessPlanSectionProps<MarketGrowthGraphWithTamSamSom, BusinessPlanSectionImageProps>) => {
  const setBusinessPlanSectionData = useSetAtom(marketGrowthGraphWithTamSamSomStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="시장 성장 그래프"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H3>{`${businessPlanSectionData?.marketGrowthGraph?.target} (단위: ${businessPlanSectionData?.marketGrowthGraph.marketSizeUnit})`}</BusinessPlanSectionLayout.H3>
      <ImageTooltip
        title={
          <div style={{ display: "flex" }}>
            <Button onClick={copyImage}>Copy</Button>
            <Empty width="10px" />
            <Button onClick={downloadImage}>Download</Button>
          </div>
        }
      >
        <MarketGrowthGraphUi marketGrowthGraph={businessPlanSectionData.marketGrowthGraph} />
      </ImageTooltip>
    </BusinessPlanSectionLayout>
  );
};
