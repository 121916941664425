import { atom } from "jotai";
import { UseControllerProps } from "react-hook-form";

export type LEAN_CANVAS_NUMBER = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null;

/**
 * @description The current Number of the lean canvas that is being full screen
 * @state LEAN_CANVAS_NUMBER
 * @default null
 */
export const selectedLeanCanvasNumberToFullScreenAtom = atom<LEAN_CANVAS_NUMBER>(null);

/**
 * @description The Reg of the lean canvas that is being full screen
 * @state UseControllerProps
 * @default null
 */
export const selectedLeanCanvasRegToFullScreenAtom = atom<UseControllerProps | null>(null);

/**
 * @description The current state of the Get Started Page Item index
 */
export const getStartedPageItemIndexAtom = atom(0);

/**
 * @description The current state of the Admin Edit Mode
 * @state boolean
 * @default true
 */
export const adminEditModeAtom = atom<boolean>(true);
