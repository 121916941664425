import React from "react";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { Bar, ComposedChart, Legend, Line, XAxis, YAxis } from "recharts";
import { appColor } from "../../../constants/appColor";
import { useAtomValue } from "jotai";
import {
  businessPlanPaletteAtom,
  isEnglishDocumentAtom,
} from "../../../store/businessPlanUiState.atom";
import { TargetSalesGraphData } from "../../../pages/3_2_생존율_제고를_위한_노력";
import { appSize } from "../../../constants/appSize";

export const TargetSalesGraphUi = ({ targetSalesGraphData }: Props) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);
  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  return (
    <div
      id={BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}
      style={{ width: "fit-content", backgroundColor: appColor.white }}
    >
      <ComposedChart
        width={Number(appSize.targetSalesGraph.width.split("px")[0])}
        height={Number(appSize.targetSalesGraph.height.split("px")[0])}
        data={targetSalesGraphData.contents.map((content) => {
          if (isEnglish)
            return {
              name: content.year,
              ...content.cost,
              "Total Revenue": content.totalRevenue,
            };
          else
            return {
              name: content.year,
              ...content.cost,
              "총 매출": content.totalRevenue,
            };
        })}
        margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
      >
        <XAxis dataKey="name" />
        <YAxis unit={targetSalesGraphData.marketSizeUnit} />
        <Legend />
        {targetSalesGraphData.contents.map((content, index) => (
          <Bar
            dataKey={Object.keys(content.cost)[index]}
            stackId="a"
            fill={
              [
                businessPlanPalette.primaryDarker,
                businessPlanPalette.primary,
                businessPlanPalette.primaryLighter,
                businessPlanPalette.primaryLightest,
              ][index]
            }
          />
        ))}
        <Line
          type="monotone"
          dataKey={isEnglish ? "Total Revenue" : "총 매출"}
          stroke={businessPlanPalette.primaryDarkest}
          strokeWidth={2}
        />
      </ComposedChart>
    </div>
  );
};

type Props = {
  targetSalesGraphData: TargetSalesGraphData;
};
