import React from "react";
import styled from "@emotion/styled";
import { appColor } from "../../constants/appColor";

interface TableProps {
  width?: string | number;
  borderCollapse?: "collapse" | "separate";
  tableLayout?: "auto"|"fixed"
}

const Table = styled("table")(({ width = "100%", borderCollapse = "collapse", tableLayout = "fixed" }: TableProps) => ({
  width: width,
  backgroundColor: appColor.backGround,
  borderRadius: "0.1rem",
  borderCollapse: borderCollapse, // border 겹침 방지
  border: `1px solid ${appColor.border}`,
  tableLayout: tableLayout, // table width를 고정
  height: "100%",
}));

const THead = styled("thead")({});

const TBody = styled("tbody")({});

const Tr = styled("tr")({});

const Th = ({ children, colSpan, rowSpan, scrollable, textCenter, ...props }: PropsTableCell) => {
  const { style, ...rest } = props;
  return (
    <th
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={{
        textAlign: textCenter ? "center" : "left",
        alignItems: "center",
        verticalAlign: textCenter ? "" : "top",
        padding: "1rem",
        border: `1px solid ${appColor.border}`,
        ...style,
      }}
      {...rest}
    >
      {children}
    </th>
  );
};

const Td = ({ children, colSpan, rowSpan, textCenter, ...props }: PropsTableCell) => {
  const { style, ...rest } = props;
  return (
    <td
      style={{
        padding: "1rem",
        border: `1px solid ${appColor.border}`,
        verticalAlign: textCenter ? "" : "top",
        textAlign: textCenter ? "center" : "inherit",
        overflowWrap: "break-word", // 텍스트 줄바꿈
        ...style,
      }}
      colSpan={colSpan}
      rowSpan={rowSpan}
      {...rest}
    >
      {children}
    </td>
  );
};

export const TableLayout = Object.assign(Table, { THead, TBody, Tr, Th, Td });

type PropsTableCell = {
  children: React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  scrollable?: boolean;
  textCenter?: boolean; // [Todo] textCenter 설계 다시 하기
} & React.HTMLAttributes<HTMLTableCellElement>;
