import React from "react";
import { Grid, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { MuiColorInput } from "mui-color-input";
import { businessPlanPaletteAtom } from "../../../store/businessPlanUiState.atom";
import { adjustBrightness } from "../../../lib/utils/adjustBrighness";
import { adminEditModeAtom } from "../../../store/uiState.atom";

export const BusinessPlanColorPicker = () => {
  const adminEditMode = useAtomValue(adminEditModeAtom);
  const themeColorPool = [
    "#B67979",
    "#DF982D",
    "#9FB679",
    "#79B68E",
    "#79AFB6",
    "#7986B6",
    "#9479B6",
    "#B679A9",
    "#964b00",
    "#6f6f6f",
  ];
  const [businessPlanPalette, setBusinessPlanPalette] = useAtom(businessPlanPaletteAtom);
  const [businessPlanThemeColor, setBusinessPlanThemeColor] = React.useState<string>(
    businessPlanPalette.primary
  );

  React.useEffect(() => {
    setBusinessPlanPalette({
      primaryLightest: adjustBrightness(businessPlanThemeColor, -0.5),
      primaryLighter: adjustBrightness(businessPlanThemeColor, -0.15),
      primary: businessPlanThemeColor,
      primaryDarker: adjustBrightness(businessPlanThemeColor, 0.15),
      primaryDarkest: adjustBrightness(businessPlanThemeColor, 0.5),
    });
  }, [businessPlanThemeColor]);

  return (
    <Grid container>
      <Grid
        item
        style={{ display: "flex", padding: "0.5rem", alignItems: "center" }}
        columnGap={1}
        direction="row"
      >
        {adminEditMode && (
          <>
            <Typography variant="h5">테마 색상 설정</Typography>
            {themeColorPool.map((color, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: color,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setBusinessPlanThemeColor(color)}
                >
                  <Typography variant="h5" component="p" align="center">
                    {index + 1}
                  </Typography>
                </div>
              );
            })}
          </>
        )}
      </Grid>
      <Grid
        style={{ display: "flex", padding: "0.5rem", alignItems: "center" }}
        columnGap={1}
        width="fit-content"
        direction="row"
      >
        <MuiColorInput
          label="색상"
          format="hex"
          value={businessPlanThemeColor}
          onChange={(event) => {
            setBusinessPlanThemeColor(event);
          }}
        />
        <Typography variant="h5">테마 색상 미리보기</Typography>
        <div style={{ display: "flex" }}>
          {Object.values(businessPlanPalette).map((color, index) => {
            return (
              <div key={index} style={{ width: "2rem", height: "2rem", backgroundColor: color }} />
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};
