import React from "react";
import { ItemBackgroundAndNeedsData } from "..";
import { Empty } from "../../../components/atoms";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { itemBackgroundAndNeedsStateAtom } from "../../../store/businessPlanDataState.atom";
import { useSetAtom } from "jotai";

export const ItemBackgroundAndNeedsView = ({
  businessPlanSectionData,
  is예비창업패키지 = false,
  isFreeVersion = false,
  isEditMode = true
}: BusinessPlanSectionProps<ItemBackgroundAndNeedsData> & {
  is예비창업패키지?: boolean;
}) => {
  const setBusinessPlanSectionData = useSetAtom(itemBackgroundAndNeedsStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="창업아이템의 배경 및 필요성"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      isEditMode={isEditMode}
    >
      <BusinessPlanSectionLayout.H3>
        1. {businessPlanSectionData.backgrounds.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.backgrounds.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        2. {businessPlanSectionData.problems.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.problems.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        3. {businessPlanSectionData.solutions.title}
      </BusinessPlanSectionLayout.H3>
      {isFreeVersion || (
        <BusinessPlanSectionLayout.Blockquote>
          TIP : 내 솔루션을 뒤에 가서 잔뜩 보여주기 전에, 문제 인식에서 먼저 짧고 강력하게
          보여주세요. 가장 핵심적인 부분과 함께 지금껏 달성한 성과를 보여주셔도 좋습니다.
        </BusinessPlanSectionLayout.Blockquote>
      )}
      {businessPlanSectionData.solutions.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        4. {businessPlanSectionData.motivations.title}
      </BusinessPlanSectionLayout.H3>
      {isFreeVersion ||
        (is예비창업패키지 && (
          <BusinessPlanSectionLayout.Blockquote>
            TIP : 내적 배경 및 동기를 요구하는 지원사업들이 있습니다. 예비창업패키지 수혜기업 2000여
            개를 전수조사한 결과 폐업률이 80%가 넘는다고 합니다. 내적 동기를 묻는 이유죠. ‘당신이
            이걸 왜 하는거고 이걸 위해 오랜 기간 버틸 수 있어?’ 내 경력, 이력 등을 모두 이용하여
            내가 이 사업을 해야만 하는 사람이란 걸 어필해주세요.
          </BusinessPlanSectionLayout.Blockquote>
        ))}
      {businessPlanSectionData.motivations.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};
