import React from "react";
import { TableLayout } from "../../layouts/TableLayout";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { CompetitorAnalysisAndDifferentiationPlanData } from "../../../pages/2_2_창업_아이템_실현_및_구체화_방안";
import { appColor } from "../../../constants/appColor";
import { Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { businessPlanPaletteAtom } from "../../../store/businessPlanUiState.atom";

export const CompetitorAnalysisUi = ({ competitorAnalysisData }: Props) => {
  const businessPlanPalette = useAtomValue(businessPlanPaletteAtom);

  return (
    <TableLayout
      id={BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}
      style={{ maxWidth: "1200px", border: "none" }}
    >
      <TableLayout.THead>
        <TableLayout.Tr>
          <TableLayout.Th textCenter style={{ border: "none" }}>
            {" "}
          </TableLayout.Th>
          <TableLayout.Th
            textCenter
            style={{ border: "none", color: businessPlanPalette.primaryDarkest }}
          >
            {competitorAnalysisData?.myItem?.title}
          </TableLayout.Th>
          {competitorAnalysisData?.competitors?.map((competitor) => (
            <TableLayout.Th textCenter style={{ border: "none", color: appColor.grey500 }}>
              {competitor.title}
            </TableLayout.Th>
          ))}
        </TableLayout.Tr>
      </TableLayout.THead>
      <TableLayout.TBody>
        {competitorAnalysisData?.criteria?.map((criteria, index) => (
          <TableLayout.Tr>
            <TableLayout.Td textCenter style={{ border: "none" }}>
              {criteria}
            </TableLayout.Td>
            <TableLayout.Td
              textCenter
              style={{
                border: "none",
                backgroundColor: businessPlanPalette.primaryLightest,
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                color={businessPlanPalette.primaryDarkest}
              >
                {competitorAnalysisData?.myItem?.contents[index]}
              </Typography>
            </TableLayout.Td>
            {competitorAnalysisData?.competitors?.map((competitor) => (
              <TableLayout.Td
                textCenter
                style={{
                  border: "none",
                  borderLeft: `solid 2px ${businessPlanPalette.primaryLightest}`,
                  color: appColor.grey500,
                }}
              >
                {competitor.contents[index]}
              </TableLayout.Td>
            ))}
          </TableLayout.Tr>
        ))}
      </TableLayout.TBody>
    </TableLayout>
  );
};

type Props = {
  competitorAnalysisData: CompetitorAnalysisAndDifferentiationPlanData;
};
