import React from "react";
import { useAlert } from "../../../hooks/infra/useAlert";
import { useDownloadBusinessPlanImage } from "../hooks/useDownloadBusinessPlanImage";
import { useParams } from "react-router-dom";
import { useGetBusinessPlanOutputData } from "../../../hooks/domain/useGetBusinessPlanOutputById";
import { copyHtmlToClipboardById } from "../../../lib/utils/copyHtmlToClipboardById";
import { PageLayout } from "../../../components/layouts/Page.layout";
import { BusinessPlanHeader } from "../../../components/organisms/BusinessPlanHeader";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { ErrorBoundary } from "react-error-boundary";
import { appSize } from "../../../constants/appSize";
import { BusinessPlanBlurArea } from "../../../components/molecules/BusinessPlanBlurArea";
import { isEmpty } from "../../../lib/utils/isEmpty";
import { TamSamSomView } from "../../1_2_창업아이템_목표_시장(고객)_현황_분석/components/TamSamSomView";
import { ClientPersonaView } from "../../1_2_창업아이템_목표_시장(고객)_현황_분석/components/ClientPersonaView";
import { MarketGrowthGraphView } from "../../1_1_창업아이템_배경_및_필요성/components/MarketGrowthGraphView";
import { LeanCanvasUiView } from "../../2_1_창업_아이템_현황/components/LeanCanvasUiView";
import { BusinessOverviewView } from "../../2_1_창업_아이템_현황/components/BusinessOverviewView";
import { BusinessConcretizationView } from "../../2_2_창업_아이템_실현_및_구체화_방안/components/BusinessConcretizationView";
import { CompetitorAnalysisAndDifferentiationPlanView } from "../../2_2_창업_아이템_실현_및_구체화_방안/components/CompetitorAnalysisAndDifferentiationPlanView";
import { PositioningMapView } from "../../2_2_창업_아이템_실현_및_구체화_방안/components/PositioningMapView";
import { BusinessModelView } from "../../3_1_창업아이템_사업화_추진_전략/components/businessModelView";
import { BusinessEntryStrategyView } from "../../3_1_창업아이템_사업화_추진_전략/components/BusinessEntryStrategyView";
import { TargetSalesGraphView } from "../../3_2_생존율_제고를_위한_노력/components/TargetSalesGraphView";
import { GlobalPromotionStrategyView } from "../../3_2_생존율_제고를_위한_노력/components/GlobalPromotionStrategyView";
import { BusinessRoadmapView } from "../../3_3_사업추진_일정/components/BusinessRoadmapView";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { ItemBackgroundAndNeedsView } from "../../1_1_창업아이템_배경_및_필요성/components/ItemBackgroundAndNeedsView";
import { GlobalPromotionStrategyAndBusinessEntryStrategyView } from "../../3_2_생존율_제고를_위한_노력/components/GlobalPromotionStrategyAndBusinessEntryStrategyView";
import { ReferenceLinkSection } from "../../../components/molecules/ReferenceLinkSection";
import { BusinessPlanCautionSection } from "../../../components/molecules/businessPlan/BusinessPlanCautionSection";

const 신사업_창업_사관학교_ID = "신사업_창업_사관학교_id";
const 신사업_창업_사관학교_FREE_VERSION_ID = "신사업_창업_사관학교_FREE_VERSION_ID";

// [Todo] 신사업창업사관학교 폼 추가하기
export const 신사업_창업_사관학교Page = () => {
  const { Alert, openAlert } = useAlert();

  const { copyToClipboard, downloadPng } = useDownloadBusinessPlanImage({ openAlert });

  const { promptOutputId } = useParams();

  const {
    marketGrowthGraphWithTamSamSomState,
    competitorAnalysisAndDifferentiationPlanState,
    itemBackgroundAndNeedsState,
    businessConcretizationState,
    businessModelState,
    businessOverviewState,
    businessRoadmapState,
    clientPersonaState,
    globalPromotionStrategyState,
    positioningMapState,
    targetSalesGraphState,
    businessEntryStrategyState,
    leanCanvasState,
    globalPromotionStrategyAndBusinessEntryStrategyState,
  } = useGetBusinessPlanOutputData({ promptOutputId: Number(promptOutputId) });

  const searchResultList = [
    ...(marketGrowthGraphWithTamSamSomState?.searchResult || []),
    ...(competitorAnalysisAndDifferentiationPlanState?.searchResult || []),
    ...(itemBackgroundAndNeedsState?.searchResult || []),
    ...(businessConcretizationState?.searchResult || []),
    ...(businessModelState?.searchResult || []),
    ...(businessOverviewState?.searchResult || []),
    ...(businessRoadmapState?.searchResult || []),
    ...(clientPersonaState?.searchResult || []),
    ...(globalPromotionStrategyState?.searchResult || []),
    ...(positioningMapState?.searchResult || []),
    ...(targetSalesGraphState?.searchResult || []),
    ...(businessEntryStrategyState?.searchResult || []),
    ...(leanCanvasState?.searchResult || []),
    ...(globalPromotionStrategyAndBusinessEntryStrategyState?.searchResult || []),
  ]
    .filter((searchResult) => searchResult.url)
    .filter((searchResult, index, self) => {
      return index === self.findIndex((t) => t.url === searchResult.url);
    });

  const onCheckSwitch = () => openAlert({ severity: "info", contents: "무료 버전 UI가 없습니다." });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Absolute>
        <Alert />
      </PageLayout.Absolute>
      <PageLayout.Main>
        <BusinessPlanHeader
          isFreeVersionBusinessPlan={false}
          onCheckSwitch={onCheckSwitch}
          businessPlanId={신사업_창업_사관학교_ID}
        />

        <div id={신사업_창업_사관학교_ID} style={{ width: appSize.businessPlanWidth }}>
          <BusinessPlanCautionSection />
          <BusinessPlanBlurArea id={신사업_창업_사관학교_FREE_VERSION_ID} isBlur={false}>
            <BusinessPlanSectionLayout.H1>1. 문제인식 (problem)</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.H2>
              1-1. 창업아이템의 개발 동기 / 개발 추진 경과(이력)
            </BusinessPlanSectionLayout.H2>
            <BusinessPlanSectionLayout.Blockquote>
              {`TIP : 심사위원이 지원사업 사업계획서를 순서대로 읽는다면, 요약 페이지를 제외하고 가장 먼저 보게되는 곳이 문제 인식 부분입니다. 잘 읽히고 잘 보여야합니다. 가능한 쉽게 적고 편하게 보여주세요. \n 
 1. 창업 배경과 관련된 이미지나 그래프는 필수입니다. 특히 시장의 성장과 상황을 꼭 보여주세요. 시장의 문제, 시장 규모, 성장률 등을 쉽게 확인할 수 있어야합니다.\n
 2. 글로벌 시장을 타겟하고 있거나 고려하고 있다는 것을 보여준다면 확실한 차별성을 얻을 수 있습니다.\n
 3. 텍스트 일부에도 정량적인 수치를 나타내주세요.`
                .split("\n")
                .map((text) => (
                  <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                    {text}
                  </BusinessPlanSectionLayout.P>
                ))}
            </BusinessPlanSectionLayout.Blockquote>
            <ErrorBoundary fallbackRender={() => <div>MarketGrowthGraph 에러 발생</div>}>
              {!isEmpty(marketGrowthGraphWithTamSamSomState) && (
                <MarketGrowthGraphView
                  businessPlanSectionData={marketGrowthGraphWithTamSamSomState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
                      name: `${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>ItemBackgroundAndNeeds 에러 발생</div>}>
              {!isEmpty(itemBackgroundAndNeedsState) && (
                <ItemBackgroundAndNeedsView businessPlanSectionData={itemBackgroundAndNeedsState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>BusinessOverview 에러 발생</div>}>
              {!isEmpty(businessOverviewState) && (
                <BusinessOverviewView businessPlanSectionData={businessOverviewState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>
              1-2. 창업아이템 목표시장(고객) 현황 분석
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>TamSamSom 에러 발생</div>}>
              {!isEmpty(marketGrowthGraphWithTamSamSomState) && (
                <TamSamSomView
                  businessPlanSectionData={marketGrowthGraphWithTamSamSomState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
                      name: `${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>ClientPersona 에러 발생</div>}>
              {!isEmpty(clientPersonaState) && (
                <ClientPersonaView
                  businessPlanSectionData={clientPersonaState}
                  listOfCopyImage={[1, 2, 3, 4].map(
                    (num) => () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                      })
                  )}
                  listOfDownloadImage={[1, 2, 3, 4].map(
                    (num) => () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                        name: `${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}.png`,
                      })
                  )}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>2. 실현가능성 (Solution)</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.H2>
              2-1. 창업아이템 현황(준비 정도)
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>LeanCanvas 에러 발생</div>}>
              {!isEmpty(leanCanvasState) && (
                <LeanCanvasUiView
                  businessPlanSectionData={leanCanvasState}
                  copyImage={() =>
                    copyToClipboard({ selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}` })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
                      name: `${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>
              2-2. 창업아이템 실현 및 구체화 방안
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessConcretization 에러 발생</div>}>
              {!isEmpty(businessConcretizationState) && (
                <BusinessConcretizationView businessPlanSectionData={businessConcretizationState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>CompetitorAnalysis 에러 발생</div>}>
              {!isEmpty(competitorAnalysisAndDifferentiationPlanState) && (
                <CompetitorAnalysisAndDifferentiationPlanView
                  businessPlanSectionData={competitorAnalysisAndDifferentiationPlanState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
                      name: `${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>PositioningMap 에러 발생</div>}>
              {!isEmpty(positioningMapState) && (
                <PositioningMapView
                  businessPlanSectionData={positioningMapState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
                      name: `${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>3. 성장전략 (Scale-up)</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.Toggle
              summary={<b style={{ color: "green" }}>각종 비스니스 모델 약어 설명</b>}
            >
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                1. <b>B2C (Business-to-Consumer)</b>: 기업이 최종 소비자에게 제품이나 서비스를
                판매하는 모델. 온라인 쇼핑몰이나 소매점 등
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                2. <b>B2B (Business-to-Business)</b>: 기업이 다른 기업에게 제품이나 서비스를
                판매하는 모델. 제조업체 간의 원자재 등
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                3. <b>B2G (Business-to-Government)</b>: 기업이 정부 기관에 제품이나 서비스를
                판매하는 모델. 많은 경우 정부 입찰을 통해 이루어짐.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                4. <b>C2C (Consumer-to-Consumer)</b>: 소비자가 다른 소비자에게 제품이나 서비스를
                판매하는 모델. 당근 마켓 등.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                5. <b>C2B (Consumer-to-Business)</b>: 개인이 기업에게 제품이나 서비스를 제공하는
                모델. 프리랜서의 기업용 디자인 서비스 등.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                6. <b>B2E (Business-to-Employee)</b>: 직원용 서비스, 혜택 등을 제공하는 모델. 직원
                할인, 복지 프로그램, 기업용 커피머신 대여 서비스 등
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                7. <b>D2C (Direct-to-Consumer)</b>: 제조업체나 브랜드가 중간 판매자 없이 직접
                소비자에게 제품을 판매하는 모델.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                8. <b>B2N (Business-to-Nonprofit)</b>: 기업이 비영리 단체에 제품이나 서비스를
                제공하는 모델.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                9. <b>P2P (Peer-to-Peer)</b>: 개인 간에 서비스나 제품을 교환하는 모델. 공유 경제의
                일부로 볼 수 있으며, Airbnb나 Uber가 여기에 해당됨.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                10. <b>P2B (Peer-to-Business)</b>: 개인이 기업에게 제품이나 서비스를 제공하는 모델.
                이는 C2B와 유사한 개념임.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                11. <b>B2B2C (Business-to-Business-to-Consumer)</b>: 기업이 다른 기업을 통해 최종
                소비자에게 제품이나 서비스를 제공하는 모델. 제조업체가 도매업체를 거쳐 최종
                소비자에게 제품을 판매하는 것 등.
              </BusinessPlanSectionLayout.P>
            </BusinessPlanSectionLayout.Toggle>
            <BusinessPlanSectionLayout.H2>
              {/* [Todo] 추진 성과를 어떻게 해야 하지??? */}
              3-1. 창업아이템 비즈니스 모델 및 사업화 추진성과
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessModel 에러 발생</div>}>
              {!isEmpty(businessModelState) && (
                <BusinessModelView
                  businessPlanSectionData={businessModelState}
                  listOfCopyImage={[1, 2, 3, 4, 5].map(
                    (num) => () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                      })
                  )}
                  listOfDownloadImage={[1, 2, 3, 4, 5].map(
                    (num) => () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                        name: `${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}.png`,
                      })
                  )}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>
              3-2. 창업아이템 사업화 추진 전략
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessEntryStrategy 에러 발생</div>}>
              {!isEmpty(businessEntryStrategyState) && (
                <BusinessEntryStrategyView businessPlanSectionData={businessEntryStrategyState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>TargetSalesGraph 에러 발생</div>}>
              {!isEmpty(targetSalesGraphState) && (
                <TargetSalesGraphView
                  businessPlanSectionData={targetSalesGraphState}
                  listOfCopyImage={[
                    () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
                      }),
                    () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
                      }),
                  ]}
                  listOfDownloadImage={[
                    () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
                        name: `${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}.png`,
                      }),
                    () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
                        name: `${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}.png`,
                      }),
                  ]}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            {/* @deprecated */}
            <ErrorBoundary fallbackRender={() => <div>GlobalPromotionStrategy 에러 발생</div>}>
              {!isEmpty(globalPromotionStrategyState) && (
                <GlobalPromotionStrategyView
                  businessPlanSectionData={globalPromotionStrategyState}
                />
              )}
            </ErrorBoundary>
            <ErrorBoundary fallbackRender={() => <div>GlobalPromotionStrategy 에러 발생</div>}>
              {!isEmpty(globalPromotionStrategyAndBusinessEntryStrategyState) && (
                <GlobalPromotionStrategyAndBusinessEntryStrategyView
                  businessPlanSectionData={globalPromotionStrategyAndBusinessEntryStrategyState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.H2>
              3-3. 사업추진 일정 및 자금운용 계획
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessRoadmap 에러 발생</div>}>
              {!isEmpty(businessRoadmapState) && (
                <BusinessRoadmapView
                  businessPlanSectionData={businessRoadmapState}
                  사업전체일정Title="3-3-1. 사업 전체 로드맵"
                  협약기간사업일정Title="3-3-2. 협약기간 (’24.4.~’25.1.) 내 목표 및 달성 방안"
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <ReferenceLinkSection searchResultList={searchResultList} />
          </BusinessPlanBlurArea>
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
};
