import { PromptOutput, ResGetPromptOutputById } from "../../apis/prompt.api";

/**
 * @description promptTypeName에 해당하는 output을 반환
 * [Todo] 특정 도메인에 너무 종속적
 */
// [Error] 이 곳에서 metaData를 주고, 이를 가지고 UI 마다 파악하면 좋을 것 같음. ex) metaData: { status: 'success' }, metaData: { status: 'error' }
export const getOutputByPromptTypeName = ({ promptOutputData, promptTypeName }: Props): object => {
  const prompt = promptOutputData?.outputs?.find(
    (p: PromptOutput) => p?.promptType?.name === promptTypeName
  );

  try {
    return prompt && prompt.output ? JSON.parse(prompt.output) : ({} as any);
  } catch (e) {
    console.error(e);
    return {};
  }
};

type Props = {
  promptOutputData: ResGetPromptOutputById;
  promptTypeName: string;
};
