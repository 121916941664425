import React from "react";
import { BusinessPlanSectionProps } from "../../../../types/businessPlan.type";
import { BusinessRoadmapData } from "../../../3_3_사업추진_일정";
import { BusinessPlanSectionLayout } from "../../../../components/layouts/BusinessPlanSection.layout";
import { TableLayout } from "../../../../components/layouts/TableLayout";
import { useSetAtom } from "jotai";
import { businessRoadmapStateAtom } from "../../../../store/businessPlanDataState.atom";
import { ReferenceLinkSection } from "../../../../components/molecules/ReferenceLinkSection";

export const 사업_전체_일정View = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<BusinessRoadmapData>) => {
  const setBusinessPlanSectionData = useSetAtom(businessRoadmapStateAtom);
  return (
    <BusinessPlanSectionLayout
      title="사업 전체 일정"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H3>{"사업 추진 일정(전체 사업단계)"}</BusinessPlanSectionLayout.H3>
      <TableLayout style={{ maxWidth: "1280px", height: "fit-content" }}>
        <TableLayout.TBody>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>순번</TableLayout.Th>
            <TableLayout.Th textCenter>추진 내용</TableLayout.Th>
            <TableLayout.Th textCenter>추진 기간</TableLayout.Th>
            <TableLayout.Th textCenter>세부 내용</TableLayout.Th>
          </TableLayout.Tr>
          {businessPlanSectionData.businessRoadMap.map((plan, index) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionTitle}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionPeriod}</TableLayout.Td>
              <TableLayout.Td textCenter>{plan.promotionDetail}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
    </BusinessPlanSectionLayout>
  );
};
