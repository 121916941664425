import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import {
  configMuiBreakpoints,
  configMuiComponents,
  configMuiPalette,
  configMuiTypography,
} from "../mui.config";

let theme = createTheme({
  typography: { ...configMuiTypography },
  palette: { ...configMuiPalette },
  breakpoints: { ...configMuiBreakpoints },
  components: { ...configMuiComponents },
});

theme = responsiveFontSizes(theme);

export const MuiThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
