import React from "react";
import { BusinessPlanSectionProps } from "../../types/businessPlan.type";
import { ItemBackgroundAndNeedsData } from "../../pages/1_1_창업아이템_배경_및_필요성";
import { BusinessPlanSectionLayout } from "../layouts/BusinessPlanSection.layout";
import { Empty } from "../atoms";
import { itemBackgroundAndNeedsStateAtom } from "../../store/businessPlanDataState.atom";
import { useSetAtom } from "jotai";

export const 창업아이템의_개발_동기View = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<ItemBackgroundAndNeedsData>) => {
  const setBusinessPlanSectionData = useSetAtom(itemBackgroundAndNeedsStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="창업아이템의 개발 동기"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H3>
        1. {businessPlanSectionData.backgrounds.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.backgrounds.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        2. {businessPlanSectionData.motivations.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.motivations.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};
