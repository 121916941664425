import axios, { AxiosResponse } from "axios";
import { ENV } from "../constants/env";
import { SurveyInputType } from "../pages/Input/types";

const getPromptOutputById = async (id: number): Promise<AxiosResponse<ResGetPromptOutputById>> => {
  return await axios.get(`${ENV.BASE_URL}/api/v1/prompts/output-collections/${id}`);
};

const getIrOutputById = async (id: number): Promise<AxiosResponse<ResGetPromptOutputById>> => {
  return await axios.get(`${ENV.BASE_URL}/api/v1/prompts/output-collections/${id}`);
};

const getSurveyInputById = async (id: number): Promise<AxiosResponse<SurveyInputType[]>> => {
  return await axios.get(`${ENV.BASE_URL}/api/v1/forms/${id}`);
};

const getOutputCollections = async (): Promise<AxiosResponse<ResGetOutputCollections>> => {
  return await axios.get(`${ENV.BASE_URL}/api/v1/prompts/output-collections`);
};

export const promptApi = {
  getPromptOutputById,
  getIrOutputById,
  getSurveyInputById,
  getOutputCollections,
};

export type ResGetPromptOutputById = {
  id: number;
  notionLink: string | null;
  isMailSent: boolean;
  createdAt: string;
  promptCategory: {
    id: number;
    name: string;
  };
  parent: { id: number; notionLink: string | null; isMailSent: boolean; createdAt: string } | null;
  outputs: PromptOutput[];
};

export type PromptOutput = {
  id: number;
  output: string;
  promptTokens: number;
  outputTokens: number;
  createdAt: string;
  model: string;
  promptType: {
    id: number;
    order: number;
    name: string;
  };
  autoFillInformation: {
    id: number;
  };
};

export type ResSurveyInputById = {
  id: number;
  content: string;
};

export type ResGetOutputCollections = {
  id: string;
  notionLink: string;
  isTestMailSent: boolean;
  createdAt: string;
}[];
