import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { BusinessConcretizationView } from "./components/BusinessConcretizationView";
import { CompetitorAnalysisAndDifferentiationPlanView } from "./components/CompetitorAnalysisAndDifferentiationPlanView";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { PositioningMapView } from "./components/PositioningMapView";
import { Empty } from "../../components/atoms";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { Stack } from "@mui/material";
import { isEmpty } from "../../lib/utils/isEmpty";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 2-2. 창업 아이템 실현 및 구체화 방안
 */
export const BusinessRealizationAndConcretizationPlanPage = () => {
  const { copyToClipboard, downloadPng } = useExportImageFromHtml();

  // form 일단 보류
  const businessPlanTitleList = ["사업 구체화 방안", "경쟁사 분석 및 차별화 방안", "포지셔닝 맵"];
  const [businessConcretizationState, setBusinessConcretizationState] =
    React.useState<BusinessConcretizationData>({} as BusinessConcretizationData);
  const [
    competitorAnalysisAndDifferentiationPlanState,
    setCompetitorAnalysisAndDifferentiationPlanState,
  ] = React.useState<CompetitorAnalysisAndDifferentiationPlanData>(
    {} as CompetitorAnalysisAndDifferentiationPlanData
  );
  const [positioningMapState, setPositioningMapState] = React.useState<PositioningMapData>(
    {} as PositioningMapData
  );

  const setterList = [
    setBusinessConcretizationState,
    setCompetitorAnalysisAndDifferentiationPlanState,
    setPositioningMapState,
  ];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() =>
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])))
                }
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(businessConcretizationState) && (
              <BusinessConcretizationView businessPlanSectionData={businessConcretizationState} />
            )}
            <Empty height="1rem" />
            {!isEmpty(competitorAnalysisAndDifferentiationPlanState) && (
              <CompetitorAnalysisAndDifferentiationPlanView
                businessPlanSectionData={competitorAnalysisAndDifferentiationPlanState}
                copyImage={() =>
                  copyToClipboard({
                    selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
                  })
                }
                downloadImage={() =>
                  downloadPng({
                    selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
                    name: `${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}.png`,
                  })
                }
              />
            )}
            <Empty height="1rem" />
            {!isEmpty(positioningMapState) && (
              <PositioningMapView
                businessPlanSectionData={positioningMapState}
                copyImage={() =>
                  copyToClipboard({
                    selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
                  })
                }
                downloadImage={() =>
                  downloadPng({
                    selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
                    name: `${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}.png`,
                  })
                }
              />
            )}
          </Stack>
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

export type BusinessConcretizationData = {
  businessConcretization: {
    title: string;
    contents: string[];
  }[];
} & { searchResult?: SearchResult[] };

export type CompetitorAnalysisAndDifferentiationPlanData = {
  criteria: string[];
  myItem: {
    title: string;
    contents: string[];
  };
  competitors: {
    title: string;
    contents: string[];
  }[];
  differences: {
    title: string;
    contents: string[];
  };
} & { searchResult?: SearchResult[] };

export type PositioningMapData = {
  metaData: {
    title: string;
    negativeXName: string;
    positiveXName: string;
    negativeYName: string;
    positiveYName: string;
  };
  data: {
    name: string;
    x: number;
    y: number;
  }[];
} & { searchResult?: SearchResult[] };
