import React from "react";
import { appSize } from "../../../constants/appSize";
import { appZIndex } from "../../../constants/appZIndex";
import { appColor } from "../../../constants/appColor";
import { BusinessPlanColorPicker } from "../BusinessPlanColorPicker";
import { Button, Stack, Switch, Typography } from "@mui/material";
import { FreeVersionStateSwitch } from "../../molecules/FreeVersionStateSwitch";
import { useAtom } from "jotai";
import { isEnglishDocumentAtom, isGlobalMarketAtom } from "../../../store/businessPlanUiState.atom";
import { useParams } from "react-router-dom";
import { useNaviTo } from "../../../hooks/infra/useNaviTo";
import { PATH } from "../../../constants/path";
import { useDownloadHtmlToDocx } from "../../../hooks/infra/useDownloadHtmlToDocx";
import { MainCtaBtn } from "../../molecules/MainCtaBtn";
import { useExportImageFromHtml } from "../../../hooks/infra/useExportImageFromHtml";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { useAlert } from "../../../hooks/infra/useAlert";
import { adminEditModeAtom } from "../../../store/uiState.atom";
import { Empty } from "../../atoms";

/**
 * @description 사업계획서 헤더
 */
export const BusinessPlanHeader = ({
  isFreeVersionBusinessPlan,
  onCheckSwitch,
  is청년창업사관학교 = false,
  businessPlanId,
}: Props) => {
  const { Alert, openAlert } = useAlert();
  const [isGlobalMarket, setIsGlobalMarket] = useAtom(isGlobalMarketAtom);
  const [adminEditMode, setAdminEditMode] = useAtom(adminEditModeAtom);

  const onCheckMarketSwitch = () => {
    setIsGlobalMarket((prev) => !prev);
  };

  const { promptOutputId } = useParams();
  const { naviTo } = useNaviTo();
  const { downloadHtmlToDocx } = useDownloadHtmlToDocx();
  const { getPreEncodedBase64Image } = useExportImageFromHtml();

  const svgSelectorList = [
    `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
    `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}1`,
    `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}2`,
    `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}3`,
    `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}4`,
    `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
    `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}1`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}2`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}3`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}4`,
    `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}5`,
    `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
    `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
    `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
  ];

  const onDownloadDocx = async () => {
    openAlert({ contents: "다운로드 중입니다...", severity: "info" });
    const businessPlanHtml = document.querySelector(`#${businessPlanId}`);
    const outerHTML = businessPlanHtml?.outerHTML;

    if (!outerHTML) return;
    const revisedOuterHTML = outerHTML?.replace(/type="button">UI 수정/g, ">");

    const htmlSizeDataList = svgSelectorList.map((selector) => {
      const svg = document.querySelector(selector);
      return { width: svg?.clientWidth, height: svg?.clientHeight };
    });

    // [Todo] 이 객체를 어떻게 리팩토링할까?
    const imageWidth = 400;
    const personaWidth = 200;
    const svgDataList = svgSelectorList.map((selector, index) => {
      const isPersona = selector.includes(BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA);
      return {
        selector,
        width: isPersona ? personaWidth : imageWidth,
        height: htmlSizeDataList[index].width
          ? ((isPersona ? personaWidth : imageWidth) * htmlSizeDataList[index].height!) /
            htmlSizeDataList[index].width!
          : 0,
      };
    });

    const beforeHtmlElementList = svgDataList.map(({ selector }) => {
      return businessPlanHtml.querySelector(selector) as Element | null;
    });

    // [Error] UI 수정 텍스트 삭제 필요
    await Promise.all(
      beforeHtmlElementList.map(async (ele, index) => {
        if (!ele) return;

        const $img = document.createElement("img");
        // [Temp] 매우 비싼 작업
        const base64Image = await getPreEncodedBase64Image({
          selector: svgDataList[index].selector,
        });
        $img.src = base64Image || "";
        $img.width = svgDataList[index].width;
        $img.height = svgDataList[index].height;

        ele?.replaceWith($img);
      })
    );

    await downloadHtmlToDocx({ outerHTML: revisedOuterHTML, fileName: "사업계획서.docx" });
    openAlert({ contents: "다운로드가 완료되었습니다.", severity: "success" });
  };

  const [isEnglish, setIsEnglish] = useAtom(isEnglishDocumentAtom);

  return (
    <>
      <Alert />
      <div
        style={{
          position: "sticky",
          top: appSize.mainHeaderHeight,
          zIndex: appZIndex.MAIN_HEADER,
          width: "100%",
          backgroundColor: appColor.white,
        }}
      >
        {adminEditMode ? (
          <>
            <Stack
              direction="row"
              spacing={3}
              style={{ padding: "0.5rem", width: "100%", alignItems: "center" }}
            >
              <Typography variant="h5" component="h2" align="center" width="5rem">
                문서 양식
              </Typography>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.P_S_S_T}/${promptOutputId}` as PATH)}
              >
                PSST 통합 사업계획서
              </Button>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.창중대_예비창업자}/${promptOutputId}` as PATH)}
              >
                창중대 예비창업자
              </Button>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.청년창업사관학교}/${promptOutputId}` as PATH)}
              >
                청년창업사관학교
              </Button>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.창중대_초기창업자}/${promptOutputId}` as PATH)}
              >
                창중대 초기창업자
              </Button>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.생애최초패키지}/${promptOutputId}` as PATH)}
              >
                생애최초패키지
              </Button>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.예비창업패키지}/${promptOutputId}` as PATH)}
              >
                예비창업패키지
              </Button>
              <Button
                variant="outlined"
                onClick={() => naviTo(`${PATH.초기창업패키지}/${promptOutputId}` as PATH)}
              >
                초기창업패키지
              </Button>
            </Stack>
            <BusinessPlanColorPicker />
            <Stack
              direction="row"
              spacing={3}
              style={{ padding: "0.5rem", width: "100%", alignItems: "center" }}
            >
              <FreeVersionStateSwitch
                isFreeVersionBusinessPlan={isFreeVersionBusinessPlan}
                onCheckSwitch={onCheckSwitch}
              />
              <Typography variant="h5" component="h2" align="center" width="5rem">
                {isEnglish ? "영문" : "한글"}
              </Typography>
              <Switch checked={isEnglish} onChange={() => setIsEnglish((prev) => !prev)} />
              {is청년창업사관학교 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h5" component="h2" align="center" width="5rem">
                    {isGlobalMarket ? "글로벌시장" : "국내시장"}
                  </Typography>
                  <Switch checked={isGlobalMarket} onChange={onCheckMarketSwitch} />
                </div>
              )}
              <MainCtaBtn onClick={onDownloadDocx} sx={{ width: "content-fit" }}>
                문서 다운로드
              </MainCtaBtn>
              <MainCtaBtn onClick={() => setAdminEditMode(false)} sx={{ width: "content-fit" }}>
                고객 사업계획서 미리 보기
              </MainCtaBtn>
            </Stack>
          </>
        ) : (
          <Stack spacing={3} style={{ padding: "0.5rem", width: "100%", marginBottom: "2rem" }}>
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Typography variant="h5" component="h2" align="center" width="5rem">
                색상 변경
              </Typography>
              <BusinessPlanColorPicker />
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Typography variant="h5" component="h2" align="center" width="5rem">
                다운로드
              </Typography>
              <Empty width="1rem" />
              <MainCtaBtn onClick={onDownloadDocx} sx={{ width: "200px" }}>
                워드 문서 다운로드
              </MainCtaBtn>
            </div>
          </Stack>
        )}
      </div>
    </>
  );
};

type Props = {
  isFreeVersionBusinessPlan: boolean;
  onCheckSwitch: () => void;
  is청년창업사관학교?: boolean;
  businessPlanId: string;
};
