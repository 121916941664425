import React from "react";
import { BusinessPlanSectionLayout } from "../../../../components/layouts/BusinessPlanSection.layout";
import { isEmpty } from "../../../../lib/utils/isEmpty";
import { ItemBackgroundAndNeedsView } from "../../../1_1_창업아이템_배경_및_필요성/components/ItemBackgroundAndNeedsView";
import { ClientPersonaView } from "../../../1_2_창업아이템_목표_시장(고객)_현황_분석/components/ClientPersonaView";
import { BUSINESS_PLAN_SVG_ID } from "../../../../constants/domain/businessPlanSvgId";
import { LeanCanvasUiView } from "../../../2_1_창업_아이템_현황/components/LeanCanvasUiView";
import { ItemBackgroundAndNeedsData } from "../../../1_1_창업아이템_배경_및_필요성";
import { ClientPersonaData } from "../../../1_2_창업아이템_목표_시장(고객)_현황_분석";
import { LeanCanvasData } from "../../../2_1_창업_아이템_현황";
import { ErrorBoundary } from "react-error-boundary";
import { useExportImageFromHtml } from "../../../../hooks/infra/useExportImageFromHtml";

export const P_S_S_TFreeVersionSampleView = ({
  itemBackgroundAndNeedsState,
  clientPersonaState,
  copyToClipboard,
  leanCanvasState,
  downloadImage,
  isEditMode = true
}: Props) => {
  return (
    <>
      <BusinessPlanSectionLayout.H1>1. 문제인식 (problem)</BusinessPlanSectionLayout.H1>
      <BusinessPlanSectionLayout.H2>1-1. 창업아이템 배경 및 필요성</BusinessPlanSectionLayout.H2>
      <BusinessPlanSectionLayout.Blockquote>
        {`TIP : 심사위원이 사업계획서를 순서대로 읽는다면, 요약 페이지를 제외하고 가장 먼저 보게되는 곳이 문제 인식 부분입니다. 최대한 쉽고 잘 읽히게 만들어주세요. 창업 배경과 관련된 이미지나 그래프, 특히 시장의 성장과 상황을 꼭 보여주세요. 정량적인 수치를 통해 시장의 문제, 시장 규모, 성장률 등을 쉽게 나타내주세요.`}
      </BusinessPlanSectionLayout.Blockquote>
      <ErrorBoundary fallbackRender={() => <div>1-1. 창업아이템 배경 및 필요성 에러 발생</div>}>
        {!isEmpty(itemBackgroundAndNeedsState) && (
          <ItemBackgroundAndNeedsView
            businessPlanSectionData={itemBackgroundAndNeedsState}
            is예비창업패키지
            isFreeVersion
            isEditMode={isEditMode}
          />
        )}
      </ErrorBoundary>
      <BusinessPlanSectionLayout.H2>
        1-2. 창업아이템 목표시장(고객) 현황 분석
      </BusinessPlanSectionLayout.H2>
      <ErrorBoundary
        fallbackRender={() => <div> 1-2. 창업아이템 목표시장(고객) 현황 분석 에러 발생</div>}
      >
        {!isEmpty(clientPersonaState) && (
          <ClientPersonaView
            businessPlanSectionData={clientPersonaState}
            listOfCopyImage={[1, 2, 3, 4].map(
              (num) => () =>
                copyToClipboard({
                  selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                })
            )}
            listOfDownloadImage={[1, 2, 3, 4].map(
              (num) => () =>
                downloadImage({
                  selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                  name: `${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}.png`,
                })
            )}
            isFreeVersion={true}
            isEditMode={isEditMode}
          />
        )}
      </ErrorBoundary>
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.Line />
      <BusinessPlanSectionLayout.Span />
      <BusinessPlanSectionLayout.H1>2. 실현가능성 (Solution)</BusinessPlanSectionLayout.H1>
      <BusinessPlanSectionLayout.H2>2-1. 창업 아이템 현황 (준비 정도)</BusinessPlanSectionLayout.H2>
      <ErrorBoundary
        fallbackRender={() => <div> 2-1. 창업아이템의 개발/개선 준비현황 에러 발생</div>}
      >
        {!isEmpty(leanCanvasState) && (
          <LeanCanvasUiView
            businessPlanSectionData={leanCanvasState}
            copyImage={() =>
              copyToClipboard({
                selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
              })
            }
            downloadImage={() =>
              downloadImage({
                selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
                name: `${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}.png`,
              })
            }
            isEditMode={isEditMode}
          />
        )}
      </ErrorBoundary>
      <BusinessPlanSectionLayout.Span />
    </>
  );
};

type Props = {
  itemBackgroundAndNeedsState: ItemBackgroundAndNeedsData;
  clientPersonaState: ClientPersonaData;
  leanCanvasState: LeanCanvasData;
  copyToClipboard: ReturnType<typeof useExportImageFromHtml>["copyToClipboard"];
  downloadImage: ReturnType<typeof useExportImageFromHtml>["downloadPng"];
  isEditMode: boolean
};
