import { Button, Stack, Typography, styled } from "@mui/material";
import { appColor } from "../../../constants/appColor";
import { usePathName } from "../../../hooks/infra/usePathName";
import { appZIndex } from "../../../constants/appZIndex";
import { useNaviTo } from "../../../hooks/infra/useNaviTo";
import { PATH } from "../../../constants/path";
import { useAtomValue } from "jotai";
import { docsTitleAtom } from "../../../store/serverState.atom";
import { breakPoints } from "../../../constants/breakPoints";
import { appSize } from "../../../constants/appSize";
import { MainSideNavListButton } from "../MainSideNav/MainSideNavListButton";

const Layout = styled("header")({
  position: "sticky",
  top: 0,
  padding: "0.5rem 5rem",
  minWidth: breakPoints.md,
  width: "100%",
  height: appSize.mainHeaderHeight,
  borderBottom: `1px solid ${appColor.border}`,
  backgroundColor: appColor.backGround,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: appZIndex.MAIN_HEADER,
});

export const MainHeader = ({ isMainFunctionPage = false }: Props) => {
  const { naviTo } = useNaviTo();
  return (
    <Layout>
      <MainSideNavListButton
        onClick={() => naviTo(PATH.ITEM_BACKGROUND_AND_NEEDS)}
        selected={false}
      >
        사업계획서 항목별 UI 렌더링
      </MainSideNavListButton>
      <MainSideNavListButton onClick={() => naviTo(PATH.IR)} selected={false}>
        IR 페이지로 이동하기
      </MainSideNavListButton>
    </Layout>
  );
};

type Props = {
  /**
   * @description 메인 기능 페이지인지 여부
   * @default true
   */
  isMainFunctionPage: boolean;
  createDocs: () => void;
};
