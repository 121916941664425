import { atomWithStorage } from "jotai/utils";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { appColor } from "../constants/appColor";
import { adjustBrightness } from "../lib/utils/adjustBrighness";
import { atom } from "jotai";

/**
 * @description Business Plan Color Atom
 * @state {string}
 */
export const businessPlanPaletteAtom = atomWithStorage<BusinessPlanPalette>(
  LOCAL_STORAGE.BUSINESS_PLAN_COLOR,
  {
    primaryLightest: adjustBrightness(appColor.primary, -0.5),
    primaryLighter: adjustBrightness(appColor.primary, -0.25),
    primary: appColor.primary,
    primaryDarker: adjustBrightness(appColor.primary, 0.25),
    primaryDarkest: adjustBrightness(appColor.primary, 0.5),
  }
);

export type BusinessPlanPalette = {
  primaryLightest: string;
  primaryLighter: string;
  primary: string;
  primaryDarker: string;
  primaryDarkest: string;
};

/**
 * @description Business Plan 청년창업사관학교 isGlobalMarket Atom
 * @state {boolean}
 * @default {false}
 */
export const isGlobalMarketAtom = atom<boolean>(false);

/**
 * @description Business Plan UI Editor Open Atom
 * @state {boolean}
 * @default {false}
 */
export const isBusinessPlanUiEditorOpenAtom = atom<boolean>(false);

/**
 * @description Business Plan English Document Atom
 * @state {boolean}
 * @default {false}
 */
export const isEnglishDocumentAtom = atom<boolean>(false);
