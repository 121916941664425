import React from "react";
import { GlobalPromotionStrategyAndBusinessEntryStrategyData } from "..";
import { Empty } from "../../../components/atoms";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { BusinessPlanSectionProps } from "../../../types/businessPlan.type";
import { freeVersionBusinessPlanStyle } from "../../../styles/domain.style";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { TableLayout } from "../../../components/layouts/TableLayout";
import { Typography } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { globalPromotionStrategyAndBusinessEntryStrategyStateAtom } from "../../../store/businessPlanDataState.atom";
import { isEnglishDocumentAtom } from "../../../store/businessPlanUiState.atom";
import { appSize } from "../../../constants/appSize";

export const GlobalPromotionStrategyAndBusinessEntryStrategyView = ({
  businessPlanSectionData,
  isFreeVersion = false,
  isGlobalMarket = false,
}: BusinessPlanSectionProps<GlobalPromotionStrategyAndBusinessEntryStrategyData> & {
  /**
   * @description 목표 시장이 글로벌 시장인지 여부
   */
  isGlobalMarket?: boolean;
}) => {
  const setBusinessPlanSectionData = useSetAtom(
    globalPromotionStrategyAndBusinessEntryStrategyStateAtom
  );

  const isEnglish = useAtomValue(isEnglishDocumentAtom);

  return (
    <BusinessPlanSectionLayout
      title={isGlobalMarket ? "글로벌 시장 확장 전략" : "글로벌 진출 전략"}
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
      id={`${BUSINESS_PLAN_SVG_ID.GLOBAL_PROMOTION_STRATEGY}_BLURRED`}
      style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
    >
      <BusinessPlanSectionLayout.H2>
        {isGlobalMarket ? "글로벌 시장 확장 전략" : "글로벌 진출 전략"}
      </BusinessPlanSectionLayout.H2>
      {!isGlobalMarket && (
        <BusinessPlanSectionLayout.Blockquote>
          TIP : 내수 시장 만을 노리겠다고 하는 사업은 지원사업 평가에 있어 점점 경쟁력이 떨어지고
          있습니다. 글로벌 진출 전략의 중요성은 커지고 있으며, 이는 글로벌 관련 지원사업의 정부 예산
          확대로도 느낄 수 있습니다.
        </BusinessPlanSectionLayout.Blockquote>
      )}
      <BusinessPlanSectionLayout.H3>
        {businessPlanSectionData.globalMarketTarget}
      </BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.H3>
        1. {businessPlanSectionData.background.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.background.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        2. {businessPlanSectionData.solution.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.solution.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <BusinessPlanSectionLayout.H3
        style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}
      >
        시장 초기 진입 전략 단계별 표
      </BusinessPlanSectionLayout.H3>
      <TableLayout
        style={
          isFreeVersion
            ? { ...appSize.table, ...freeVersionBusinessPlanStyle }
            : { ...appSize.table }
        }
      >
        <TableLayout.THead>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>{isEnglish ? "Step" : "단계"}</TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
          </TableLayout.Tr>
        </TableLayout.THead>
        <TableLayout.TBody>
          {businessPlanSectionData.marketEntryStrategy.map((content, index) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{index + 1}</TableLayout.Td>
              <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
              <TableLayout.Td>{content.contents}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
      <Empty height="1rem" />
      <Typography variant="h4">마케팅 전략</Typography>
      <Empty height="1rem" />
      <TableLayout style={{ ...appSize.table }}>
        <TableLayout.THead>
          <TableLayout.Tr>
            <TableLayout.Th textCenter>{isEnglish ? "Title" : "제목"}</TableLayout.Th>
            <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
          </TableLayout.Tr>
        </TableLayout.THead>
        <TableLayout.TBody>
          {businessPlanSectionData.marketingStrategy.map((content) => (
            <TableLayout.Tr>
              <TableLayout.Td textCenter>{content.title}</TableLayout.Td>
              <TableLayout.Td>{content.contents}</TableLayout.Td>
            </TableLayout.Tr>
          ))}
        </TableLayout.TBody>
      </TableLayout>
      <Empty height="1rem" />
      {businessPlanSectionData.marketingStrategyDetail.map((marketingStrategyDetail) => (
        <>
          <Typography variant="h4" style={isFreeVersion ? { ...freeVersionBusinessPlanStyle } : {}}>
            {marketingStrategyDetail.title}
          </Typography>
          <Empty height="1rem" />
          <TableLayout
            style={
              isFreeVersion
                ? { ...appSize.table, ...freeVersionBusinessPlanStyle }
                : { ...appSize.table }
            }
          >
            <TableLayout.THead>
              <TableLayout.Tr>
                <TableLayout.Th textCenter>{isEnglish ? "Criteria" : "구분"}</TableLayout.Th>
                <TableLayout.Th textCenter>{isEnglish ? "Contents" : "내용"}</TableLayout.Th>
              </TableLayout.Tr>
            </TableLayout.THead>
            <TableLayout.TBody>
              {marketingStrategyDetail.rows.map((content) => (
                <TableLayout.Tr>
                  <TableLayout.Td textCenter>{content.criteria}</TableLayout.Td>
                  <TableLayout.Td>{content.content}</TableLayout.Td>
                </TableLayout.Tr>
              ))}
            </TableLayout.TBody>
          </TableLayout>
          <Empty height="1rem" />
        </>
      ))}
    </BusinessPlanSectionLayout>
  );
};
