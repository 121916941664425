import React, { useEffect, useState } from "react";
import { PageLayout } from "../../../components/layouts/Page.layout";
import { ItemBackgroundAndNeedsView } from "../../1_1_창업아이템_배경_및_필요성/components/ItemBackgroundAndNeedsView";
import { isEmpty } from "../../../lib/utils/isEmpty";
import { MarketGrowthGraphView } from "../../1_1_창업아이템_배경_및_필요성/components/MarketGrowthGraphView";
import { PositioningMapView } from "../../2_2_창업_아이템_실현_및_구체화_방안/components/PositioningMapView";
import { useGetBusinessPlanOutputData } from "../../../hooks/domain/useGetBusinessPlanOutputById";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TamSamSomView } from "../../1_2_창업아이템_목표_시장(고객)_현황_분석/components/TamSamSomView";
import { ClientPersonaView } from "../../1_2_창업아이템_목표_시장(고객)_현황_분석/components/ClientPersonaView";
import { LeanCanvasUiView } from "../../2_1_창업_아이템_현황/components/LeanCanvasUiView";
import { BusinessOverviewView } from "../../2_1_창업_아이템_현황/components/BusinessOverviewView";
import { BusinessConcretizationView } from "../../2_2_창업_아이템_실현_및_구체화_방안/components/BusinessConcretizationView";
import { CompetitorAnalysisAndDifferentiationPlanView } from "../../2_2_창업_아이템_실현_및_구체화_방안/components/CompetitorAnalysisAndDifferentiationPlanView";
import { BusinessModelView } from "../../3_1_창업아이템_사업화_추진_전략/components/businessModelView";
import { BusinessEntryStrategyView } from "../../3_1_창업아이템_사업화_추진_전략/components/BusinessEntryStrategyView";
import { TargetSalesGraphView } from "../../3_2_생존율_제고를_위한_노력/components/TargetSalesGraphView";
import { GlobalPromotionStrategyView } from "../../3_2_생존율_제고를_위한_노력/components/GlobalPromotionStrategyView";
import { BusinessRoadmapView } from "../../3_3_사업추진_일정/components/BusinessRoadmapView";
import { BUSINESS_PLAN_SVG_ID } from "../../../constants/domain/businessPlanSvgId";
import { BusinessPlanSectionLayout } from "../../../components/layouts/BusinessPlanSection.layout";
import { useAlert } from "../../../hooks/infra/useAlert";
import { useDownloadBusinessPlanImage } from "../hooks/useDownloadBusinessPlanImage";
import { ErrorBoundary } from "react-error-boundary";
import { P_S_S_TFreeVersionSampleView } from "./components/P_S_S_TFreeVersionSampleView";
import { BusinessPlanHeader } from "../../../components/organisms/BusinessPlanHeader";
import { BusinessPlanBlurArea } from "../../../components/molecules/BusinessPlanBlurArea";
import { appSize } from "../../../constants/appSize";
import { GlobalPromotionStrategyAndBusinessEntryStrategyView } from "../../3_2_생존율_제고를_위한_노력/components/GlobalPromotionStrategyAndBusinessEntryStrategyView";
import { ReferenceLinkSection } from "../../../components/molecules/ReferenceLinkSection";
import { BusinessPlanCautionSection } from "../../../components/molecules/businessPlan/BusinessPlanCautionSection";
import { ENV } from "../../../constants/env";
import { PATH } from "../../../constants/path";
import { useAtom } from "jotai";
import { adminEditModeAtom } from "../../../store/uiState.atom";
import { mailApi } from "../../../apis/mails.api";

const P_S_S_T_ID = "P_S_S_T_id";
const P_S_S_T_FREE_VERSION_ID = "P_S_S_T_FREE_VERSION_ID";

/**
 * @description 사업계획서 전체 페이지
 */
export const P_S_S_TPage = () => {
  const [adminEditMode, setAdminEditMode] = useAtom(adminEditModeAtom);
  const location = useLocation();
  const navigate = useNavigate();

  const { Alert, openAlert } = useAlert();

  const { copyToClipboard, downloadPng } = useDownloadBusinessPlanImage({ openAlert });

  const { promptOutputId } = useParams();

  const {
    promptOutputData,
    marketGrowthGraphWithTamSamSomState,
    competitorAnalysisAndDifferentiationPlanState,
    itemBackgroundAndNeedsState,
    businessConcretizationState,
    businessModelState,
    businessOverviewState,
    businessRoadmapState,
    clientPersonaState,
    globalPromotionStrategyState,
    positioningMapState,
    targetSalesGraphState,
    businessEntryStrategyState,
    leanCanvasState,
    globalPromotionStrategyAndBusinessEntryStrategyState,
  } = useGetBusinessPlanOutputData({ promptOutputId: Number(promptOutputId) });

  // sendState 확인 후 메일 전송
  useEffect(() => {
    if (location.state === "sendState") {
      setAdminEditMode(false);

      const sendMail = async () => {
        // [Temp] 이 조건은 무료 버전일 때만 해당
        console.log(leanCanvasState, itemBackgroundAndNeedsState, clientPersonaState); // [Test]
        if (
          promptOutputId &&
          promptOutputData?.resInputData.title &&
          !isEmpty(leanCanvasState) &&
          !isEmpty(itemBackgroundAndNeedsState) &&
          !isEmpty(clientPersonaState)
        ) {
          const htmlContent = document.documentElement.outerHTML;
          const formData = new FormData();
          formData.append("outputCollectionId", promptOutputId);
          formData.append("subject", "[Docshunt] 사업계획서가 도착했습니다!");
          formData.append(
            "content",
            promptOutputData?.resInputData.title +
              "에 대한 사업계획서가 도착했습니다. 첨부파일을 확인해 주세요"
          );
          formData.append(
            "attachment",
            new Blob([htmlContent], { type: "text/html" }),
            `사업계획서(${promptOutputData?.resInputData.title}).html`
          );
          // [Temp] 테스트용 메일 전송 -> mailTrap에서 메일 테스트용으로 사용. 이게 없으면 실제로 보내져버림
          formData.append("isTest", "true");

          // [Temp] 서버 기능 도입 시 재확인 필요
          try {
            const response = await mailApi.postMail(formData);
            if (response.status === 200) {
              openAlert({
                contents: "사업계획서 메일 전송 완료",
                severity: "success",
              });
            }
          } catch (error) {
            openAlert({
              contents: "사업계획서 메일 전송 실패",
              severity: "error",
            });
          }
        }

        navigate(PATH.P_S_S_T);
      };

      sendMail();
    }
  }, [promptOutputData, leanCanvasState, itemBackgroundAndNeedsState, clientPersonaState]);

  // html 문서 직접 다운로드
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!adminEditMode && event.ctrlKey && event.key === "d") {
        event.preventDefault();
        const htmlContent = document.documentElement.outerHTML;

        const blob = new Blob([htmlContent], { type: "text/html" });
        const url = URL.createObjectURL(blob);

        const filename = `사업계획서(${promptOutputData?.resInputData.title}).html`;

        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();

        URL.revokeObjectURL(url);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [adminEditMode]);

  const searchResultList = [
    ...(marketGrowthGraphWithTamSamSomState?.searchResult || []),
    ...(competitorAnalysisAndDifferentiationPlanState?.searchResult || []),
    ...(itemBackgroundAndNeedsState?.searchResult || []),
    ...(businessConcretizationState?.searchResult || []),
    ...(businessModelState?.searchResult || []),
    ...(businessOverviewState?.searchResult || []),
    ...(businessRoadmapState?.searchResult || []),
    ...(clientPersonaState?.searchResult || []),
    ...(globalPromotionStrategyState?.searchResult || []),
    ...(positioningMapState?.searchResult || []),
    ...(targetSalesGraphState?.searchResult || []),
    ...(businessEntryStrategyState?.searchResult || []),
    ...(leanCanvasState?.searchResult || []),
    ...(globalPromotionStrategyAndBusinessEntryStrategyState?.searchResult || []),
  ]
    .filter((searchResult) => searchResult.url)
    .filter((searchResult, index, self) => {
      return index === self.findIndex((t) => t.url === searchResult.url);
    });

  const [isFreeVersionBusinessPlan, setIsFreeVersionBusinessPlan] = React.useState(true);
  const onCheckSwitch = () => setIsFreeVersionBusinessPlan((prev) => !prev);

  return (
    <PageLayout isMainFunctionPage noHeader={!adminEditMode}>
      <PageLayout.Absolute>
        <Alert />
      </PageLayout.Absolute>
      <PageLayout.Main>
        {adminEditMode && (
          <BusinessPlanHeader
            isFreeVersionBusinessPlan={isFreeVersionBusinessPlan}
            onCheckSwitch={onCheckSwitch}
            businessPlanId={P_S_S_T_ID}
          />
        )}
        <div id={P_S_S_T_ID} style={{ width: appSize.businessPlanWidth }}>
          <BusinessPlanSectionLayout.H1>
            {promptOutputData?.resInputData?.title || "Untitled"}
          </BusinessPlanSectionLayout.H1>
          <BusinessPlanCautionSection />
          {isFreeVersionBusinessPlan && (
            <P_S_S_TFreeVersionSampleView
              itemBackgroundAndNeedsState={itemBackgroundAndNeedsState}
              clientPersonaState={clientPersonaState}
              copyToClipboard={copyToClipboard}
              leanCanvasState={leanCanvasState}
              downloadImage={downloadPng}
              isEditMode={adminEditMode}
            />
          )}

          <BusinessPlanBlurArea isBlur={isFreeVersionBusinessPlan} id={P_S_S_T_FREE_VERSION_ID}>
            <BusinessPlanSectionLayout.H1>1. 문제인식 (problem)</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.H2>
              1-1. 창업아이템 배경 및 필요성
            </BusinessPlanSectionLayout.H2>
            <BusinessPlanSectionLayout.Blockquote>
              {`TIP : 심사위원이 사업계획서를 순서대로 읽는다면, 요약 페이지를 제외하고 가장 먼저 보게되는 곳이 문제 인식 부분입니다. 최대한 쉽고 잘 읽히게 만들어주세요. 창업 배경과 관련된 이미지나 그래프, 특히 시장의 성장과 상황을 꼭 보여주세요. 정량적인 수치를 통해 시장의 문제, 시장 규모, 성장률 등을 쉽게 나타내주세요.`}
            </BusinessPlanSectionLayout.Blockquote>
            <ErrorBoundary fallbackRender={() => <div>MarketGrowthGraph 에러 발생</div>}>
              {!isEmpty(marketGrowthGraphWithTamSamSomState) && (
                <MarketGrowthGraphView
                  businessPlanSectionData={marketGrowthGraphWithTamSamSomState}
                  copyImage={() =>
                    copyToClipboard({ selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}` })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}`,
                      name: `${BUSINESS_PLAN_SVG_ID.MARKET_GROWTH_GRAPH}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            {!isEmpty(itemBackgroundAndNeedsState) && (
              <ItemBackgroundAndNeedsView
                businessPlanSectionData={itemBackgroundAndNeedsState}
                is예비창업패키지
                isEditMode={adminEditMode}
              />
            )}
            <BusinessPlanSectionLayout.H2>
              1-2. 창업아이템 목표시장(고객) 현황 분석
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>TamSamSom 에러 발생</div>}>
              {!isEmpty(marketGrowthGraphWithTamSamSomState) && (
                <TamSamSomView
                  businessPlanSectionData={marketGrowthGraphWithTamSamSomState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}`,
                      name: `${BUSINESS_PLAN_SVG_ID.TAM_SAM_SOM}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>ClientPersona 에러 발생</div>}>
              {!isEmpty(clientPersonaState) && (
                <ClientPersonaView
                  businessPlanSectionData={clientPersonaState}
                  listOfCopyImage={[1, 2, 3, 4].map(
                    (num) => () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                      })
                  )}
                  listOfDownloadImage={[1, 2, 3, 4].map(
                    (num) => () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}`,
                        name: `${BUSINESS_PLAN_SVG_ID.CLIENT_PERSONA}${num}.png`,
                      })
                  )}
                  isEditMode={adminEditMode}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>2. 실현가능성 (Solution)</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.H2>
              2-1. 창업 아이템 현황(준비 정도)
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>LeanCanvas 에러 발생</div>}>
              {!isEmpty(leanCanvasState) && (
                <LeanCanvasUiView
                  businessPlanSectionData={leanCanvasState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}`,
                      name: `${BUSINESS_PLAN_SVG_ID.LEAN_CANVAS}.png`,
                    })
                  }
                  isEditMode={adminEditMode}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>BusinessOverview 에러 발생</div>}>
              {!isEmpty(businessOverviewState) && (
                <BusinessOverviewView businessPlanSectionData={businessOverviewState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>
              2-2. 창업아이템 실현 및 구체화 방안
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessConcretization 에러 발생</div>}>
              {!isEmpty(businessConcretizationState) && (
                <BusinessConcretizationView businessPlanSectionData={businessConcretizationState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>CompetitorAnalysis 에러 발생</div>}>
              {!isEmpty(competitorAnalysisAndDifferentiationPlanState) && (
                <CompetitorAnalysisAndDifferentiationPlanView
                  businessPlanSectionData={competitorAnalysisAndDifferentiationPlanState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}`,
                      name: `${BUSINESS_PLAN_SVG_ID.COMPETITOR_ANALYSIS}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>PositioningMap 에러 발생</div>}>
              {!isEmpty(positioningMapState) && (
                <PositioningMapView
                  businessPlanSectionData={positioningMapState}
                  copyImage={() =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
                    })
                  }
                  downloadImage={() =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}`,
                      name: `${BUSINESS_PLAN_SVG_ID.POSITIONING_MAP}.png`,
                    })
                  }
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H1>3. 성장전략 (Scale-up)</BusinessPlanSectionLayout.H1>
            <BusinessPlanSectionLayout.Toggle
              summary={<b style={{ color: "green" }}>각종 비스니스 모델 약어 설명</b>}
            >
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                1. <b>B2C (Business-to-Consumer)</b>: 기업이 최종 소비자에게 제품이나 서비스를
                판매하는 모델. 온라인 쇼핑몰이나 소매점 등
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                2. <b>B2B (Business-to-Business)</b>: 기업이 다른 기업에게 제품이나 서비스를
                판매하는 모델. 제조업체 간의 원자재 등
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                3. <b>B2G (Business-to-Government)</b>: 기업이 정부 기관에 제품이나 서비스를
                판매하는 모델. 많은 경우 정부 입찰을 통해 이루어짐.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                4. <b>C2C (Consumer-to-Consumer)</b>: 소비자가 다른 소비자에게 제품이나 서비스를
                판매하는 모델. 당근 마켓 등.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                5. <b>C2B (Consumer-to-Business)</b>: 개인이 기업에게 제품이나 서비스를 제공하는
                모델. 프리랜서의 기업용 디자인 서비스 등.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                6. <b>B2E (Business-to-Employee)</b>: 직원용 서비스, 혜택 등을 제공하는 모델. 직원
                할인, 복지 프로그램, 기업용 커피머신 대여 서비스 등
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                7. <b>D2C (Direct-to-Consumer)</b>: 제조업체나 브랜드가 중간 판매자 없이 직접
                소비자에게 제품을 판매하는 모델.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                8. <b>B2N (Business-to-Nonprofit)</b>: 기업이 비영리 단체에 제품이나 서비스를
                제공하는 모델.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                9. <b>P2P (Peer-to-Peer)</b>: 개인 간에 서비스나 제품을 교환하는 모델. 공유 경제의
                일부로 볼 수 있으며, Airbnb나 Uber가 여기에 해당됨.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                10. <b>P2B (Peer-to-Business)</b>: 개인이 기업에게 제품이나 서비스를 제공하는 모델.
                이는 C2B와 유사한 개념임.
              </BusinessPlanSectionLayout.P>
              <BusinessPlanSectionLayout.P style={{ color: "green" }}>
                11. <b>B2B2C (Business-to-Business-to-Consumer)</b>: 기업이 다른 기업을 통해 최종
                소비자에게 제품이나 서비스를 제공하는 모델. 제조업체가 도매업체를 거쳐 최종
                소비자에게 제품을 판매하는 것 등.
              </BusinessPlanSectionLayout.P>
            </BusinessPlanSectionLayout.Toggle>
            <BusinessPlanSectionLayout.H2>
              3-1. 창업아이템 비즈니스 모델
            </BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessModel 에러 발생</div>}>
              {!isEmpty(businessModelState) && (
                <BusinessModelView
                  businessPlanSectionData={businessModelState}
                  listOfCopyImage={[1, 2, 3, 4, 5].map(
                    (num) => () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                      })
                  )}
                  listOfDownloadImage={[1, 2, 3, 4, 5].map(
                    (num) => () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                        name: `${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}.png`,
                      })
                  )}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.H2>
              3-2. 창업아이템 사업화 추진 전략
            </BusinessPlanSectionLayout.H2>
            <BusinessPlanSectionLayout.Blockquote>
              TIP : 본 사업이 매출을 통해 빠르게 자생&성장 가능함을 어필하는 것이 가장 주효합니다.
            </BusinessPlanSectionLayout.Blockquote>
            <ErrorBoundary fallbackRender={() => <div>BusinessEntryStrategy 에러 발생</div>}>
              {!isEmpty(businessEntryStrategyState) && (
                <BusinessEntryStrategyView businessPlanSectionData={businessEntryStrategyState} />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <ErrorBoundary fallbackRender={() => <div>TargetSalesGraph 에러 발생</div>}>
              {!isEmpty(targetSalesGraphState) && (
                <TargetSalesGraphView
                  businessPlanSectionData={targetSalesGraphState}
                  listOfCopyImage={[
                    () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
                      }),
                    () =>
                      copyToClipboard({
                        selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
                      }),
                  ]}
                  listOfDownloadImage={[
                    () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}`,
                        name: `${BUSINESS_PLAN_SVG_ID.TARGET_SALES_GRAPH}.png`,
                      }),
                    () =>
                      downloadPng({
                        selector: `#${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}`,
                        name: `${BUSINESS_PLAN_SVG_ID.FINANCIAL_STATEMENT}.png`,
                      }),
                  ]}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            {/* @deprecated */}
            <ErrorBoundary fallbackRender={() => <div>GlobalPromotionStrategy 에러 발생</div>}>
              {!isEmpty(globalPromotionStrategyState) && (
                <GlobalPromotionStrategyView
                  businessPlanSectionData={globalPromotionStrategyState}
                />
              )}
            </ErrorBoundary>
            <ErrorBoundary fallbackRender={() => <div>GlobalPromotionStrategy 에러 발생</div>}>
              {!isEmpty(globalPromotionStrategyAndBusinessEntryStrategyState) && (
                <GlobalPromotionStrategyAndBusinessEntryStrategyView
                  businessPlanSectionData={globalPromotionStrategyAndBusinessEntryStrategyState}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.H2>3-3. 사업추진 일정</BusinessPlanSectionLayout.H2>
            <ErrorBoundary fallbackRender={() => <div>BusinessRoadmap 에러 발생</div>}>
              {!isEmpty(businessRoadmapState) && (
                <BusinessRoadmapView
                  businessPlanSectionData={businessRoadmapState}
                  사업전체일정Title={"3-3-1. 사업 전체 로드맵"}
                  협약기간사업일정Title={"3-3-2. 협약기간(’24.04. ~ ’24.12.) 내 목표 및 달성 방안"}
                />
              )}
            </ErrorBoundary>
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Line />
            <BusinessPlanSectionLayout.Span />
            <BusinessPlanSectionLayout.Span />
            <ReferenceLinkSection searchResultList={searchResultList} />
          </BusinessPlanBlurArea>

          {isFreeVersionBusinessPlan && (
            <>
              <BusinessPlanSectionLayout.H2>Free 버전이 아쉬우신가요?</BusinessPlanSectionLayout.H2>
              <BusinessPlanSectionLayout.H2>
                Pro 버전 구매하고 워드, 노션 파일로 전체 사업계획서를 받아보세요!
              </BusinessPlanSectionLayout.H2>
              <BusinessPlanSectionLayout.H1>
                <a href="https://walla.my/docshuntpro">DocsHunt Pro 구매하기</a>
              </BusinessPlanSectionLayout.H1>
            </>
          )}
          <BusinessPlanSectionLayout.Span />
          <BusinessPlanSectionLayout.Line />
          <BusinessPlanSectionLayout.Span />
          <BusinessPlanSectionLayout.H3>
            - 고객님이 입력하신 내용입니다 🙂
          </BusinessPlanSectionLayout.H3>
          <BusinessPlanSectionLayout.Span />
          <BusinessPlanSectionLayout.H3>1. 아이템 설명</BusinessPlanSectionLayout.H3>
          <BusinessPlanSectionLayout.P>
            {promptOutputData?.resInputData?.description}
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.H3>2. 타겟시장</BusinessPlanSectionLayout.H3>
          <BusinessPlanSectionLayout.P>
            {promptOutputData?.resInputData?.target}
          </BusinessPlanSectionLayout.P>
          <BusinessPlanSectionLayout.H3>3. 내적동기</BusinessPlanSectionLayout.H3>
          <BusinessPlanSectionLayout.P>
            {promptOutputData?.resInputData?.motivation}
          </BusinessPlanSectionLayout.P>
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
};
