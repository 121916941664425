import React from "react";
import { useModal } from "../../hooks/infra/useModal";
import { Button, Typography } from "@mui/material";
import { Empty, TextFieldControlled } from "../atoms";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { useAlert } from "../../hooks/infra/useAlert";

export const BusinessPlanUiEditorModal = ({
  Modal,
  title,
  businessPlanSectionDataState,
  setBusinessPlanSectionDataState,
  handleClose,
}: Props) => {
  const { Alert, openAlert } = useAlert();
  const { control, getValues, setValue } = useForm();
  const createReg = createControlledReg(control);
  const reg = createReg(title, {
    validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
  });
  React.useEffect(() => {
    setValue(title, businessPlanSectionDataState);
  }, [businessPlanSectionDataState]);

  const onClick = () => {
    let data;
    try {
      data = JSON.parse(getValues(title));
    } catch (e) {
      openAlert({ severity: "error", contents: "잘못된 JSON 형식입니다." });
      return;
    }
    setBusinessPlanSectionDataState(data);
    openAlert({ severity: "success", contents: "렌더링 완료" });
    handleClose();
  };

  return (
    <>
      <Alert />
      <Modal style={{ position: "relative", height: "fit-content" }}>
        <Typography variant="h4">{title}</Typography>
        <Empty height="1rem" />
        <TextFieldControlled useControllerProps={reg} fullWidth rows={20} multiline />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={onClick}>렌더링하기</Button>
        </div>
      </Modal>
    </>
  );
};

type Props = {
  Modal: ReturnType<typeof useModal>["Modal"];
  title: string;
  businessPlanSectionDataState: string;
  setBusinessPlanSectionDataState: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
};
