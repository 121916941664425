import React from "react";
import styled from "@emotion/styled";
import { BusinessPlanMenu } from "../organisms/BusinessPlanMenu";
import { Empty, TextFieldControlled } from "../atoms";
import { UseControllerProps } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { BusinessPlanColorPicker } from "../organisms/BusinessPlanColorPicker";
import { useAlert } from "../../hooks/infra/useAlert";
import { appColor } from "../../constants/appColor";
import { appZIndex } from "../../constants/appZIndex";
import { appSize } from "../../constants/appSize";

const MainLayout = styled("main")({
  width: "100%",
  height: "100%",
});

const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainLayout>
      <div
        style={{
          position: "sticky",
          top: appSize.mainHeaderHeight,
          width: "100%",
          backgroundColor: appColor.backGround,
          zIndex: appZIndex.MAIN_HEADER,
        }}
      >
        <BusinessPlanMenu />
        <BusinessPlanColorPicker />
      </div>
      <Empty height="2rem" />
      {children}
    </MainLayout>
  );
};

// 레이아웃에 Input이 있어도 될까?
const Input = ({ useControllerProps, startRendering }: InputProps) => {
  const { Alert, openAlert } = useAlert();

  const onClick = () => {
    try {
      startRendering();
      openAlert({ severity: "success", contents: "렌더링 완료" });
    } catch (e) {
      openAlert({ severity: "error", contents: "입력값을 확인해주세요." });
    }
  };
  return (
    <>
      <Alert />
      <Stack direction="row" spacing={2}>
        <TextFieldControlled
          sx={{ width: "40rem" }}
          label={useControllerProps.name}
          multiline
          variant="outlined"
          useControllerProps={useControllerProps}
        />
        <Button variant="outlined" color="primary" sx={{ width: "6rem" }} onClick={onClick}>
          렌더링
        </Button>
      </Stack>
    </>
  );
};

export const BusinessPlanLayout = Object.assign(Main, { Input });

type InputProps = {
  useControllerProps: UseControllerProps;
  startRendering: () => void;
};
