import React from "react";
import { Switch, Typography } from "@mui/material";

export const FreeVersionStateSwitch = ({ isFreeVersionBusinessPlan, onCheckSwitch }: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="h5" component="h2" align="center" width="5rem">
        {isFreeVersionBusinessPlan ? "Free" : "Premium"}
      </Typography>
      <Switch checked={!isFreeVersionBusinessPlan} onChange={onCheckSwitch} />
    </div>
  );
};

type Props = {
  isFreeVersionBusinessPlan: boolean;
  onCheckSwitch: () => void;
};
