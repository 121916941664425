import React from "react";
import { PageLayout } from "../../components/layouts/Page.layout";
import { BusinessEntryStrategyView } from "./components/BusinessEntryStrategyView";
import { BusinessModelView } from "./components/businessModelView";
import { Empty } from "../../components/atoms";
import { useForm } from "react-hook-form";
import { createControlledReg } from "../../lib/utils/createControlledReg";
import { BusinessPlanLayout } from "../../components/layouts/BusinessPlan.layout";
import { Stack } from "@mui/material";
import { isEmpty } from "../../lib/utils/isEmpty";
import { useExportImageFromHtml } from "../../hooks/infra/useExportImageFromHtml";
import { BUSINESS_PLAN_SVG_ID } from "../../constants/domain/businessPlanSvgId";
import { SearchResult } from "../../types/searchResult.type";

/**
 * @description 3-1. 창업아이템 사업화 추진 전략
 */
export const BusinessCommercializationStrategyPage = () => {
  const { copyToClipboard, downloadPng } = useExportImageFromHtml();

  // form 일단 보류
  const businessPlanTitleList = ["비즈니스 모델", "시장 진입 전략(마케팅 전략)"];
  const [businessModelState, setBusinessModelState] = React.useState<BusinessModelData>(
    {} as BusinessModelData
  );
  const [businessEntryStrategyState, setBusinessEntryStrategyState] =
    React.useState<BusinessEntryStrategyData>({} as BusinessEntryStrategyData);

  const setterList = [setBusinessModelState, setBusinessEntryStrategyState];

  const { control, getValues } = useForm();
  const createReg = createControlledReg(control);
  const reg = businessPlanTitleList.map((title) => {
    return createReg(title, {
      validate: { isRequired: (val: string) => val || `${title}을 입력해야 합니다.` },
    });
  });

  return (
    <PageLayout isMainFunctionPage>
      <PageLayout.Main>
        <BusinessPlanLayout>
          {businessPlanTitleList.map((title, index) => (
            <>
              <BusinessPlanLayout.Input
                key={title}
                useControllerProps={reg[index]}
                startRendering={() => {
                  setterList[index](JSON.parse(getValues(businessPlanTitleList[index])));
                }}
              />
              <Empty height="2rem" />
            </>
          ))}
          <Stack style={{ width: "100%", flexDirection: "column" }}>
            {!isEmpty(businessModelState) && (
              <BusinessModelView
                businessPlanSectionData={businessModelState}
                listOfCopyImage={[1, 2, 3, 4, 5].map(
                  (num) => () =>
                    copyToClipboard({
                      selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                    })
                )}
                listOfDownloadImage={[1, 2, 3, 4, 5].map(
                  (num) => () =>
                    downloadPng({
                      selector: `#${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}`,
                      name: `${BUSINESS_PLAN_SVG_ID.BUSINESS_MODEL}${num}.png`,
                    })
                )}
              />
            )}
            <Empty height="1rem" />
            {!isEmpty(businessEntryStrategyState) && (
              <BusinessEntryStrategyView businessPlanSectionData={businessEntryStrategyState} />
            )}
          </Stack>
        </BusinessPlanLayout>
      </PageLayout.Main>
    </PageLayout>
  );
};

export type BusinessModelData = {
  businessModel: {
    title: string;
    format: string;
    contents: string[];
    mermaid: string;
  }[];
} & { searchResult?: SearchResult[] };

export type BusinessEntryStrategyData = {
  marketEntryStrategy: {
    title: string;
    contents: string;
  }[];
  marketingStrategy: {
    title: string;
    contents: string;
  }[];
  marketingStrategyDetail: {
    title: string;
    rows: {
      criteria: string;
      content: string;
    }[];
  }[];
} & /**
 * @deprecated 옛날 stratege 오타 수용
 */ {
  marketEntryStratege: {
    title: string;
    contents: string;
  }[];
  marketingStratege: {
    title: string;
    contents: string;
  }[];
  marketingStrategeDetail: {
    title: string;
    rows: {
      criteria: string;
      content: string;
    }[];
  }[];
} & { searchResult?: SearchResult[] };
