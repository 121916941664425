import React from "react";
import { BusinessPlanSectionProps } from "../../types/businessPlan.type";
import { ItemBackgroundAndNeedsData } from "../../pages/1_1_창업아이템_배경_및_필요성";
import { BusinessPlanSectionLayout } from "../layouts/BusinessPlanSection.layout";
import { Empty } from "../atoms";
import { useSetAtom } from "jotai";
import { itemBackgroundAndNeedsStateAtom } from "../../store/businessPlanDataState.atom";

export const 창업아이템의_개발_목적View = ({
  businessPlanSectionData,
}: BusinessPlanSectionProps<ItemBackgroundAndNeedsData>) => {
  const setBusinessPlanSectionData = useSetAtom(itemBackgroundAndNeedsStateAtom);

  return (
    <BusinessPlanSectionLayout
      title="창업아이템의 개발 목적"
      businessPlanSectionDataState={JSON.stringify(businessPlanSectionData)}
      setBusinessPlanSectionDataState={setBusinessPlanSectionData}
    >
      <BusinessPlanSectionLayout.H3>
        1. {businessPlanSectionData.problems.title}
      </BusinessPlanSectionLayout.H3>
      {businessPlanSectionData.problems.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
      <Empty height="1rem" />
      <BusinessPlanSectionLayout.H3>
        2. {businessPlanSectionData.solutions.title}
      </BusinessPlanSectionLayout.H3>
      <BusinessPlanSectionLayout.Blockquote>
        TIP : 내 솔루션을 뒤에 가서 잔뜩 보여주기 전에, 문제 인식에서 먼저 짧고 강력하게 보여주세요.
        가장 핵심적인 부분과 함께 지금껏 달성한 성과를 보여주어도 좋습니다.
      </BusinessPlanSectionLayout.Blockquote>
      {businessPlanSectionData.solutions.contents.map((content, index) => (
        <BusinessPlanSectionLayout.P key={index}>- {content}</BusinessPlanSectionLayout.P>
      ))}
    </BusinessPlanSectionLayout>
  );
};
